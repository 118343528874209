/** @format */

@use "variables";
@media screen and (min-width: 1920px) {
	.s_model_content,
	.s_estimation_widget,
	.s_section > .s_slide_appartement,
	.s_review,
	.s_content_sell,
	.s_large_box,
	.s_actualites {
		padding: 5% 15% !important;
	}

	.s_description_detail .mapboxgl-canvas-container .mapboxgl-canvas {
		height: 600px !important;
	}

	.s_actualites > .s_card_actualite > div.s_card_white,
	.s_actualites > .s_card_actualite > div.s_card_sable,
	.s_actualites > .s_card_actualite > div.s_card_prusse {
		width: 70% !important;
	}

	.s_description_detail > div:first-child > div p > img,
	.s_description_detail > div:first-child > div div p > img {
		width: 25% !important;
	}

	.s_general_box .s_seo_model h1 {
		font-size: 30px !important;
	}

	.s_top_logo > img {
		width: 15% !important;
	}
	.s_description_detail > div:first-child > div p,
	.s_description_detail > div:first-child > div div p {
		font-size: 14px !important;
	}
	footer > div > p {
		font-size: 12px !important;
	}

	.s_actus {
		padding: 0 11% !important;
	}

	.s_estimation_widget > div:last-child > div:last-child,
	.s_estimation_widget > div:last-child > div:first-child,
	.s_estimation_widget > div:last-child {
		justify-content: flex-end !important;
	}

	.s_actualites > .s_card_actualite {
		justify-content: center !important;
		height: 400px !important;
	}

	.s_estimation_widget > div:last-child > div:first-child > img {
		width: 3em !important;
	}

	.s_estimation_widget > div:last-child > div:first-child > p {
		margin-right: 13% !important;
	}

	.f_column_reverse > .w_100 {
		margin-right: 5% !important;
	}

	.s_estimation_widget > div:last-child > div:last-child > span {
		padding: 0.8rem !important;
	}

	.s_actus > div:first-child > div {
		padding: 35% 5% !important;
	}

	.s_review > div:last-child,
	.s_review > div:last-child > p {
		text-align: center !important;
	}

	.s_review > div:last-child {
		padding: 5% 0 5% 5% !important;
	}
	.s_review > div:first-child {
		margin: 0 !important;
	}
}

@media screen and (min-height: 100px) and (max-height: 750px) {
	.s_slide_appartement .s_card_appartement {
		// height: auto !important;
	}
}

@media screen and (min-width: 600px) and (max-width: 1155px) {
	.s_slide_appartement
		.s_card_appartement
		> div:first-child
		img.s_main_picture {
		height: 50vh !important;
	}
}
@media screen and (min-width: 200px) and (max-width: 600px) {
	.s_slide_appartement
		.s_card_appartement
		> div:first-child
		img.s_main_picture {
		height: 30vh !important;
	}
}
@media screen and (min-width: 200px) and (max-width: 415px) {
	.s_actus > div:last-child > div > div {
		height: 150px !important;
	}
}
@media screen and (min-width: 200px) and (max-width: 1155px) {
	.content_blog .s_blog_bloc > img {
		width: 100% !important;
		height: 30vh !important;
		margin: 20px 0 !important;
	}

	.s_menu_modal {
		margin-left: 0 !important;
		padding: 40px !important;
	}
	.s_main_content,
	.s_section {
		padding: 0 10px !important;
		overflow: hidden;
	}

	.s_reviews_control_fix {
		right: 0 !important;
		width: 100% !important;
		> div {
			width: 50% !important;
		}
	}

	.s_slide_appartement .s_card_appartement > div:last-child {
		padding: 10% 0 20% 0 !important;
		margin-bottom: 0 !important;
	}
	.s_slide_appartement,
	.s_actus > div:first-child {
		height: auto !important;
		padding: 10% 0 !important;
	}

	.s_actus > div:last-child,
	.s_content_sell > div,
	.s_actualites > .s_card_actualite {
		height: auto !important;
	}

	.s_content_sell > div > div,
	.s_menu_modal > div:first-child {
		width: 100% !important;
		margin-right: 0 !important;
		margin-bottom: 25px !important;
	}

	.s_large_box {
		margin-bottom: 20px;
	}

	.s_menu_modal > div:nth-child(2) > div > div > h2 {
		font-size: 20px !important;
		margin-bottom: 5px !important;
	}

	.s_description > div:first-child > div > div {
		width: 50% !important;
	}

	.s_menu_modal > div:nth-child(2) {
		justify-content: flex-start !important;
	}

	.s_menu_modal > div:nth-child(2) {
		overflow: auto !important;
	}

	.s_menu_modal > div:nth-child(2) > div > div > p {
		margin-bottom: 5px !important;
	}

	.s_menu_modal > div:nth-child(2) {
		height: auto !important;
	}
	.s_actus > div:last-child > div > div > img {
		height: 200px !important;
		object-fit: cover !important;
	}

	.s_actus > div:last-child > div > div {
		width: 100% !important;
		height: 200px !important;
		margin-bottom: 5px !important;
	}

	.s_model_content,
	.s_review,
	.s_actualites > .s_card_actualite:nth-child(even) {
		flex-direction: column-reverse !important;
	}

	.s_appartement .s_slide_appartement {
		padding: 5% !important;
	}

	.s_estimation_widget {
		padding: 5% 0 !important;
	}

	.s_expert {
		margin: 40px 0;
	}

	.s_expert,
	.s_select_option > div,
	.estimation-button,
	.s_appartement,
	.s_description_detail > div:first-child,
	.s_general_box,
	.s_description > div:first-child,
	.s_actualites,
	.s_model_content > div:last-child,
	.s_actus > div:last-child,
	.s_actus > div:last-child,
	.s_menu_modal > div:nth-child(2) > div:last-child,
	.s_large_box {
		padding: 0 !important;
	}

	.s_menu_modal > div:first-child {
		height: 10% !important;
	}

	.s_general_box .s_model_head .s_img_model {
		height: 50vh !important;
	}

	.s_actualites > .s_card_actualite > div.s_card_image {
		height: 60% !important;
	}
	.s_menu_modal div {
		border: none !important;
	}

	.s_actualites > .s_card_actualite,
	.s_actualites > .s_card_actualite > div.s_card_image,
	.s_card_sable {
		left: 0 !important;
		align-items: center !important;
	}

	.s_text_filter,
	.s_actualites > .s_card_actualite > div.s_card_white {
		padding: 5% !important;
	}

	.s_slide_appartement .s_card_appartement > div:last-child {
		padding-bottom: 0 !important;
	}

	.s_select_option form h4,
	.s_select_option .s_step_white,
	.appartement,
	.maison,
	.s_step_1,
	.s_connexion,
	.s_description_detail,
	.s_fix,
	.s_card_appartement,
	.s_menu_modal > div:nth-child(2),
	.s_actus,
	.s_content_sell > div,
	.s_actualites > .s_card_actualite,
	.s_description {
		flex-direction: column !important;
	}

	.s_model_content > div > div {
		flex-direction: row !important;
		margin: 20px 0;
		align-items: flex-start !important;
	}

	.s_actus > div:last-child {
		margin-top: 20px !important;
	}

	.p_20_40 {
		padding: 20px 40px 20px 20px !important;
	}

	.s_menu_svg {
		right: 40px !important;
		> span {
			background-color: black !important;
		}
	}

	.s_main_content > img {
		width: 300px !important;
		margin: 40px 0 !important;
	}

	.s_navbar div:nth-child(2) > div {
		width: 30% !important;
	}

	.s_navbar div:nth-child(2) svg {
		width: 30px !important;
	}

	.s_model_content > div > div,
	.s_model_content > div:last-child,
	.s_model_content > div:first-child,
	.s_estimation_widget > div:last-child > div:first-child,
	.s_estimation_widget > div:last-child > div:last-child {
		align-items: center !important;
		justify-content: center !important;
	}

	.s_select_option input,
	.s_select_option select,
	.s_select_option .s_step_white > div:not(:last-child),
	.appartement > div,
	.maison > div,
	.s_expert > div:first-child img,
	.s_step_1 > div,
	.s_estimation_widget > div:first-child > div > input,
	.s_left,
	.s_text_filter > span,
	.s_text_filter > span,
	.s_description_detail > div,
	.s_description > div,
	.s_card_sable,
	.s_card_prusse,
	.s_actualites > .s_card_actualite > div.s_card_image,
	.s_actualites > .s_card_actualite > div.s_card_white,
	.w_50,
	.s_menu_modal > div:nth-child(2) > div:last-child > div,
	.s_menu_modal > div:nth-child(2) > div:nth-child(2) > div,
	.s_menu_modal > div:nth-child(2) > div:first-child > div,
	.s_menu_modal > div:nth-child(2) > div,
	.s_model_content > div:first-child,
	.s_review > div,
	.s_actus > div:first-child,
	.s_actus > div:last-child,
	.s_model_content > div:last-child,
	.s_slide_appartement .s_card_appartement > div:first-child,
	.s_slide_appartement .s_card_appartement > div:last-child {
		width: 100% !important;
	}

	.s_steps_expert {
		margin-top: 80px !important;
	}

	.s_bloc_expert {
		margin-top: 20px !important;
	}

	.s_slide_appartement .s_card_appartement > div:first-child {
		margin-right: 0 !important;
	}

	.s_actus > div:last-child > div > h2,
	.s_actus > div:last-child > div > p,
	.s_actus > div:last-child > div > a {
		margin-top: 10px !important;
	}

	.s_actus > div:first-child {
		padding: 0 !important;
		margin: 0 20px 0 0 !important;
	}

	.s_top_logo {
		padding: 120px 0 40px 0 !important;
	}

	.s_slide_appartement .s_card_appartement > div:last-child > h2 {
		font-size: 26px !important;
		height: auto !important;
		margin: 0 10px !important;
	}
	.s_appartement {
		.s_slide_appartement {
			.s_card_appartement {
				> div {
					&:last-child {
						b {
							font-size: 16px !important;
						}
						h2 {
							font-size: 26px !important;
						}
						p {
							display: none !important;
						}
					}
				}
			}
		}
	}

	.s_card_appartement > div:last-child > h2 {
		-webkit-line-clamp: 2 !important;
	}

	.s_actus > div:last-child > div:first-child {
		padding: 0 5px 0 0 !important;
	}
	footer > ul > li {
		padding: 10px !important;
	}

	footer > ul {
		flex-wrap: wrap !important;
		padding: 0 20px 20px 5%;
	}
	.s_model_content > div > p {
		text-align: center !important;
	}
	footer > div > p {
		text-align: center !important;
		padding: 0 15%;
	}

	.s_review > div:first-child,
	.s_menu_modal > div:nth-child(2) > div:last-child,
	.s_select_option form h4 p {
		margin: 0 !important;
	}

	.s_select_option form h4 {
		align-items: flex-start !important;
	}
	.s_model_content,
	.s_model_content > div > img,
	.s_actus > div:last-child > div:last-child {
		padding: 0 !important;
		margin-bottom: 40px !important;
	}

	.s_estimation_widget {
		flex-direction: column !important;
		> div {
			margin-top: 5% !important;
			width: 100% !important;
			align-items: center !important;

			> h2 {
				font-size: 25px !important;
			}
		}
	}

	.s_actus > div:first-child > div > p {
		font-size: 12px !important;
	}

	.s_actus > div:first-child > div > span {
		font-size: 10px !important;
	}

	.s_model_content > div > img {
		padding: 20px 0 !important;
	}

	body section .w_80 {
		width: auto !important;
	}

	.s_actus > div:first-child > div {
		padding: 20px !important;
		background-color: white !important;
		margin: 0 -20px !important;
		border-radius: 0 !important;
	}

	.s_model_content {
		margin-top: 5% !important;
		> div {
			&:last-child {
			}
		}
	}

	.s_slide_appartement .s_card_appartement > div:last-child > b {
		margin: 10px !important;
	}
	.s_actus > div:last-child > div {
		justify-content: flex-start !important;
	}

	.s_left h1 {
		letter-spacing: 1px !important;
		font-size: 20px !important;
	}

	.s_actus > div:last-child {
		align-items: flex-start !important;
	}

	.s_reseaux,
	.s_menu_modal > div:last-child,
	.s_slide_appartement .s_card_appartement > div:last-child > p,
	.s_top_header,
	.s_right {
		display: none !important;
	}
	.s_navbar {
		width: 100% !important;
		height: 80px !important;
		flex-direction: row !important;
		align-items: flex-start !important;
		> div {
			width: 33.3% !important;
			&:first-child {
				justify-content: flex-start !important;
				padding: 20px 0 20px 40px !important;
			}
			&:nth-child(2) {
				flex-direction: row !important;
				height: 100% !important;
			}
		}
	}
}
