/** @format */

@use "variables";

.s_navbar {
	position: fixed;
	left: 0;
	top: 0;
	width: 80px;
	height: 100%;
	background-color: variables.$S_WHITE_COLOR_CLIENT;
	opacity: 0.9;
	display: flex;
	flex-direction: column;
	z-index: 10;
	align-items: center;
	align-content: center;
	div:first-child {
		position: relative;
		width: 100%;
		height: 10%;
		padding: 20px 0;
		display: flex;
		align-items: flex-start;
		align-content: center;
		justify-content: center;
		img {
			cursor: pointer;
			width: 2.5rem;
			height: auto;
			position: relative;
		}
	}
	div:nth-child(2) {
		position: relative;
		width: 100%;
		height: 80%;
		padding: 10px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		justify-content: center;
		> div {
			position: relative;
			width: 100%;
			height: auto;
			padding: 0;
			overflow: hidden;
			span {
				font-size: 1em;
				letter-spacing: 1px;
				width: 20px;
				color: variables.$S_BLEU_PRUSSE;
			}
		}
		svg {
			width: 40px;
			height: auto;
			cursor: pointer;
			transition: all 0.5s;

			&:hover {
				fill: variables.$S_OLD_GOLD;
			}
		}
		//ARROW UP
		svg:first-child {
			margin-bottom: 20px;
		}
		//ARROW DOWN
		svg:last-child {
			margin-top: 20px;
		}
	}
	div:nth-child(3) {
		position: relative;
		width: 100%;
		height: 10%;
		padding: 10px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		justify-content: center;
		> a {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 10;
		}
		svg {
			position: relative;
			width: 40px;
			height: 20px;
			border-radius: 5px;
			height: auto;
			padding: 8px;
			cursor: pointer;
			transition: all 0.5s;
			&:hover {
				fill: variables.$S_OLD_GOLD;
			}
		}
	}
}

.s_animated_top {
	position: relative;
	animation-duration: 0.5s;
	animation-name: animeNumberTop;
	top: 0;
}

.s_animated_bottom {
	position: relative;
	animation-duration: 0.5s;
	animation-name: animeNumberBottom;
	top: 0;
}

.s_reseaux {
	position: fixed;
	right: 2.3%;
	width: 0;
	top: 45%;
	transform: rotate(270deg);
	transform-origin: 0 0;
	height: 0;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	z-index: 11;
	mix-blend-mode: difference;
	span,
	a {
		font-size: 12px;
		letter-spacing: 5px;
		color: variables.$S_WHITE;
		text-transform: uppercase;
		// padding-right: 64px;
		padding-right: 0;
		cursor: pointer;
		transition: all 3s;
		&.animed {
			padding-right: 64px;
		}
	}
}

// .s_menu_svg {
//   position: fixed;
//   right: 2.3%;
//   top: 20px;
//   z-index: 30;
//   mix-blend-mode: difference;
//   cursor: pointer;
//   path {
//     transition: all .5s;
//   }
//   &:hover {
//     path {
//       stroke: variables.$S_OLD_GOLD;
//     }
//   }
// }

.s_menu_svg {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	position: fixed;
	right: 2.3%;
	top: 20px;
	z-index: 51;
	width: 50px;
	mix-blend-mode: difference;
	cursor: pointer;

	input[type="checkbox"] {
		display: none;

		&:checked ~ span {
			transform: none;
			&:nth-of-type(1) {
				width: 50%;
			}

			&:nth-of-type(2) {
				width: 100%;
			}

			&:nth-of-type(3) {
				width: 75%;
			}
		}
	}

	span {
		background: #fff;
		border-radius: 10px;
		height: 2px;
		margin: 0;
		transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
		&.animed {
			margin: 5px 0;
		}
		&:nth-of-type(1) {
			transform-origin: bottom;
			transform: rotatez(45deg) translate(-13px, 18px);
			width: 50%;
		}

		&:nth-of-type(2) {
			transform-origin: top;
			transform: rotatez(-45deg);
			width: 100%;
		}

		&:nth-of-type(3) {
			transform-origin: bottom;
			transform: translate(-4px, -3px) rotatez(45deg);
			width: 50%;
		}
	}
}

.s_active_menu {
	animation: menuMove 1s forwards;
}

.s_unactive_menu {
	animation: menuUnmove 1s forwards;
}

.s_menu_modal {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 50;
	right: -100%;
	background: variables.$S_WHITE;
	padding: 40px 160px 40px 80px;
	margin-left: 80px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: space-between;

	> {
		div {
			&:first-child {
				width: 100%;
				height: 20%;
				display: flex;
				justify-content: center;
				align-content: center;
				> img {
					height: auto;
				}
			}
			&:nth-child(2) {
				width: 100%;
				height: 50%;
				display: flex;
				justify-content: center;
				align-content: center;
				> div {
					width: 33.3%;
					> div {
						> h2 {
							position: relative;
							font-family: "Optima";
							font-size: 25px;
							text-transform: uppercase;
							line-height: 42px;
							margin-bottom: 10px;
							text-align: center;
							a {
								color: variables.$S_BLEU_PRUSSE;
								transition: all 0.5s;
								&:hover {
									letter-spacing: 1px;
								}
							}
						}
						> p {
							position: relative;
							text-align: center;
							font-family: "Gotham Book";
							font-size: 14px;
							line-height: 20px;
							margin-bottom: 10px;
							a {
								color: variables.$S_SABLE;
								transition: all 0.5s;
								&:hover {
									color: variables.$S_OLD_GOLD;
								}
							}
						}
					}
					&:first-child {
						display: flex;
						border-right: 2px solid #ececea;
						flex-direction: column;
						flex-wrap: nowrap;
						align-content: center;
						justify-content: space-evenly;
						align-items: center;
						> div {
							width: 60%;
							margin: 0 auto;
						}
					}
					&:nth-child(2) {
						display: flex;
						border-right: 2px solid #ececea;
						flex-direction: column;
						flex-wrap: nowrap;
						align-content: center;
						justify-content: space-evenly;
						align-items: center;
						> div {
							width: 60%;
							margin: 0 auto;
						}
					}
					&:last-child {
						padding-left: 5%;
						display: flex;
						flex-direction: column;
						flex-wrap: nowrap;
						align-content: center;
						justify-content: space-evenly;
						align-items: center;
						> div {
							width: 60%;
							margin: 0 auto;
						}
					}
				}
			}
			&:last-child {
				width: 100%;
				height: 15%;
				display: flex;
				justify-content: space-between;
				align-content: center;
				> div {
					&:last-child {
						width: 200px;
						height: 100%;
						margin-left: auto;
						display: flex;
						flex-direction: column;
						justify-content: flex-end;
						align-items: flex-end;
						padding: 0;
						> div {
							&:first-child {
								position: relative;
								width: 100%;
								display: flex;
								flex-direction: row-reverse;
								align-items: flex-end;
								justify-content: space-between;
								> p {
									font-size: 10px;
									font-family: "Gotham Bold";
									font-weight: bold;
									position: relative;
									color: #325a66;
								}
								> img {
									width: 2rem;
								}
							}
							&:last-child {
								padding: 5px 0 0 0;
								width: 100%;
								display: flex;
								flex-direction: row;
								align-items: flex-end;
								justify-content: space-around;
								> span {
									background-color: #325a66;
									border-radius: 5px;
									padding: 0.5rem;
									margin-left: 5px;
									&:first-child {
										margin: 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
