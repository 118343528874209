@use "variables";

footer {
    position: relative;
    height: auto;
    width: 100%;
    padding: 5% 0;
    display: flex;
    z-index: 5;
    background: variables.$S_WHITE_COLOR_CLIENT;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    > ul {
        position: relative;
        display: flex;
        align-items: center;
        align-content: center;
        width: 100%;
        padding: 0 10% 5% 10%;
        justify-content: space-between;
        > li {
            position: relative;
            padding: 0 10px;
            text-transform: uppercase;
            font-family: "Optimal";
            font-size: 14px;
            color: variables.$S_BLEU_PRUSSE;
            cursor: pointer;
        }
    }
    > img {
        position: relative;
        width: 64px;
        margin-bottom: 5%;
    }
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > p {
            position: relative;
            font-family: "Gotham Book";
            font-size: 10px;
            line-height: 15px;
            text-transform: uppercase;
            color: variables.$S_BLEU_PRUSSE;
            margin-bottom: 10px;
            a {
                color: variables.$S_BLEU_PRUSSE;
            }
            strong {
                font-family: "Gotham Bold";
            }
        }
    }
}