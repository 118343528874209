/** @format */

@use "variables";
@import "../../node_modules/include-media/dist/include-media";
$breakpoints: (
	phone: 200px,
	tablet: 1150px,
	laptop: 1440px,
	desktop: 1980px,
);

.s_estimation_widget {
	position: relative;
	height: 100%;
	padding: 5%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	> div:first-child {
		position: relative;
		width: auto;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		> h2 {
			position: relative;
			font-size: 35px;
			white-space: nowrap;
			font-family: "Optimal";
			color: variables.$S_BLEU_PRUSSE;
			text-transform: uppercase;
		}
		p {
			position: relative;
			font-size: 12px;
			font-family: "Gotham Book";
			color: variables.$S_BLEU_PRUSSE;
		}
		> div {
			position: relative;
			padding-top: 1.25em;
			width: 100%;
			height: auto;
			display: flex;
			flex-direction: row;
			justify-content: center;
			> input {
				position: relative;
				width: 23rem;
				height: 48px;
				padding: 10px 10px 10px 40px;
				border: 1px solid #eee;
				&::placeholder {
					opacity: 0.5;
				}
			}
			> img {
				position: absolute;
				width: 20px;
				left: 10px;
				height: 48px;
				z-index: 10;
			}
		}
	}
	> div:last-child {
		width: 30%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		padding: 0 0 0 5%;
		> div:first-child {
			position: relative;
			width: 75%;
			display: flex;
			flex-direction: row;
			align-items: flex-end;
			justify-content: space-between;
			> p {
				font-size: 10px;
				font-family: "Gotham Bold";
				font-weight: bold;
				position: relative;
				color: variables.$S_WHITE_COLOR_CLIENT;
				> b {
					color: variables.$S_BLEU_PRUSSE;
				}
			}
			> img {
				width: 2rem;
				margin: 0 0 0 5px;
			}
		}
		> div:last-child {
			padding: 5px 0 0 0;
			width: 75%;
			display: flex;
			flex-direction: row;
			align-items: flex-end;
			justify-content: space-around;
			> span {
				background-color: variables.$S_BLEU_PRUSSE;
				border-radius: 5px;
				padding: 0.5rem;
				margin-left: 5px;
				&:first-child {
					margin: 0;
				}
			}
		}
	}
}
#budget {
	margin-top: 10px;
}
h4.mobile {
	font-family: "Gotham Bold";
	position: relative;
	margin: 0 10px !important;
	color: variables.$S_BLEU_PRUSSE;
	font-size: 14px;
}
.mobile {
	display: none;
	@include media(">phone", "<tablet") {
		display: flex !important;
	}
	span {
		@include media(">phone", "<tablet") {
			width: 46px !important;
			height: 46px;
			padding: 10px;
			margin-right: 10px;
			&:last-child {
				margin-right: 0;
			}
			&:hover {
				background-color: transparent !important;
			}
		}
		img {
			@include media(">phone", "<tablet") {
				width: 46px;
			}
		}
	}
}
.s_filter {
	position: relative;
	z-index: 10;
	background: variables.$S_BLEU_PRUSSE;
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 0 40px;
	margin: 40px 0 0 0;
	width: 100%;
	transition: all 0.5s;
	.close_filter {
		position: absolute;
		right: 10px;
		top: 10px;
		width: 56px;
		cursor: pointer;
	}
	@include media(">phone", "<tablet") {
		width: 100%;
		overflow: scroll;
		position: fixed;
		top: 100%;
		display: flex;
		padding-top: 40px;
		height: 100%;
		background: #c9c1b6;
		flex-direction: column;
		justify-content: flex-start;
	}
	> div {
		position: relative;
		width: 19%;
		height: 100%;
		padding: 40px 0 40px 0;
		@include media(">phone", "<tablet") {
			width: 100%;
			padding: 0 0 20px 0;
			margin-top: 0;
			height: auto;
		}
		span {
			@include media(">phone", "<tablet") {
				display: none;
			}
		}
		> div {
			@include media(">phone", "<tablet") {
				position: relative;
				opacity: 1;
				display: block;
				background-color: transparent;
				padding: 0;
				width: 100%;
				box-shadow: none;
				margin-top: 10px;
			}
			opacity: 0;
			display: none;
			position: absolute;
			margin-top: 20px;
			background: white;
			padding: 20px;
			border-radius: 10px;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			width: calc(100% + 150px);
			transition: all 0.5s;
			.result {
				> b {
					width: 47%;
					font-size: 8px;
					line-height: 10px;
					padding: 10px;
					text-transform: uppercase;
					background: variables.$S_BLEU_PRUSSE;
					color: white;
					border-radius: 5px;
					margin-bottom: 1%;
					justify-content: space-between;
					align-items: center;
					&:last-child {
						margin-right: 0;
					}
					> img {
						width: 16px;
						height: 16px;
						z-index: 5;
						cursor: pointer;
					}
				}
			}
			h4 {
				position: relative;
				color: variables.$S_BLEU_PRUSSE;
				font-family: "Optimal Bold";
				font-size: 18px;
				white-space: nowrap;
			}
			> div {
				@include media(">phone", "<tablet") {
					margin: 10px 0 0 0;
					align-items: center;
					justify-content: flex-start;
				}
				position: relative;
				width: 100%;
				margin: 20px 0;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: flex-start;
				flex-wrap: wrap;

				&.pieces,
				&.ascenceur {
					b {
						position: relative;
						display: flex;
						width: 33%;
						justify-content: center;
						align-items: center;
						@include media(">phone", "<tablet") {
							width: 13%;
							&:first-child {
								width: 20%;
							}
						}
						input {
							&:checked {
								z-index: 0;
							}
						}
					}
					input {
						width: 62px !important;
						height: 32px !important;
						cursor: pointer;
					}
					p {
						position: absolute;
					}
				}
				> img {
					position: absolute;
					z-index: 10;
					left: 10px;
					top: 10px;
				}
				> div {
					position: relative;
					width: 47%;
					margin-right: 10px;
					@include media(">phone", "<tablet") {
						width: 49.5%;
						margin-right: 3px;
					}
					&:last-child {
						margin-right: 0;
					}
					> p {
						font-family: "Gotham Book";
						position: relative;
						margin: 5px 0;
						font-size: 12px;
						text-transform: uppercase;
					}
				}
				input[type="text"],
				input[type="number"] {
					@include media(">phone", "<tablet") {
						background-color: transparent;
					}
					position: relative;
					text-decoration: none;
					border: 1px solid variables.$S_BLEU_PRUSSE;
					border-radius: 0;
					appearance: none;
					width: 100%;
					padding: 10px 10px 10px 40px;
					transition: all 0.5s;
					&::placeholder {
						font-family: "Gotham Book";
						color: variables.$S_BLEU_PRUSSE;
						font-size: 12px;
						opacity: 0.7;
					}
				}
				input[type="number"] {
					@include media(">phone", "<tablet") {
						background-color: transparent;
					}
					padding: 10px !important;

					&::before {
						position: absolute;
						content: "";
						right: 20px;
						width: 16px;
						height: 16px;
						background-color: red;
					}
				}
				> b {
					display: flex;
					width: 50%;
					justify-content: flex-start;
					margin-bottom: 10px;
					input,
					p {
						margin-right: 10px;
					}
					input[type="checkbox"] {
						position: relative;
						cursor: pointer;
						z-index: 3;
						text-decoration: none;
						border: 1px solid variables.$S_BLEU_PRUSSE;
						border-radius: 0;
						appearance: none;
						width: 12px;
						height: 12px;
						transition: all 0.5s;
						&:checked {
							background-color: variables.$S_BLEU_PRUSSE;
							border: 1px solid variables.$S_BLEU_PRUSSE;
						}
					}
					p {
						font-family: "Gotham Book";
						font-size: 12px;
						color: variables.$S_BLEU_PRUSSE;
					}
				}
			}
		}
	}
}
.left-back {
	background: linear-gradient(
		90deg,
		#325a66 -5.97%,
		rgba(50, 90, 102, 0.5) 65%,
		rgba(217, 217, 217, 0) 100%
	);
}
.right-back {
	background: linear-gradient(
		270deg,
		#325a66 0%,
		rgba(50, 90, 102, 0.5) 65%,
		rgba(217, 217, 217, 0) 100%
	);
}
.s_control_swiper_appartement {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.moveTeamBtn {
	position: absolute;
	height: 50%;
	width: 100%;
	border-radius: 0;
	font-size: 12px;

	&:nth-child(2n + 2) {
		bottom: 0;
	}

	img {
		height: 15px;
	}
}

.teamRow {
	position: relative;
	padding: 0;
}
