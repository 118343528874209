@font-face {
    font-family: "Gotham Book";
    src: url("../../assets/font/Gotham-Book.otf");
}

@font-face {
    font-family: "Gotham Bold";
    src: url("../../assets/font/Gotham-Bold.otf");
}

@font-face {
    font-family: "Optimal";
    src: url("../../assets/font/OptimaLTStd.otf");
}

@font-face {
    font-family: "Optimal Bold";
    src: url("../../assets/font/OptimaLTStd-Bold.otf");
}