/** @format */

@use "variables";
@use "breakpoint";
@use "reset";
@use "font";
@use "navbar";
@use "button";
@use "section";
@use "widget";
@use "board";
@use "estimation";
@use "footer";
@use "panelAccueil";

@import "../../node_modules/include-media/dist/include-media";
$breakpoints: (
	phone: 200px,
	tablet: 1150px,
	laptop: 1440px,
	desktop: 1980px,
);

* {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

html {
	height: 100%;
	overflow-x: hidden;
	margin: 0;
	padding: 0;
}

body {
	position: relative;
	background-color: variables.$S_WHITE;
	font-family: "Gotham Book";
}

#wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
}

#main {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	opacity: 1;
}

strong {
	font-family: "Gotham Bold";
}

/* NOTIFICATION */

.s_notif_acheter {
	opacity: 0;
	position: fixed;
	bottom: 20px;
	left: 100px;
	height: 150px;
	width: 250px;
	background: white;
	z-index: 100;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
		rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
	overflow: hidden;
	transition: all 0.5s;
	@include media(">phone", "<tablet") {
		left: 20px;
		height: 150px;
		width: 250px;
		z-index: 5;
	}
	> div {
		&:first-child {
			position: relative;
			padding: 0 10px;
			height: 20%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			align-content: center;
			flex-direction: row;
			background-color: variables.$S_WHITE_COLOR_CLIENT;
			> p {
				position: relative;
				font-size: 10px;
				color: variables.$S_BLEU_PRUSSE;
				text-transform: uppercase;
			}
			> img {
				cursor: pointer;
				width: 20px;
				height: 20px;
			}
		}
		&:last-child {
			position: relative;
			padding: 10px;
			height: 80%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			> p {
				font-size: 14px;
				opacity: 0.7;
			}
		}
	}
}

.s_notification {
	display: block;
	position: fixed;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	top: 10%;
	right: 0;
	z-index: 40;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;

	i {
		margin-right: 15px;
		font-weight: bold;
	}

	.error_message {
		padding: 10px;
		font-size: 12px;
		padding: 20px 10px;
		text-transform: uppercase;
		display: flex;
		background: variables.$S_NOTIFICATION_COLOR_ERROR;
		color: whitesmoke;
		align-items: center;
		justify-content: center;
	}

	.sucess_message {
		padding: 10px;
		font-size: 12px;
		padding: 20px 10px;
		text-transform: uppercase;
		display: flex;
		background: variables.$S_NOTIFICATION_COLOR_SUCCESS;
		color: whitesmoke;
		align-items: center;
		justify-content: center;
	}
}

/* NOTIFICATION */
/* CONNEXION */
.s_connexion {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
}

#connexion_board {
	width: 100%;
}

.s_left {
	width: 50%;
	height: 100%;
	padding: 10%;
	background-color: white;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	h1 {
		font-size: 26px;
		padding-bottom: 20px;
		width: 100%;
		text-align: left;
		font-family: "Gotham Bold";
		letter-spacing: 8px;
	}
}

// IMAGE CONNEXION À FAIRE
.s_right {
	width: 50%;
	height: 100%;
	position: relative;
	right: 0;
	top: 0;
	background-image: url("../../assets/background/buy.png");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}
/* CONNEXION */
/* LOAD */
.s_load {
	position: fixed;
	width: 100%;
	height: 100%;
	background: variables.$S_BLEU_PRUSSE;
	z-index: 20;
	display: flex;
	align-items: center;
	justify-content: center;
	> div {
		position: relative;
		width: 50%;
		height: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		> .s_cache_logo {
			position: absolute;
			background-color: variables.$S_BLEU_PRUSSE;
			width: 100%;
			height: 100%;
		}
	}
}
/* LOAD */
/* COUNTER API SEARCH */
.hdpi.pac-logo:after,
.pac-logo:after,
.mapboxgl-ctrl-attrib-inner,
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
	display: none !important;
}
/* COUNTER API SEARCH */
/* OVERLAY */

.s_overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: variables.$S_MAIN_COLOR_BLACK;
	z-index: 7;
}

.bk_bleu_overlay {
	background-color: variables.$S_BLEU_PRUSSE;
}

.b_rad_10 {
	border-radius: 10px;
}

.opa_x_6 {
	opacity: 0.6;
}

.opa_x_5,
.opa_x_4 {
	opacity: 0.4;
}

.opa_x_3 {
	opacity: 0.3;
}

.opa_x_2 {
	opacity: 0.2;
}

/* OVERLAY */
/* AXEPTIO */

.htKxuG svg path {
	fill: variables.$S_BLEU_PRUSSE !important;

	&:last-child {
		fill: white !important;
	}
}

.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1
	.inpjsL.inpjsL.inpjsL.inpjsL.inpjsL.inpjsL {
	color: variables.$S_BLEU_PRUSSE !important;
}

.EeFHd {
	display: none !important;
}

#axeptio_main_button {
	right: 1% !important;
}

/* AXEPTIO */
/* RECHERCHE */
.s_nodal_content {
	display: none;
	position: absolute !important;
	background: white;
	top: 100%;
	margin-top: 10px;
	z-index: 100;
	padding: 0 !important;
	width: 100% !important;
	border-radius: 5px;
	flex-direction: column !important;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	> div {
		&:first-child {
			background-color: #f5f7f7;
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			align-content: center;
			justify-content: space-between;
			position: relative;
			padding: 5px 10px;

			> img {
				width: 26px;
				height: 26px;
				cursor: pointer;
			}
			> div {
				> img {
					width: 23px;
					height: 23px;
					padding: 5px;
				}
				p {
					position: relative;
					font-family: "Gotham Book";
					text-transform: uppercase;
					font-size: 10px;
					top: 1px;
					padding: 0 5px;
				}
				display: flex;
				align-items: center;
				align-content: center;
			}
		}
		&:nth-child(2) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: column;
			> div {
				display: flex;
				align-items: center;
				justify-content: space-between;
				align-content: center;
				padding: 10px 0;
				width: 100%;
				transition: 0.5s all;
				cursor: pointer;

				&:not(:last-child) {
					border-bottom: 1px solid whitesmoke;
				}
				&:hover {
					background-color: whitesmoke;
				}
				> div {
					&:first-child {
						width: 20%;
						display: flex;
						align-items: center;
						justify-content: center;
						> svg {
							width: 26px;
							height: 26px;
						}
					}
					&:last-child {
						width: 80%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						> h4 {
							position: relative;
							font-size: 14px;
							font-family: "Gotham Book";
						}
						> p {
							position: relative;
							font-size: 12px;
							font-family: "Gotham Book";
							opacity: 0.6;
						}
					}
				}
			}
		}
	}
}
/* SWIPER */

.swiper-button-next,
.swiper-button-prev {
	top: 90%;
	width: 90%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: variables.$S_MAIN_COLOR_BLACK;
}

.swiper-pagination-bullet-active {
	background-color: variables.$S_MAIN_COLOR_BLACK !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
	font-size: 23px !important;
}

body {
	.swiper {
		overflow: visible;
	}
}
/* SWIPER */

/* FORM */
form {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	background: #fff;
	select {
		float: left;
		border-radius: 5px;
		background: variables.$S_WHITE_COLOR_CLIENT;
		width: 100%;
		border: #eee solid 0.2px;
		padding: 15px 20px 15px 55px;
		font-size: 12px;
		margin-bottom: 10px;
		color: variables.$S_MAIN_COLOR_BLACK;
		&::placeholder {
			font-size: 12px;
			opacity: 0.4;
		}
	}
	input {
		float: left;
		border-radius: 5px;
		background: variables.$S_WHITE_COLOR_CLIENT;
		width: 100%;
		border: #eee solid 0.2px;
		padding: 15px 20px 15px 55px !important;
		font-size: 12px;
		margin-bottom: 10px;
		color: variables.$S_MAIN_COLOR_BLACK;
		&::placeholder {
			font-size: 12px;
			opacity: 0.4;
		}
	}
	textarea {
		float: left;
		border-radius: 5px;
		background: variables.$S_WHITE_COLOR_CLIENT;
		width: 100%;
		border: #eee solid 0.2px;
		padding: 15px 20px 15px 20px;
		font-size: 12px;
		margin-bottom: 10px;
		color: variables.$S_MAIN_COLOR_BLACK;
		&::placeholder {
			font-size: 12px;
			opacity: 0.4;
		}
	}
	> p {
		font-family: "Gotham Book";
		position: relative;
		margin: 5px 0 !important;
		font-size: 12px !important;
		text-transform: uppercase !important;
	}
	> b {
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-content: center;
		flex-direction: row;
		position: relative;
		> div {
			position: relative;
			width: 49%;
			> p {
				font-family: "Gotham Book";
				position: relative;
				margin: 5px 0 !important;
				font-size: 12px !important;
				text-transform: uppercase !important;
			}
			> img {
				padding-right: 12px;
				font-size: 16px;
				color: #d17e4b;
				position: absolute;
				width: 26px;
				font-weight: 700;
				top: 50%;
				left: 20px;
				text-align: left;
			}
		}
	}
}

// GLOBAL
#closeButton {
	position: fixed;
	right: 5%;
	top: 5%;
	width: 80px;
	height: auto;
	cursor: pointer;
}

.bk_sable {
	background-color: variables.$S_SABLE;
}

.bk_white {
	background-color: white;
}
.bk_bleu {
	background-color: variables.$S_BLEU_PRUSSE;
	color: variables.$S_WHITE_COLOR_CLIENT;
}
.bk_white_client {
	background-color: variables.$S_WHITE_COLOR_CLIENT;
}

.bk_sable {
	background-color: variables.$S_SABLE;
}

.cl_bleu_prusse {
	color: variables.$S_BLEU_PRUSSE;
}

.cl_white {
	color: white;
}

.bold {
	font-weight: bold;
}

.uppercase {
	text-transform: uppercase;
}

.text_none {
	text-transform: none !important;
}

.m_left_0 {
	margin-left: 0 !important;
}

.m_auto {
	margin: auto !important;
}

.m_b_0 {
	margin-bottom: 0 !important;
}

.m_right_5 {
	margin-right: 5%;
}
.m_top_10 {
	margin-top: 10px;
}

.m_top_20 {
	margin-top: 20px;
}

.m_5_0 {
	margin: 5% 0;
}

.m_1_0 {
	margin: 1% 0;
}

.m_20_center {
	margin: 20px auto 0 auto;
}

.l_10 {
	left: 10%;
}

.l_0 {
	left: 0 !important;
}
.p_0_5 {
	padding: 0 5%;
}

.p_5_5_2_5 {
	padding: 5% 5% 2% 5%;
}

.p_0_10 {
	padding: 0 10%;
}

.p_20_40 {
	padding: 20px 40px !important;
}

.p_15 {
	padding: 15px;
}
.p_15_40 {
	padding: 15px 40px;
}

.p_10_20 {
	padding: 10px 20px;
}

.p_a20_40 {
	padding: 20px 60px 20px 20px;
}

.p_a15_40 {
	padding: 15px 40px 15px 15px;
}

.p_a15_l40 {
	padding: 15px 15px 15px 40px;
}

.p_0 {
	padding: 0 !important;
}

.p_t_0 {
	padding-top: 0 !important;
}

.p_0_20 {
	padding: 0 20px;
}

.z_6 {
	z-index: 6 !important;
}
.bd_l_0 {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.bd_r_none {
	border-right: none !important;
}

.bd_r_0 {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.bd_1_prusse {
	border: 1px solid variables.$S_BLEU_PRUSSE;
}

.bd_1_white {
	border: 1px solid white;
}

.bd_r_10 {
	border-radius: 10px;
}

.fs_16 {
	font-size: 16px;
}

.fs_12 {
	font-size: 12px;
}

.fs_10 {
	font-size: 10px;
}

.fs_08 {
	font-size: 8px;
}

body {
	section {
		.t_none {
			text-transform: none !important;
		}

		.w_64px {
			width: 10% !important;
			display: flex;
			height: 100%;
			margin: 0;
			align-items: center;
			&:hover {
				background-color: transparent !important;
			}
		}
		.w_0 {
			width: 0 !important;
		}
		.w_20 {
			width: 20% !important;
		}
		.w_25 {
			width: 25% !important;
		}
		.w_30 {
			width: 30% !important;
		}

		.w_50 {
			width: 50% !important;
		}
		.w_60 {
			width: 60%;
		}
		.w_70 {
			width: 70% !important;
		}
		.w_100 {
			width: 100% !important;
		}
		.w_80 {
			width: 80%;
		}
		.h_auto {
			height: auto !important;
		}
		.h_30vh {
			height: 30vh;
		}
		.h_100 {
			height: 100%;
		}
		.h_70vh {
			height: 70vh;
		}
		.d_flex {
			display: flex;
			align-items: center;
		}
		.aj_start {
			align-items: flex-start;
			justify-content: flex-start;
		}

		.f_end {
			align-items: flex-end;
		}

		.f_start {
			align-items: flex-start;
		}

		.f_center {
			align-items: center !important;
		}

		.f_column_reverse {
			flex-direction: column-reverse;
		}

		.f_reverse {
			flex-direction: row-reverse;
		}

		.t_align_center {
			text-align: center;
		}

		.t_align_right {
			text-align: right;
		}

		.index_10 {
			z-index: 10;
		}

		.s_overflow_h {
			overflow: hidden !important;
		}
	}
	.j_center {
		justify-content: center !important;
	}
	.j_start {
		justify-content: flex-start !important;
	}
	.top_60 {
		top: 60% !important;
	}
	.top_100 {
		top: 100% !important;
	}
	.j_between {
		justify-content: space-between !important;
	}
}

.s_modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	background: #000000e3;
	width: 100%;
	height: 100%;
	padding: 0 5%;

	align-items: center;
	justify-content: center;
	align-content: center;

	.zoomSlide {
		overflow: hidden;
	}

	.swiper-button-prev {
		padding-right: 80px;
	}

	.swiper-button-next {
		padding-left: 80px;
	}

	.swiper-button-prev,
	.swiper-button-next {
		color: white;
		width: 100%;
		top: 95%;
		display: flex;
		z-index: 100;
		position: fixed;
		justify-content: center;
		align-items: center;
	}

	> img {
		position: fixed;
		right: 20px;
		top: 20px;
		width: 54px;
		cursor: pointer;
	}

	> div img {
		display: block;
		width: 100%;
		height: 80vh;
		object-fit: contain;
		padding: 20px 5%;
	}
	.j_end {
		justify-content: flex-end !important;
	}
}
.mobile_desktop {
	display: block !important;
}
.big-image {
	width: 100% !important;
	height: 100% !important;
}
