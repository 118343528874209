@use "reset";
@use "font";
@use "animation";

$S_MAIN_COLOR_CLIENT: #C9C1B6;
$S_WHITE_COLOR_CLIENT: #ececea;
$S_BLEU_PRUSSE: #325a66;
$S_BLEU_PRUSSE_9: #325a66de;
$S_BLEU_PETROLE: #0a0f25;
$S_WHITE: #FFF;
$S_SABLE: #c9c1b6;
$S_OLD_GOLD: #b98a53;
$S_MAIN_COLOR_BLACK: #0a0f25;
$S_NOTIFICATION_COLOR_SUCCESS: #4caf4f;
$S_NOTIFICATION_COLOR_ERROR: #bd3630;
