@keyframes animeNumberTop {
    from {
      top: 0;
    }  

    50% {
        top: -40px;
    }
    to {
      top: 0;
    }
  }


@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 1;
  }
}

@keyframes background-move {
  0% {
    background-color: transparent;
    color: #325a66;
    opacity: 1;
  }
  50% {
    background-color: #325a66;
    color: #ececea;
    opacity: 1;
  }
  100% {
    background-color: transparent;
    color: #325a66;
    opacity: 1;
  }
}


@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 1;
  }
}

@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}


@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}



  @keyframes animeNumberBottom {
    from {
      top: 0;
    }  

    50% {
        top: 40px;
    }
    to {
      top: 0;
    }
  }

  @keyframes menuMove {
    from {
      right: -100%;
    }

    to {
      right: -80px;
    }
    
  }

  @keyframes menuUnmove {
    from {
      right: -80px;
    }

    to {
      right: -100%;
    }
  }

  @keyframes agentMove {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
    
  }

  @keyframes agentUnmove {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }


@media screen and (min-width: 200px) and (max-width: 1155px) {
  @keyframes menuMove {
    from {
      right: -100%;
    }

    to {
      right: 0;
    }
    
  }

  @keyframes menuUnmove {
    from {
      right: 0;
    }

    to {
      right: -100%;
    }
  }
}







.anime {
  opacity: 0;
  transition: opacity 1s;
}

.animed.slide-left {
  animation: slide-in-left 1s ease-in-out both;
}

.animed.slide-out-top {
  opacity: 1 !important;
  animation: slide-out-top 2s ease-in-out both;
}


.animed.slide-out-left {
  opacity: 1 !important;
  animation: slide-out-left 2s ease-in-out both;
}


.animed.slide-out-right {
  opacity: 1 !important;
  animation: slide-out-right 2s ease-in-out both;
}

.animed.slide-right {
  animation: slide-in-right 1s ease-in-out both;
}

.animed.slide-top {
  animation: slide-in-top 1s ease-in-out both;
}

.animed.slide-bottom {
  animation: slide-in-bottom 1s ease-in-out both;
}

.animed.background_move {
  animation: background-move 1s ease-in-out both;
}

.anime.animed {
  opacity: 1;
}