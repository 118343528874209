@use "variables";

button {
    margin-top: 20px;
}

.s_button {
    position: relative;
    font-family: "Gotham Bold";
    width: auto;
    height: auto;
    display: flex;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
    z-index: 10;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: white;
    transition: 0.5s ease-in-out;
    // &:after {
    //     content: "";
    //     top: 0;
    //     right: 0;
    //     z-index: -1;
    //     width: 100%;
    //     height: 100%;
    //     background-color: variables.$S_BLEU_PRUSSE !important;
    //     transform: translateX(-110%);
    //     position: absolute;
    //     transition: 0.5s ease-in-out;
    // }   

    &:not(.cl_white) {
        &:hover {
            color: white;
            background-color: variables.$S_BLEU_PRUSSE;
            &::after {
                position: absolute;
                width: 16px;
                height: 16px;
            }
        }
    }
    &.arrow-right:not(.cl_white):not(.arrow-left) {
        &:hover {
            &::after {
                content: "";
                right: 20px;
                background-image: url("../../assets/icons/arrow-right-white.svg");
            }
        }
    }
    &.arrow-left:not(.cl_white):not(.arrow-right) {
        &:hover {
            &::after {
                content: "";
                left: 20px;
                transform: rotate(180deg);
                background-image: url("../../assets/icons/arrow-right-white.svg");
            }
        }
    }

    &:not(.cl_bleu_prusse) {
        &:hover {
            color: variables.$S_BLEU_PRUSSE;
            background-color: variables.$S_WHITE;
            &::after {
                position: absolute;
                width: 16px;
                height: 16px;
            }
        }
    }
    &.arrow-right:not(.cl_bleu_prusse):not(.arrow-left) {
        &:hover {
            &::after {
                content: "";
                right: 20px;
                background-image: url("../../assets/icons/arrow-right-white.svg");
            }
        }
    }
    &.arrow-left:not(.cl_bleu_prusse):not(.arrow-right) {
        &:hover {
            &::after {
                content: "";
                left: 20px;
                transform: rotate(180deg);
                background-image: url("../../assets/icons/arrow-right-white.svg");
            }
        }
    }
    &.arrow-down-white:not(.cl_bleu_prusse):not(.arrow-right):not(.arrow-left) {
        &:hover {
            &::after {
                content: "";
                right: 20px;
                background-image: url("../../assets/icons/carre-down_prusse.svg");
            }
            &::before {
               opacity: 0;
            }
        }
    }
    &.arrow-left-white:not(.cl_bleu_prusse):not(.arrow-right-white) {
        &:hover {
            &::after {
                content: "";
                left: 20px;
                background-image: url("../../assets/icons/arrow-right.svg");
                transform: rotate(180deg);

            }
            &::before {
               opacity: 0;
            }
        }
    }
    &.arrow-right-white:not(.cl_bleu_prusse):not(.arrow-left-white) {
        &:hover {
            &::after {
                content: "";
                right: 20px;
                background-image: url("../../assets/icons/arrow-right.svg");

            }
            &::before {
               opacity: 0;
            }
        }
    }
}


.arrow-right {
    &::before {
        position: absolute;
        content: "";
        right: 20px;
        background-image: url("../../assets/icons/arrow-right.svg");
        width: 16px;
        height: 16px;
    }
}

.arrow-right-white {
    &::before {
        position: absolute;
        content: "";
        right: 20px;
        background-image: url("../../assets/icons/arrow-right-white.svg");
        width: 16px;
        height: 16px;
    }
}

.arrow-left-white {
    &::before {
        position: absolute;
        content: "";
        left: 20px;
        background-image: url("../../assets/icons/arrow-right-white.svg");
        width: 16px;
        height: 16px;
        transform: rotate(180deg);

    }
}

.arrow-down-white {
    &::before {
        position: absolute;
        content: "";
        left: 20px;
        background-image: url("../../assets/icons/carre-down_white.svg");
        width: 16px;
        height: 16px;
        transform: rotate(180deg);
    }
}

.arrow-left {
    &::before {
        position: absolute;
        content: "";
        left: 20px;
        background-image: url("../../assets/icons/arrow-right.svg");
        width: 16px;
        height: 16px;
        transform: rotate(180deg);
    }
}


.s_control_swiper {
    display: flex;
    flex-direction: row;
}