
a,
abbr,
acronym,
address,
applet,
big,
blockquote,
body,
caption,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var {
  border: none;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  text-decoration: none;
  margin: 0;
  padding: 0;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
:focus {
  outline: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  border: 0;
  vertical-align: middle;
}
a {
  text-decoration: none;
  position: relative;
  color: #0a0f25;
}
audio,
canvas,
video {
  max-width: 100%;
}
*,
::after,
::before {
  box-sizing: border-box;
}
a,
button {
  cursor: revert;
}
menu,
ol,
ul {
  list-style: none;
}
img {
  max-width: 100%;
}
table {
  border-collapse: collapse;
}
textarea {
  white-space: revert;
}
meter {
  appearance: revert;
}
::placeholder {
  color: unset;
}
