a,
abbr,
acronym,
address,
applet,
big,
blockquote,
body,
caption,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var {
  border: none;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

:focus {
  outline: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  border: 0;
  vertical-align: middle;
}

a {
  text-decoration: none;
  position: relative;
  color: #0a0f25;
}

audio,
canvas,
video {
  max-width: 100%;
}

*,
::after,
::before {
  box-sizing: border-box;
}

a,
button {
  cursor: revert;
}

menu,
ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
}

table {
  border-collapse: collapse;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

::placeholder {
  color: unset;
}

@font-face {
  font-family: "Gotham Book";
  src: url("../../assets/font/Gotham-Book.otf");
}
@font-face {
  font-family: "Gotham Bold";
  src: url("../../assets/font/Gotham-Bold.otf");
}
@font-face {
  font-family: "Optimal";
  src: url("../../assets/font/OptimaLTStd.otf");
}
@font-face {
  font-family: "Optimal Bold";
  src: url("../../assets/font/OptimaLTStd-Bold.otf");
}
@keyframes animeNumberTop {
  from {
    top: 0;
  }
  50% {
    top: -40px;
  }
  to {
    top: 0;
  }
}
@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 1;
  }
}
@keyframes background-move {
  0% {
    background-color: transparent;
    color: #325a66;
    opacity: 1;
  }
  50% {
    background-color: #325a66;
    color: #ececea;
    opacity: 1;
  }
  100% {
    background-color: transparent;
    color: #325a66;
    opacity: 1;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 1;
  }
}
@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes animeNumberBottom {
  from {
    top: 0;
  }
  50% {
    top: 40px;
  }
  to {
    top: 0;
  }
}
@keyframes menuMove {
  from {
    right: -100%;
  }
  to {
    right: -80px;
  }
}
@keyframes menuUnmove {
  from {
    right: -80px;
  }
  to {
    right: -100%;
  }
}
@keyframes agentMove {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes agentUnmove {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@media screen and (min-width: 200px) and (max-width: 1155px) {
  @keyframes menuMove {
    from {
      right: -100%;
    }
    to {
      right: 0;
    }
  }
  @keyframes menuUnmove {
    from {
      right: 0;
    }
    to {
      right: -100%;
    }
  }
}
.anime {
  opacity: 0;
  transition: opacity 1s;
}

.animed.slide-left {
  animation: slide-in-left 1s ease-in-out both;
}

.animed.slide-out-top {
  opacity: 1 !important;
  animation: slide-out-top 2s ease-in-out both;
}

.animed.slide-out-left {
  opacity: 1 !important;
  animation: slide-out-left 2s ease-in-out both;
}

.animed.slide-out-right {
  opacity: 1 !important;
  animation: slide-out-right 2s ease-in-out both;
}

.animed.slide-right {
  animation: slide-in-right 1s ease-in-out both;
}

.animed.slide-top {
  animation: slide-in-top 1s ease-in-out both;
}

.animed.slide-bottom {
  animation: slide-in-bottom 1s ease-in-out both;
}

.animed.background_move {
  animation: background-move 1s ease-in-out both;
}

.anime.animed {
  opacity: 1;
}

/** @format */
@media screen and (min-width: 1920px) {
  .s_model_content,
.s_estimation_widget,
.s_section > .s_slide_appartement,
.s_review,
.s_content_sell,
.s_large_box,
.s_actualites {
    padding: 5% 15% !important;
  }

  .s_description_detail .mapboxgl-canvas-container .mapboxgl-canvas {
    height: 600px !important;
  }

  .s_actualites > .s_card_actualite > div.s_card_white,
.s_actualites > .s_card_actualite > div.s_card_sable,
.s_actualites > .s_card_actualite > div.s_card_prusse {
    width: 70% !important;
  }

  .s_description_detail > div:first-child > div p > img,
.s_description_detail > div:first-child > div div p > img {
    width: 25% !important;
  }

  .s_general_box .s_seo_model h1 {
    font-size: 30px !important;
  }

  .s_top_logo > img {
    width: 15% !important;
  }

  .s_description_detail > div:first-child > div p,
.s_description_detail > div:first-child > div div p {
    font-size: 14px !important;
  }

  footer > div > p {
    font-size: 12px !important;
  }

  .s_actus {
    padding: 0 11% !important;
  }

  .s_estimation_widget > div:last-child > div:last-child,
.s_estimation_widget > div:last-child > div:first-child,
.s_estimation_widget > div:last-child {
    justify-content: flex-end !important;
  }

  .s_actualites > .s_card_actualite {
    justify-content: center !important;
    height: 400px !important;
  }

  .s_estimation_widget > div:last-child > div:first-child > img {
    width: 3em !important;
  }

  .s_estimation_widget > div:last-child > div:first-child > p {
    margin-right: 13% !important;
  }

  .f_column_reverse > .w_100 {
    margin-right: 5% !important;
  }

  .s_estimation_widget > div:last-child > div:last-child > span {
    padding: 0.8rem !important;
  }

  .s_actus > div:first-child > div {
    padding: 35% 5% !important;
  }

  .s_review > div:last-child,
.s_review > div:last-child > p {
    text-align: center !important;
  }

  .s_review > div:last-child {
    padding: 5% 0 5% 5% !important;
  }

  .s_review > div:first-child {
    margin: 0 !important;
  }
}
@media screen and (min-width: 600px) and (max-width: 1155px) {
  .s_slide_appartement .s_card_appartement > div:first-child img.s_main_picture {
    height: 50vh !important;
  }
}
@media screen and (min-width: 200px) and (max-width: 600px) {
  .s_slide_appartement .s_card_appartement > div:first-child img.s_main_picture {
    height: 30vh !important;
  }
}
@media screen and (min-width: 200px) and (max-width: 415px) {
  .s_actus > div:last-child > div > div {
    height: 150px !important;
  }
}
@media screen and (min-width: 200px) and (max-width: 1155px) {
  .content_blog .s_blog_bloc > img {
    width: 100% !important;
    height: 30vh !important;
    margin: 20px 0 !important;
  }

  .s_menu_modal {
    margin-left: 0 !important;
    padding: 40px !important;
  }

  .s_main_content,
.s_section {
    padding: 0 10px !important;
    overflow: hidden;
  }

  .s_reviews_control_fix {
    right: 0 !important;
    width: 100% !important;
  }
  .s_reviews_control_fix > div {
    width: 50% !important;
  }

  .s_slide_appartement .s_card_appartement > div:last-child {
    padding: 10% 0 20% 0 !important;
    margin-bottom: 0 !important;
  }

  .s_slide_appartement,
.s_actus > div:first-child {
    height: auto !important;
    padding: 10% 0 !important;
  }

  .s_actus > div:last-child,
.s_content_sell > div,
.s_actualites > .s_card_actualite {
    height: auto !important;
  }

  .s_content_sell > div > div,
.s_menu_modal > div:first-child {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 25px !important;
  }

  .s_large_box {
    margin-bottom: 20px;
  }

  .s_menu_modal > div:nth-child(2) > div > div > h2 {
    font-size: 20px !important;
    margin-bottom: 5px !important;
  }

  .s_description > div:first-child > div > div {
    width: 50% !important;
  }

  .s_menu_modal > div:nth-child(2) {
    justify-content: flex-start !important;
  }

  .s_menu_modal > div:nth-child(2) {
    overflow: auto !important;
  }

  .s_menu_modal > div:nth-child(2) > div > div > p {
    margin-bottom: 5px !important;
  }

  .s_menu_modal > div:nth-child(2) {
    height: auto !important;
  }

  .s_actus > div:last-child > div > div > img {
    height: 200px !important;
    object-fit: cover !important;
  }

  .s_actus > div:last-child > div > div {
    width: 100% !important;
    height: 200px !important;
    margin-bottom: 5px !important;
  }

  .s_model_content,
.s_review,
.s_actualites > .s_card_actualite:nth-child(even) {
    flex-direction: column-reverse !important;
  }

  .s_appartement .s_slide_appartement {
    padding: 5% !important;
  }

  .s_estimation_widget {
    padding: 5% 0 !important;
  }

  .s_expert {
    margin: 40px 0;
  }

  .s_expert,
.s_select_option > div,
.estimation-button,
.s_appartement,
.s_description_detail > div:first-child,
.s_general_box,
.s_description > div:first-child,
.s_actualites,
.s_model_content > div:last-child,
.s_actus > div:last-child,
.s_actus > div:last-child,
.s_menu_modal > div:nth-child(2) > div:last-child,
.s_large_box {
    padding: 0 !important;
  }

  .s_menu_modal > div:first-child {
    height: 10% !important;
  }

  .s_general_box .s_model_head .s_img_model {
    height: 50vh !important;
  }

  .s_actualites > .s_card_actualite > div.s_card_image {
    height: 60% !important;
  }

  .s_menu_modal div {
    border: none !important;
  }

  .s_actualites > .s_card_actualite,
.s_actualites > .s_card_actualite > div.s_card_image,
.s_card_sable {
    left: 0 !important;
    align-items: center !important;
  }

  .s_text_filter,
.s_actualites > .s_card_actualite > div.s_card_white {
    padding: 5% !important;
  }

  .s_slide_appartement .s_card_appartement > div:last-child {
    padding-bottom: 0 !important;
  }

  .s_select_option form h4,
.s_select_option .s_step_white,
.appartement,
.maison,
.s_step_1,
.s_connexion,
.s_description_detail,
.s_fix,
.s_card_appartement,
.s_menu_modal > div:nth-child(2),
.s_actus,
.s_content_sell > div,
.s_actualites > .s_card_actualite,
.s_description {
    flex-direction: column !important;
  }

  .s_model_content > div > div {
    flex-direction: row !important;
    margin: 20px 0;
    align-items: flex-start !important;
  }

  .s_actus > div:last-child {
    margin-top: 20px !important;
  }

  .p_20_40 {
    padding: 20px 40px 20px 20px !important;
  }

  .s_menu_svg {
    right: 40px !important;
  }
  .s_menu_svg > span {
    background-color: black !important;
  }

  .s_main_content > img {
    width: 300px !important;
    margin: 40px 0 !important;
  }

  .s_navbar div:nth-child(2) > div {
    width: 30% !important;
  }

  .s_navbar div:nth-child(2) svg {
    width: 30px !important;
  }

  .s_model_content > div > div,
.s_model_content > div:last-child,
.s_model_content > div:first-child,
.s_estimation_widget > div:last-child > div:first-child,
.s_estimation_widget > div:last-child > div:last-child {
    align-items: center !important;
    justify-content: center !important;
  }

  .s_select_option input,
.s_select_option select,
.s_select_option .s_step_white > div:not(:last-child),
.appartement > div,
.maison > div,
.s_expert > div:first-child img,
.s_step_1 > div,
.s_estimation_widget > div:first-child > div > input,
.s_left,
.s_text_filter > span,
.s_text_filter > span,
.s_description_detail > div,
.s_description > div,
.s_card_sable,
.s_card_prusse,
.s_actualites > .s_card_actualite > div.s_card_image,
.s_actualites > .s_card_actualite > div.s_card_white,
.w_50,
.s_menu_modal > div:nth-child(2) > div:last-child > div,
.s_menu_modal > div:nth-child(2) > div:nth-child(2) > div,
.s_menu_modal > div:nth-child(2) > div:first-child > div,
.s_menu_modal > div:nth-child(2) > div,
.s_model_content > div:first-child,
.s_review > div,
.s_actus > div:first-child,
.s_actus > div:last-child,
.s_model_content > div:last-child,
.s_slide_appartement .s_card_appartement > div:first-child,
.s_slide_appartement .s_card_appartement > div:last-child {
    width: 100% !important;
  }

  .s_steps_expert {
    margin-top: 80px !important;
  }

  .s_bloc_expert {
    margin-top: 20px !important;
  }

  .s_slide_appartement .s_card_appartement > div:first-child {
    margin-right: 0 !important;
  }

  .s_actus > div:last-child > div > h2,
.s_actus > div:last-child > div > p,
.s_actus > div:last-child > div > a {
    margin-top: 10px !important;
  }

  .s_actus > div:first-child {
    padding: 0 !important;
    margin: 0 20px 0 0 !important;
  }

  .s_top_logo {
    padding: 120px 0 40px 0 !important;
  }

  .s_slide_appartement .s_card_appartement > div:last-child > h2 {
    font-size: 26px !important;
    height: auto !important;
    margin: 0 10px !important;
  }

  .s_appartement .s_slide_appartement .s_card_appartement > div:last-child b {
    font-size: 16px !important;
  }
  .s_appartement .s_slide_appartement .s_card_appartement > div:last-child h2 {
    font-size: 26px !important;
  }
  .s_appartement .s_slide_appartement .s_card_appartement > div:last-child p {
    display: none !important;
  }

  .s_card_appartement > div:last-child > h2 {
    -webkit-line-clamp: 2 !important;
  }

  .s_actus > div:last-child > div:first-child {
    padding: 0 5px 0 0 !important;
  }

  footer > ul > li {
    padding: 10px !important;
  }

  footer > ul {
    flex-wrap: wrap !important;
    padding: 0 20px 20px 5%;
  }

  .s_model_content > div > p {
    text-align: center !important;
  }

  footer > div > p {
    text-align: center !important;
    padding: 0 15%;
  }

  .s_review > div:first-child,
.s_menu_modal > div:nth-child(2) > div:last-child,
.s_select_option form h4 p {
    margin: 0 !important;
  }

  .s_select_option form h4 {
    align-items: flex-start !important;
  }

  .s_model_content,
.s_model_content > div > img,
.s_actus > div:last-child > div:last-child {
    padding: 0 !important;
    margin-bottom: 40px !important;
  }

  .s_estimation_widget {
    flex-direction: column !important;
  }
  .s_estimation_widget > div {
    margin-top: 5% !important;
    width: 100% !important;
    align-items: center !important;
  }
  .s_estimation_widget > div > h2 {
    font-size: 25px !important;
  }

  .s_actus > div:first-child > div > p {
    font-size: 12px !important;
  }

  .s_actus > div:first-child > div > span {
    font-size: 10px !important;
  }

  .s_model_content > div > img {
    padding: 20px 0 !important;
  }

  body section .w_80 {
    width: auto !important;
  }

  .s_actus > div:first-child > div {
    padding: 20px !important;
    background-color: white !important;
    margin: 0 -20px !important;
    border-radius: 0 !important;
  }

  .s_model_content {
    margin-top: 5% !important;
  }
  .s_slide_appartement .s_card_appartement > div:last-child > b {
    margin: 10px !important;
  }

  .s_actus > div:last-child > div {
    justify-content: flex-start !important;
  }

  .s_left h1 {
    letter-spacing: 1px !important;
    font-size: 20px !important;
  }

  .s_actus > div:last-child {
    align-items: flex-start !important;
  }

  .s_reseaux,
.s_menu_modal > div:last-child,
.s_slide_appartement .s_card_appartement > div:last-child > p,
.s_top_header,
.s_right {
    display: none !important;
  }

  .s_navbar {
    width: 100% !important;
    height: 80px !important;
    flex-direction: row !important;
    align-items: flex-start !important;
  }
  .s_navbar > div {
    width: 33.3% !important;
  }
  .s_navbar > div:first-child {
    justify-content: flex-start !important;
    padding: 20px 0 20px 40px !important;
  }
  .s_navbar > div:nth-child(2) {
    flex-direction: row !important;
    height: 100% !important;
  }
}
/** @format */
.s_navbar {
  position: fixed;
  left: 0;
  top: 0;
  width: 80px;
  height: 100%;
  background-color: #ececea;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  z-index: 10;
  align-items: center;
  align-content: center;
}
.s_navbar div:first-child {
  position: relative;
  width: 100%;
  height: 10%;
  padding: 20px 0;
  display: flex;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
}
.s_navbar div:first-child img {
  cursor: pointer;
  width: 2.5rem;
  height: auto;
  position: relative;
}
.s_navbar div:nth-child(2) {
  position: relative;
  width: 100%;
  height: 80%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.s_navbar div:nth-child(2) > div {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  overflow: hidden;
}
.s_navbar div:nth-child(2) > div span {
  font-size: 1em;
  letter-spacing: 1px;
  width: 20px;
  color: #325a66;
}
.s_navbar div:nth-child(2) svg {
  width: 40px;
  height: auto;
  cursor: pointer;
  transition: all 0.5s;
}
.s_navbar div:nth-child(2) svg:hover {
  fill: #b98a53;
}
.s_navbar div:nth-child(2) svg:first-child {
  margin-bottom: 20px;
}
.s_navbar div:nth-child(2) svg:last-child {
  margin-top: 20px;
}
.s_navbar div:nth-child(3) {
  position: relative;
  width: 100%;
  height: 10%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.s_navbar div:nth-child(3) > a {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.s_navbar div:nth-child(3) svg {
  position: relative;
  width: 40px;
  height: 20px;
  border-radius: 5px;
  height: auto;
  padding: 8px;
  cursor: pointer;
  transition: all 0.5s;
}
.s_navbar div:nth-child(3) svg:hover {
  fill: #b98a53;
}

.s_animated_top {
  position: relative;
  animation-duration: 0.5s;
  animation-name: animeNumberTop;
  top: 0;
}

.s_animated_bottom {
  position: relative;
  animation-duration: 0.5s;
  animation-name: animeNumberBottom;
  top: 0;
}

.s_reseaux {
  position: fixed;
  right: 2.3%;
  width: 0;
  top: 45%;
  transform: rotate(270deg);
  transform-origin: 0 0;
  height: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  z-index: 11;
  mix-blend-mode: difference;
}
.s_reseaux span,
.s_reseaux a {
  font-size: 12px;
  letter-spacing: 5px;
  color: #FFF;
  text-transform: uppercase;
  padding-right: 0;
  cursor: pointer;
  transition: all 3s;
}
.s_reseaux span.animed,
.s_reseaux a.animed {
  padding-right: 64px;
}

.s_menu_svg {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  right: 2.3%;
  top: 20px;
  z-index: 51;
  width: 50px;
  mix-blend-mode: difference;
  cursor: pointer;
}
.s_menu_svg input[type=checkbox] {
  display: none;
}
.s_menu_svg input[type=checkbox]:checked ~ span {
  transform: none;
}
.s_menu_svg input[type=checkbox]:checked ~ span:nth-of-type(1) {
  width: 50%;
}
.s_menu_svg input[type=checkbox]:checked ~ span:nth-of-type(2) {
  width: 100%;
}
.s_menu_svg input[type=checkbox]:checked ~ span:nth-of-type(3) {
  width: 75%;
}
.s_menu_svg span {
  background: #fff;
  border-radius: 10px;
  height: 2px;
  margin: 0;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.s_menu_svg span.animed {
  margin: 5px 0;
}
.s_menu_svg span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotatez(45deg) translate(-13px, 18px);
  width: 50%;
}
.s_menu_svg span:nth-of-type(2) {
  transform-origin: top;
  transform: rotatez(-45deg);
  width: 100%;
}
.s_menu_svg span:nth-of-type(3) {
  transform-origin: bottom;
  transform: translate(-4px, -3px) rotatez(45deg);
  width: 50%;
}

.s_active_menu {
  animation: menuMove 1s forwards;
}

.s_unactive_menu {
  animation: menuUnmove 1s forwards;
}

.s_menu_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 50;
  right: -100%;
  background: #FFF;
  padding: 40px 160px 40px 80px;
  margin-left: 80px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
}
.s_menu_modal > div:first-child {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-content: center;
}
.s_menu_modal > div:first-child > img {
  height: auto;
}
.s_menu_modal > div:nth-child(2) {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
}
.s_menu_modal > div:nth-child(2) > div {
  width: 33.3%;
}
.s_menu_modal > div:nth-child(2) > div > div > h2 {
  position: relative;
  font-family: "Optima";
  font-size: 25px;
  text-transform: uppercase;
  line-height: 42px;
  margin-bottom: 10px;
  text-align: center;
}
.s_menu_modal > div:nth-child(2) > div > div > h2 a {
  color: #325a66;
  transition: all 0.5s;
}
.s_menu_modal > div:nth-child(2) > div > div > h2 a:hover {
  letter-spacing: 1px;
}
.s_menu_modal > div:nth-child(2) > div > div > p {
  position: relative;
  text-align: center;
  font-family: "Gotham Book";
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}
.s_menu_modal > div:nth-child(2) > div > div > p a {
  color: #c9c1b6;
  transition: all 0.5s;
}
.s_menu_modal > div:nth-child(2) > div > div > p a:hover {
  color: #b98a53;
}
.s_menu_modal > div:nth-child(2) > div:first-child {
  display: flex;
  border-right: 2px solid #ececea;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}
.s_menu_modal > div:nth-child(2) > div:first-child > div {
  width: 60%;
  margin: 0 auto;
}
.s_menu_modal > div:nth-child(2) > div:nth-child(2) {
  display: flex;
  border-right: 2px solid #ececea;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}
.s_menu_modal > div:nth-child(2) > div:nth-child(2) > div {
  width: 60%;
  margin: 0 auto;
}
.s_menu_modal > div:nth-child(2) > div:last-child {
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}
.s_menu_modal > div:nth-child(2) > div:last-child > div {
  width: 60%;
  margin: 0 auto;
}
.s_menu_modal > div:last-child {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.s_menu_modal > div:last-child > div:last-child {
  width: 200px;
  height: 100%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0;
}
.s_menu_modal > div:last-child > div:last-child > div:first-child {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: space-between;
}
.s_menu_modal > div:last-child > div:last-child > div:first-child > p {
  font-size: 10px;
  font-family: "Gotham Bold";
  font-weight: bold;
  position: relative;
  color: #325a66;
}
.s_menu_modal > div:last-child > div:last-child > div:first-child > img {
  width: 2rem;
}
.s_menu_modal > div:last-child > div:last-child > div:last-child {
  padding: 5px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
}
.s_menu_modal > div:last-child > div:last-child > div:last-child > span {
  background-color: #325a66;
  border-radius: 5px;
  padding: 0.5rem;
  margin-left: 5px;
}
.s_menu_modal > div:last-child > div:last-child > div:last-child > span:first-child {
  margin: 0;
}

button {
  margin-top: 20px;
}

.s_button {
  position: relative;
  font-family: "Gotham Bold";
  width: auto;
  height: auto;
  display: flex;
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
  z-index: 10;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;
  transition: 0.5s ease-in-out;
}
.s_button:not(.cl_white):hover {
  color: white;
  background-color: #325a66;
}
.s_button:not(.cl_white):hover::after {
  position: absolute;
  width: 16px;
  height: 16px;
}
.s_button.arrow-right:not(.cl_white):not(.arrow-left):hover::after {
  content: "";
  right: 20px;
  background-image: url("../../assets/icons/arrow-right-white.svg");
}
.s_button.arrow-left:not(.cl_white):not(.arrow-right):hover::after {
  content: "";
  left: 20px;
  transform: rotate(180deg);
  background-image: url("../../assets/icons/arrow-right-white.svg");
}
.s_button:not(.cl_bleu_prusse):hover {
  color: #325a66;
  background-color: #FFF;
}
.s_button:not(.cl_bleu_prusse):hover::after {
  position: absolute;
  width: 16px;
  height: 16px;
}
.s_button.arrow-right:not(.cl_bleu_prusse):not(.arrow-left):hover::after {
  content: "";
  right: 20px;
  background-image: url("../../assets/icons/arrow-right-white.svg");
}
.s_button.arrow-left:not(.cl_bleu_prusse):not(.arrow-right):hover::after {
  content: "";
  left: 20px;
  transform: rotate(180deg);
  background-image: url("../../assets/icons/arrow-right-white.svg");
}
.s_button.arrow-down-white:not(.cl_bleu_prusse):not(.arrow-right):not(.arrow-left):hover::after {
  content: "";
  right: 20px;
  background-image: url("../../assets/icons/carre-down_prusse.svg");
}
.s_button.arrow-down-white:not(.cl_bleu_prusse):not(.arrow-right):not(.arrow-left):hover::before {
  opacity: 0;
}
.s_button.arrow-left-white:not(.cl_bleu_prusse):not(.arrow-right-white):hover::after {
  content: "";
  left: 20px;
  background-image: url("../../assets/icons/arrow-right.svg");
  transform: rotate(180deg);
}
.s_button.arrow-left-white:not(.cl_bleu_prusse):not(.arrow-right-white):hover::before {
  opacity: 0;
}
.s_button.arrow-right-white:not(.cl_bleu_prusse):not(.arrow-left-white):hover::after {
  content: "";
  right: 20px;
  background-image: url("../../assets/icons/arrow-right.svg");
}
.s_button.arrow-right-white:not(.cl_bleu_prusse):not(.arrow-left-white):hover::before {
  opacity: 0;
}

.arrow-right::before {
  position: absolute;
  content: "";
  right: 20px;
  background-image: url("../../assets/icons/arrow-right.svg");
  width: 16px;
  height: 16px;
}

.arrow-right-white::before {
  position: absolute;
  content: "";
  right: 20px;
  background-image: url("../../assets/icons/arrow-right-white.svg");
  width: 16px;
  height: 16px;
}

.arrow-left-white::before {
  position: absolute;
  content: "";
  left: 20px;
  background-image: url("../../assets/icons/arrow-right-white.svg");
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
}

.arrow-down-white::before {
  position: absolute;
  content: "";
  left: 20px;
  background-image: url("../../assets/icons/carre-down_white.svg");
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
}

.arrow-left::before {
  position: absolute;
  content: "";
  left: 20px;
  background-image: url("../../assets/icons/arrow-right.svg");
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
}

.s_control_swiper {
  display: flex;
  flex-direction: row;
}

/** @format */
.s_main_section {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 9;
}
.s_main_section .wallpaper-slider {
  position: absolute;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.s_main_section .wallpaper-slider .image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all 3s ease-in-out;
  transform: scale(2);
}
.s_main_section .wallpaper-slider .image.disable {
  opacity: 0;
}
.s_main_section .wallpaper-slider .image.active {
  opacity: 1;
  animation: zoom 10s linear;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(2);
  }
}
.s_main_content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  z-index: 10;
}
.s_main_content > img {
  width: 30vh;
  z-index: 10;
}

.s_section {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 5;
  padding-left: 80px;
}
.s_section > p {
  position: relative;
  font-family: "Gotham Book";
  font-size: 16px;
  letter-spacing: 0.5px;
  text-align: justify;
  line-height: 22px;
  padding-bottom: 20px;
  padding: 20px 5%;
}

.s_fix {
  position: relative;
  width: 100%;
  height: 100vh;
}

.s_top_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5% 0 0 0;
  background-color: white;
}

.s_large_box {
  width: 100%;
  height: auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 5%;
  background-color: white;
}
.s_large_box ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 10px 0;
}
.s_large_box ul li {
  font-family: "Gotham Book";
  font-weight: normal;
  font-size: 14px;
  text-align: justify;
  margin-bottom: 5px;
}
.s_large_box > div {
  padding: 20px 40px;
  border-radius: 10px;
}
.s_large_box > div > h4 {
  font-family: "Gotham Bold";
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  color: #325a66;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.s_large_box > div > p {
  font-family: "Gotham Book";
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
}

.s_actualites {
  position: relative;
  display: flex;
  background-color: white;
  flex-direction: column;
  padding: 5%;
  width: 100%;
  height: 100%;
}
.s_actualites > span {
  position: relative;
  font-family: "Optimal Bold";
  font-size: 14px;
  color: #c9c1b6;
  text-transform: uppercase;
  padding-bottom: 20px;
}
.s_actualites > h2 {
  position: relative;
  font-family: "Optimal";
  font-size: 35px;
  color: #325a66;
  padding-bottom: 20px;
}
.s_actualites > .s_card_actualite {
  position: relative;
  left: -3%;
  margin: 5% 0;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
}
.s_actualites > .s_card_actualite > div {
  position: relative;
  border-radius: 10px;
}
.s_actualites > .s_card_actualite > div > div {
  border-radius: 10px;
}
.s_actualites > .s_card_actualite > div > img {
  position: relative;
  object-fit: cover;
  height: 100%;
  border-radius: 10px;
  width: 100%;
}
.s_actualites > .s_card_actualite > div.s_card_image {
  left: 10%;
  z-index: 10;
  margin: 2% 0;
  width: 25%;
}
.s_actualites > .s_card_actualite > div.s_card_sable {
  position: relative;
  background-color: #c9c1b6;
  padding: 20px 15% 20px 20px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.s_actualites > .s_card_actualite > div.s_card_sable > h4 {
  position: relative;
  font-family: "Optima";
  font-size: 20px;
  color: #325a66;
}
.s_actualites > .s_card_actualite > div.s_card_sable > span {
  position: relative;
  font-family: "Gotham Book";
  font-size: 10px;
  color: white;
}
.s_actualites > .s_card_actualite > div.s_card_sable > p {
  position: relative;
  font-family: "Gotham Book";
  font-size: 12px;
  color: #0a0f25;
  line-height: 20px;
  margin: 20px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.s_actualites > .s_card_actualite > div.s_card_prusse {
  position: relative;
  background-color: #325a66;
  padding: 20px 20px 20px 15%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.s_actualites > .s_card_actualite > div.s_card_prusse > h4 {
  position: relative;
  font-family: "Optima";
  font-size: 20px;
  color: white;
}
.s_actualites > .s_card_actualite > div.s_card_prusse > span {
  position: relative;
  font-family: "Gotham Book";
  font-size: 10px;
  color: #c9c1b6;
}
.s_actualites > .s_card_actualite > div.s_card_prusse > p {
  position: relative;
  font-family: "Gotham Book";
  font-size: 12px;
  color: white;
  line-height: 20px;
  margin: 20px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.s_actualites > .s_card_actualite > div.s_card_white {
  position: relative;
  background-color: #ececea;
  padding: 20px 20px 20px 15%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.s_actualites > .s_card_actualite > div.s_card_white > h4 {
  position: relative;
  font-family: "Optima";
  font-size: 20px;
  color: #325a66;
}
.s_actualites > .s_card_actualite > div.s_card_white > span {
  position: relative;
  font-family: "Gotham Book";
  font-size: 10px;
  color: #c9c1b6;
}
.s_actualites > .s_card_actualite > div.s_card_white > p {
  position: relative;
  font-family: "Gotham Book";
  font-size: 12px;
  color: #0a0f25;
  line-height: 20px;
  margin: 20px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.s_model_content {
  position: relative;
  display: flex;
  background-color: white;
  padding: 5%;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}
.s_model_content > div {
  position: relative;
  width: 100%;
  height: 100%;
}
.s_model_content > div > span {
  position: relative;
  font-family: "Optimal Bold";
  font-size: 15px;
  color: #c9c1b6;
  text-transform: uppercase;
  padding-bottom: 20px;
  letter-spacing: 2px;
}
.s_model_content > div > p {
  position: relative;
  font-family: "Gotham Book";
  font-size: 16px;
  letter-spacing: 0.5px;
  text-align: justify;
  line-height: 22px;
  padding-bottom: 20px;
}
.s_model_content > div > p > a {
  position: relative;
  font-size: 12px;
  font-family: "Gotham Bold";
  text-transform: uppercase;
  color: #325a66;
  transition: 1s all;
}
.s_model_content > div > p > a:hover {
  color: #0a0f25;
}
.s_model_content > div > h2 {
  position: relative;
  font-family: "Optimal";
  font-size: 36px;
  color: #325a66;
  padding-bottom: 20px;
}
.s_model_content > div .s_information {
  justify-content: flex-start;
  display: flex;
  margin-bottom: 20px;
}
.s_model_content > div .s_information > div:first-child {
  margin-right: 20px;
}
.s_model_content > div .s_information > div:first-child img {
  width: 20px;
  height: auto;
}
.s_model_content > div .s_information > div:last-child > h4 {
  font-family: "Gotham Bold";
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: #325a66;
}
.s_model_content > div .s_information > div:last-child > p,
.s_model_content > div .s_information > div:last-child a {
  font-family: "Gotham Book";
  font-size: 12px;
  line-height: 20px;
  color: #0a0f25;
}
.s_model_content > div .s_information_contact {
  height: auto;
  flex-direction: column;
}
.s_model_content > div > div {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  width: 100%;
  height: 80px;
  justify-content: space-between;
}
.s_model_content > div > img {
  position: relative;
  width: auto;
  height: 100%;
}
.s_model_content > div:first-child {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.s_model_content > div:last-child {
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.s_model_content .f_end {
  align-items: flex-end;
}

.s_description_detail {
  width: 100%;
  margin: 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.s_description_detail .mapboxgl-canvas-container {
  position: relative;
  height: 100%;
}
.s_description_detail .mapboxgl-canvas-container .mapboxgl-canvas {
  height: 400px;
  position: relative;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}
.s_description_detail > div {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.s_description_detail > div:first-child {
  padding-right: 5%;
}
.s_description_detail > div:first-child > div {
  margin-bottom: 20px;
  width: 100%;
}
.s_description_detail > div:first-child > div b {
  font-family: "Gotham Book";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-right: 10px;
  text-transform: uppercase;
  color: #325a66;
}
.s_description_detail > div:first-child > div p,
.s_description_detail > div:first-child > div div p {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-family: "Gotham Bold";
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  color: #0a0f25;
  margin: 10px 0;
  align-items: center;
}
.s_description_detail > div:first-child > div p > img,
.s_description_detail > div:first-child > div div p > img {
  width: 35%;
}
.s_description_detail > div:first-child > div > span {
  font-family: "Optimal";
  font-style: normal;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #325a66;
}

.s_description {
  width: 100%;
  margin: 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.s_description > div {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.s_description > div:first-child {
  padding-right: 5%;
}
.s_description > div:first-child > div {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.s_description > div:first-child > div > div {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.s_description > div:first-child > div > div > div:first-child {
  width: 60px;
  height: 42px;
  border-radius: 10px;
  background: #325a66;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.s_description > div:first-child > div > div > div:first-child > img {
  width: 16px;
  height: 16px;
}
.s_description > div:first-child > div > div > div:last-child {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
}
.s_description > div:first-child > div > div > div:last-child > p {
  font-family: "Gotham Book";
  font-size: 12px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #0a0f25;
}
.s_description > div:first-child > div > div > div:last-child > span {
  font-family: "Gotham Bold";
  font-style: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-feature-settings: "cpsp" on;
  color: #325a66;
}
.s_description > div:last-child h4 {
  font-family: "Optimal";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  color: #325a66;
  margin-bottom: 20px;
}
.s_description > div:last-child p {
  font-family: "Gotham Book";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  color: #0a0f25;
}

.s_general_box {
  background-color: white;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 5%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.s_general_box .s-table {
  border-radius: 10px;
  border: 1px solid #f3f3f9;
  color: #325a66;
  width: 100%;
  padding-right: 0 !important;
}
.s_general_box .s-table .s-table-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid #f3f3f9;
  margin-bottom: 0;
}
.s_general_box .s-table .s-table-row:nth-child(even) {
  background-color: #c9c0b652;
}
.s_general_box .s-table .s-table-row .s-table-cell {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  align-items: center;
  align-content: center;
}
.s_general_box .s-table .s-table-row .s-table-cell:first-child {
  border-right: 1px solid #f3f3f9;
}
.s_general_box .s-table .s-table-row .s-table-cell span {
  font-family: "Optimal";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.s_general_box .s_step {
  display: flex;
  align-items: center;
  margin: 10px 0;
  justify-content: flex-start;
}
.s_general_box .s_step span {
  font-family: "Optimal";
  background: #c9c1b6;
  padding-top: 3px;
  height: 30px;
  margin: 0 20px 0 0;
  border: 1px solid #eee;
  color: white;
  display: flex;
  width: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.s_general_box .s_step h4 {
  font-family: "Optimal";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 42px;
  color: #325a66;
}
.s_general_box .s_seo_model {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  z-index: 5;
  overflow: hidden;
  margin: 20px 0 20px 0;
}
.s_general_box .s_seo_model > div {
  width: 100%;
}
.s_general_box .s_seo_model h1 {
  font-family: "Optimal";
  font-style: normal;
  font-weight: 400;
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 42px;
  color: #325a66;
}
.s_general_box .s_model_head {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 5;
  overflow: hidden;
}
.s_general_box .s_model_head .s_img_model {
  position: relative;
  height: 70vh;
  width: 80%;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  margin-left: auto;
  margin-right: auto;
}
.s_general_box .s_model_head .s_img_model img {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}
.s_general_box .s_model_head > div:first-child {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.s_slide_appartement {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 40px;
  overflow: hidden;
}
.s_slide_appartement .s_card_appartement .s_card_fix {
  height: 100% !important;
}
.s_slide_appartement .s_card_appartement > div {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s_slide_appartement .s_card_appartement > div:last-child {
  width: 50%;
}
.s_slide_appartement .s_card_appartement > div:first-child {
  width: 50%;
  margin-right: 5%;
  height: 80%;
}
.s_slide_appartement .s_card_appartement > div:first-child img.s_main_picture {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.s_slide_appartement .s_card_appartement > div:first-child img.s_filigrame {
  position: absolute;
  width: 145px;
  opacity: 0.5;
  z-index: 10;
}
.s_slide_appartement .s_card_appartement > div:last-child {
  height: 100%;
  display: flex;
  margin-right: 20px;
  flex-direction: column;
  align-items: flex-start;
  background-color: #c9c1b6;
  justify-content: center;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_slide_appartement .s_card_appartement > div:last-child {
    margin-right: 0;
  }
}
.s_slide_appartement .s_card_appartement > div:last-child > span {
  position: relative;
  font-family: "Optimal";
  font-size: 16px;
  color: #ececea;
  text-transform: uppercase;
  background-color: #c9c1b6;
  margin-bottom: 10px;
  width: 100%;
}
.s_slide_appartement .s_card_appartement > div:last-child > b {
  font-family: "Gotham Book";
  font-size: 20px;
  background: #c9c1b6;
  width: 100%;
  color: #325a66;
  letter-spacing: 5px;
  margin: 10px 0;
}
.s_slide_appartement .s_card_appartement > div:last-child > h2 {
  position: relative;
  font-family: "Optimal";
  font-size: 35px;
  color: #325a66;
  width: 100%;
  background-color: #c9c1b6;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.s_slide_appartement .s_card_appartement > div:last-child > p {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  text-align: justify;
  overflow: hidden;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #c9c1b6;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.s_slide_appartement .s_card_appartement > div:last-child > div {
  position: relative;
  width: 100%;
  display: flex;
  background-color: #c9c1b6;
  flex-wrap: wrap;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_slide_appartement .s_card_appartement > div:last-child > div.show {
    display: none;
  }
}
.s_slide_appartement .s_card_appartement > div:last-child > div div {
  width: 50%;
  padding: 10px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
}
.s_slide_appartement .s_card_appartement > div:last-child > div div > div:first-child {
  width: 3rem;
  background: #325a66;
  border-radius: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s_slide_appartement .s_card_appartement > div:last-child > div div > div:last-child {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
.s_slide_appartement .s_card_appartement > div:last-child > div div > div:last-child > p {
  text-transform: uppercase;
  font-family: "Gotham Book";
  font-size: 12px;
  background-color: #c9c1b6;
}
.s_slide_appartement .s_card_appartement > div:last-child > div div > div:last-child > span {
  font-family: "Gotham Bold";
  font-size: 12px;
  color: #325a66;
  text-transform: uppercase;
  background-color: #c9c1b6;
  width: 100%;
}

.s_actus {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.s_actus > div:first-child {
  width: 40%;
  display: flex;
  height: 35rem;
  padding: 5% 5% 0 5%;
  align-items: flex-end;
}
.s_actus > div:first-child > div {
  position: relative;
  padding: 20px;
  display: flex;
  top: 0%;
  z-index: 20;
  height: auto;
  padding: 40% 5%;
  border-radius: 10px;
  background-color: #ececea;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.s_actus > div:first-child > div > span {
  position: relative;
  font-size: 16px;
  font-family: "Optimal Bold";
  color: #c9c1b6;
  margin-bottom: 30px;
}
.s_actus > div:first-child > div > p {
  position: relative;
  font-family: "Optimal";
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 10px;
}
.s_actus > div:first-child > div > h2 {
  position: relative;
  color: #325a66;
  font-size: 30px;
  font-family: "Optimal";
  margin-bottom: 10px;
}
.s_actus > div:last-child {
  position: relative;
  padding: 5% 5% 0 0;
  width: 60%;
  height: 35rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.s_actus > div:last-child > div {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.s_actus > div:last-child > div:first-child {
  padding-right: 5%;
}
.s_actus > div:last-child > div:last-child {
  padding-left: 5%;
}
.s_actus > div:last-child > div > h2 {
  position: relative;
  font-size: 23px;
  color: #325a66;
  font-family: "Optimal";
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.s_actus > div:last-child > div > p {
  position: relative;
  font-size: 12px;
  font-family: "Gotham Book";
  width: 100%;
  line-height: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-align: left;
  margin-bottom: 20px;
  /* Track */
  /* Handle */
}
.s_actus > div:last-child > div > p::-webkit-scrollbar {
  width: 2px;
}
.s_actus > div:last-child > div > p::-webkit-scrollbar-track {
  border-radius: 5px;
}
.s_actus > div:last-child > div > p::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #325a66;
}
.s_actus > div:last-child > div > div {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.s_actus > div:last-child > div > div > img {
  border-radius: 10px;
  height: 250px;
  width: 100%;
  object-fit: cover;
}

.content_blog {
  position: relative;
  width: 100%;
  height: auto;
  padding: 5%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.content_blog h1 {
  font-family: "Optimal";
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 42px;
  margin-bottom: 20px;
  color: #325a66;
}
.content_blog > p {
  font-family: "Gotham Book";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 40px 0 20px 0;
  color: #0a0f25;
}
.content_blog .s_blog_bloc {
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
  margin-bottom: 20px;
}
.content_blog .s_blog_bloc .strong {
  font-family: "Gotham Bold";
}
.content_blog .s_blog_bloc > img {
  float: left;
  width: 50%;
  height: 50vh;
  object-fit: cover;
  border-radius: 10px;
  margin: 20px 20px 20px 0;
}
.content_blog .s_blog_bloc > ul,
.content_blog .s_blog_bloc > div > ul {
  padding: 20px 0 20px 40px;
  width: 100%;
  height: auto;
}
.content_blog .s_blog_bloc > ul > li {
  list-style-type: disc;
  font-size: 14px;
  line-height: 25px;
}
.content_blog .s_blog_bloc > div > ul > li {
  list-style-type: disc;
  font-size: 14px;
  line-height: 25px;
}
.content_blog .s_blog_bloc > div a {
  color: #325a66;
  padding-bottom: 5px;
  border-bottom: 1px solid #0a0f25;
  transition: all 1s;
}
.content_blog .s_blog_bloc > div a:hover {
  color: #0a0f25;
}
.content_blog .s_blog_bloc > div table {
  position: relative;
  width: 100%;
  height: auto;
  background: whitesmoke;
  margin: 20px 0;
}
.content_blog .s_blog_bloc > div tr {
  position: relative;
  border-bottom: 1px solid #eee;
}
.content_blog .s_blog_bloc > div .color {
  background: #325a66 !important;
}
.content_blog .s_blog_bloc > div td {
  position: relative;
  text-align: center;
  font-size: 14px;
  padding: 10px;
  border-right: 2px solid #eee;
  color: #0a0f25;
}
.content_blog .s_blog_bloc > div th {
  color: whitesmoke;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  font-size: 12px;
  border-right: 2px solid #eee;
}
.content_blog .s_blog_bloc > span {
  position: relative;
  width: 100%;
  height: 2px;
  margin-bottom: 40px;
  margin-top: 20px;
  background-color: #eee;
}
.content_blog .s_blog_bloc > h1 {
  position: relative;
  margin-bottom: 20px;
  line-height: 40px;
  font-size: 26px;
  color: #0a0f25;
  text-transform: uppercase;
}
.content_blog .s_blog_bloc > h2::before {
  position: absolute;
  content: "";
  width: 20%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #0a0f25;
}
.content_blog .s_blog_bloc > h1::before {
  background-color: #325a66;
}
.content_blog .s_blog_bloc > div {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  margin: 0;
}
.content_blog .s_blog_bloc > div > h2 {
  margin-bottom: 20px;
  font-size: 20px;
  color: #325a66;
  text-transform: uppercase;
  position: relative;
}
.content_blog .s_blog_bloc > h1 > b {
  color: #325a66;
}
.content_blog .s_blog_bloc > p {
  position: relative;
  width: 100%;
  height: auto;
  text-align: justify;
  padding: 10px 0;
  line-height: 20px;
  font-size: 14px;
}
.content_blog .s_blog_bloc > div > p {
  position: relative;
  width: 100%;
  height: auto;
  text-align: justify;
  padding: 10px 0;
  line-height: 20px;
  font-size: 14px;
}
.content_blog .s_blog_bloc > div > p > b {
  color: #325a66;
}
.content_blog .s_blog_bloc > div > div {
  display: flex;
  flex-direction: row;
  height: auto;
  align-items: flex-start;
}
.content_blog .s_blog_bloc > div > div > p {
  margin-bottom: 5px;
  font-size: 10px;
  text-transform: uppercase;
}
.content_blog .s_blog_bloc > div > div > span {
  margin-right: 20px;
  margin: 10px 0;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.content_blog .s_blog_bloc > div > div > ul {
  padding: 10px;
  display: flex;
  flex-direction: row;
  font-family: "Gotham Book";
  justify-content: flex-end;
  align-items: flex-start;
}
.content_blog .s_blog_bloc > div > div > ul > li > a {
  color: #0a0f25;
  text-transform: uppercase;
  font-size: 12px;
  border: none;
  letter-spacing: 2px;
  font-family: "Gotham Book";
  transition: all 1s;
}
.content_blog .s_blog_bloc > div > div > ul > li > a > img {
  width: 46px;
  height: 46px;
  padding: 0 10px;
}
.content_blog .s_blog_bloc > div > div > ul > li > a > i {
  font-size: 20px;
  border: 1px solid #0a0f25;
  color: #0a0f25;
  border-radius: 3px;
  margin: 0 10px 0 0;
  padding: 5px;
  transition: all 1s;
}
.content_blog .s_blog_bloc > div > div > ul > li > a > i:hover {
  border-radius: 50%;
  color: #325a66;
  border: 1px solid #325a66;
}
.content_blog .s_footer_blog {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.content_blog .s_footer_blog > div:first-child {
  flex-direction: column;
}
.content_blog .s_footer_blog > div:first-child > b {
  display: flex;
  font-weight: normal;
}
.content_blog .s_footer_blog > div:first-child > b > p {
  color: #325a66;
  margin: 0 5px;
}

.s_reviews_control_fix {
  position: relative;
  right: 0;
  width: 47.5%;
  margin-left: auto;
}

.s_review {
  width: 100%;
  height: auto;
  padding: 5% 0;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.s_review > div {
  padding: 5%;
  width: 50%;
}
.s_review > div:first-child {
  position: relative;
  height: auto;
  overflow: hidden !important;
  margin-left: 5%;
  padding: 5% 0 5% 0;
}
.s_review > div:first-child .s_card_review {
  position: relative;
  background-color: #C9C1B6;
  border-radius: 10px;
  padding: 5% 10%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.s_review > div:first-child .s_card_review > h2 {
  position: relative;
  color: #325a66;
  font-size: 35px;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-family: "Optimal";
}
.s_review > div:first-child .s_card_review > p {
  position: relative;
  color: #0a0f25;
  font-family: "Gotham Book";
  font-size: 14px;
  line-height: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 80px;
  margin-bottom: 10px;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.s_review > div:first-child .s_card_review > p::-webkit-scrollbar {
  width: 5px;
}
.s_review > div:first-child .s_card_review > p::-webkit-scrollbar-track {
  background: transparent;
}
.s_review > div:first-child .s_card_review > p::-webkit-scrollbar-thumb {
  background: #325a66;
}
.s_review > div:first-child .s_card_review > p::-webkit-scrollbar-thumb:hover {
  background: #325a66;
}
.s_review > div:first-child .s_card_review > div > span {
  background-color: #325a66;
  border-radius: 5px;
  padding: 0.5rem;
  margin-left: 5px;
}
.s_review > div:first-child .s_card_review > div > span > img {
  width: 15px;
}
.s_review > div:first-child .s_card_review > div > span:first-child {
  margin: 0;
}
.s_review > div:last-child {
  position: relative;
  height: auto;
}
.s_review > div:last-child > span {
  position: relative;
  font-size: 18px;
  font-family: "Optimal";
  color: #c9c1b6;
}
.s_review > div:last-child > h2 {
  position: relative;
  font-family: "Optimal";
  font-size: 35px;
  color: #325a66;
  margin: 20px 0;
}
.s_review > div:last-child > p {
  position: relative;
  font-size: 14px;
  font-family: "Gotham Book";
  line-height: 20px;
  text-align: justify;
  margin-bottom: 10px;
}
.s_review > div:last-child > div {
  width: 200px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 40px 0 0 5%;
}
.s_review > div:last-child > div > div:first-child {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: space-between;
}
.s_review > div:last-child > div > div:first-child > p {
  font-size: 10px;
  font-family: "Gotham Bold";
  font-weight: bold;
  position: relative;
  color: #325a66;
}
.s_review > div:last-child > div > div:first-child > p > b {
  color: #325a66;
}
.s_review > div:last-child > div > div:first-child > img {
  width: 2rem;
}
.s_review > div:last-child > div > div:last-child {
  padding: 5px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
}
.s_review > div:last-child > div > div:last-child > span {
  background-color: #325a66;
  border-radius: 5px;
  padding: 0.5rem;
  margin-left: 5px;
}
.s_review > div:last-child > div > div:last-child > span:first-child {
  margin: 0;
}

.s_active_agent {
  animation: agentMove 1s forwards;
}

.s_unactive_agent {
  animation: agentUnmove 1s forwards;
}

.w_100 .s_slide_agent {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  margin-top: 15%;
  background-color: #C9C1B6;
}
.w_100 .s_slide_agent .s_card_agent {
  position: relative;
  transform: translateY(-150px);
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.w_100 .s_slide_agent .s_card_agent > h2 {
  padding: 10px 0 0 0;
  font-size: 28px;
  color: #325a66;
  font-family: "Optimal";
}
.w_100 .s_slide_agent .s_card_agent > p {
  font-size: 12px;
  font-family: "Gotham Book";
  color: black;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.w_100 .s_slide_agent .s_card_agent > div:first-child {
  position: relative;
}
.w_100 .s_slide_agent .s_card_agent > div:first-child > div {
  position: absolute;
  top: 0;
  border-radius: 10px;
  opacity: 0;
  height: 100%;
  width: 100%;
  display: flex;
  padding: 20px;
  z-index: 10;
  background: #325a66de;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.w_100 .s_slide_agent .s_card_agent > div:first-child > div > h4 {
  font-family: "Gotham Book";
  font-weight: normal;
  font-size: 26px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 10%;
}
.w_100 .s_slide_agent .s_card_agent > div:first-child > div > p {
  font-family: "Gotham Book";
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
}
.w_100 .s_slide_agent .s_card_agent > div:last-child {
  position: relative;
  width: 40%;
  padding: 20px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  height: auto;
}

.s_noresult {
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.s_noresult h4 {
  font-size: 20px;
  font-family: "Gotham Bold";
  color: #325a66;
}

.s_partner {
  width: 100%;
  height: 200px;
  background: #325a66;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  align-content: center;
}
.s_partner > div {
  height: 100%;
}
.s_partner > div img {
  width: 150px;
}
.s_partner .s_card_partner {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: relative;
  filter: brightness(0) invert(1);
  opacity: 0.7;
  transition: all 1s;
}
.s_partner .s_card_partner:hover {
  opacity: 1;
}

.s_content_sell {
  position: relative;
  width: 100%;
  height: auto;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
}
.s_content_sell > h2 {
  position: relative;
  font-size: 28px;
  font-family: "Optimal";
  color: #325a66;
  line-height: 42px;
}
.s_content_sell > div {
  position: relative;
  width: 100%;
  padding-top: 5%;
  display: flex;
  height: 350px;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
}
.s_content_sell > div > div {
  position: relative;
  width: 25%;
  margin-right: 5%;
  padding: 20px;
  height: 100%;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  align-content: center;
  overflow: hidden;
  transition: all 1s;
}
.s_content_sell > div > div:hover > img,
.s_content_sell > div > div:hover > h4,
.s_content_sell > div > div:hover > p {
  opacity: 0;
}
.s_content_sell > div > div:hover > div {
  opacity: 1;
}
.s_content_sell > div > div:last-child {
  margin-right: 0;
}
.s_content_sell > div > div > img {
  width: 64px;
  margin-bottom: 10px;
}
.s_content_sell > div > div > h4 {
  position: relative;
  color: #325a66;
  font-size: 20px;
  text-align: center;
  font-family: "Optimal";
}
.s_content_sell > div > div > p {
  font-size: 12px;
  margin: 10px 0;
  text-align: justify;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
.s_content_sell > div > div > div {
  opacity: 0;
  position: absolute;
  top: 0;
  padding: 20px;
  overflow: auto;
  z-index: 100;
  background-color: #325a66;
  height: 100%;
  transition: all 1s;
}
.s_content_sell > div > div > div > p {
  font-size: 15px;
  text-align: center;
  line-height: 18px;
  letter-spacing: 0.5px;
  overflow: auto;
  color: white;
}

.s_control_filter {
  width: 100%;
  position: relative;
  display: flex;
  flex: row;
  align-items: center;
  justify-content: space-between;
  padding: 50px 5% 40px 5%;
  background-color: white;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_control_filter {
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0 10px 40px 10px;
  }
}

.s_order_by {
  position: relative;
  padding: 20px 60px;
  border: #325a66 1px solid;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_order_by {
    display: block;
    border: none;
    position: absolute;
    right: 0;
    width: 100%;
  }
}
.s_order_by:hover {
  background-color: #325a66;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_order_by:hover {
    background-color: transparent;
  }
}
.s_order_by:hover > p {
  color: white;
}
.s_order_by > p {
  font-family: "Gotham Bold";
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #325a66;
  text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_order_by > p {
    display: none;
  }
}
.s_order_by > div {
  display: none;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: absolute;
  width: 100%;
  right: 0;
  bottom: -148px;
  z-index: 100;
  overflow: hidden;
  transition: all 0.5s;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_order_by > div {
    display: block;
    right: -100%;
    width: 200px;
    bottom: -150px;
  }
}
.s_order_by > div > p {
  position: relative;
  width: 100%;
  padding: 10px 20px;
  font-family: "Gotham Bold";
  font-size: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
  border-bottom: 1px solid #ececea;
  white-space: nowrap;
  color: #325a66;
  transition: all 0.5s;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_order_by > div > p {
    font-size: 11px;
  }
}
.s_order_by > div > p:hover {
  background-color: #ececea;
}
.s_order_by > div > p:last-child {
  border: none;
}

.s_text_filter {
  position: relative;
  display: flex;
  background-color: white;
  flex-direction: column;
  width: 50%;
  height: auto;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 449px) {
  .s_text_filter {
    padding: 0 !important;
    width: 70%;
  }
}
.s_text_filter > span {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 50%;
  height: auto;
}
.s_text_filter > span:first-child {
  font-family: "Gotham Bold";
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: #325a66;
}
@media (max-width: 449px) {
  .s_text_filter > span:first-child {
    font-size: 14px;
  }
}
.s_text_filter > span:last-child {
  font-family: "Gotham Book";
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: #325a66;
}
@media (max-width: 449px) {
  .s_text_filter > span:last-child {
    font-size: 12px;
  }
}
.s_text_filter > span > b {
  margin-right: 10px;
}
.s_text_filter > div {
  display: flex;
  cursor: pointer;
  width: auto;
  height: 50px;
  border: 1px solid #eee;
  border-radius: 0.3rem;
  background: white;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
}
.s_text_filter > div > i {
  margin-right: 20px;
}
.s_text_filter > div > span {
  margin-right: 20px;
  font-size: 10px;
}

#ModelMap {
  width: 100%;
  height: 50vh;
  border-radius: 10px;
}

.s_appartement {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0 5% 5% 5%;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.s_thumbnails {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 5%;
  display: flex;
  background-color: white;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  transition: all 1s;
}
.s_thumbnails > div {
  position: relative;
  padding-top: 100px;
  width: 100%;
}
.s_thumbnails > div > h2 {
  font-family: "Gotham Book";
  font-size: 24px;
  text-transform: uppercase;
  color: #2a2a2a;
}
.s_thumbnails > div > div {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: white;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 40px 0;
  align-items: center;
  justify-content: space-between;
  transition: all 1s;
}
.s_thumbnails > div > div > div {
  position: relative;
  width: 32.5%;
  height: 500px;
  overflow: hidden;
  border-radius: 5px;
  margin-top: 5px;
  transition: all 1s;
}
@media (min-width: 1980px) {
  .s_thumbnails > div > div > div {
    height: 600px;
  }
}
@media (min-width: 1000px) and (max-width: 1439px) {
  .s_thumbnails > div > div > div {
    width: 49.5%;
    height: 600px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .s_thumbnails > div > div > div {
    width: 49.5%;
  }
}
@media (min-width: 451px) and (max-width: 767px) {
  .s_thumbnails > div > div > div {
    width: 100%;
  }
}
@media (max-width: 449px) {
  .s_thumbnails > div > div > div {
    width: 100%;
  }
}
.s_thumbnails > div > div > div:hover > div {
  height: 100%;
}
.s_thumbnails > div > div > div:hover > div > div {
  padding: 40px;
}
.s_thumbnails > div > div > div:hover > div > div > div:first-child p:nth-child(3) {
  opacity: 1;
}
.s_thumbnails > div > div > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.s_thumbnails > div > div > div > div {
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 20%;
  z-index: 3;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
  transition: all 1s;
}
.s_thumbnails > div > div > div > div > div {
  width: 100%;
  z-index: 5;
  padding: 20px;
  display: flex;
  height: 100%;
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0.7) 70%, rgba(0, 0, 0, 0) 100%);
  flex-direction: column;
  justify-content: space-between;
  transition: all 1s;
}
.s_thumbnails > div > div > div > div > div > div {
  position: relative;
  width: 100%;
  height: auto;
}
.s_thumbnails > div > div > div > div > div > div:first-child p:nth-child(4) {
  opacity: 0;
  transition: all 1s;
}
.s_thumbnails > div > div > div > div > div > div:first-child p:nth-child(3) {
  margin-bottom: 30px;
  opacity: 0;
  transition: all 1s;
}
.s_thumbnails > div > div > div > div > div > div:last-child p {
  opacity: 0.7;
  font-style: italic;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
@media (min-width: 1980px) {
  .s_thumbnails > div > div > div > div > div > div:last-child p {
    font-size: 16px;
  }
}
.s_thumbnails > div > div > div > div > div > div:last-child > b {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 1980px) {
  .s_thumbnails > div > div > div > div > div > div .fs_08 {
    font-size: 14px;
  }
}
.s_thumbnails > div > div > div > div > div > div > h4 {
  position: relative;
  color: #ececea;
  font-size: 20px;
  font-family: "Optimal Bold";
}
@media (min-width: 1980px) {
  .s_thumbnails > div > div > div > div > div > div > h4 {
    font-size: 26px;
  }
}
.s_thumbnails > div > div > div > div > div > div > span {
  position: relative;
  margin-bottom: 10px;
  font-family: "Gotham Book";
  font-size: 12.9px;
  line-height: 20px;
  text-align: justify;
  color: #ececea;
  margin-top: 30px;
}
@media (min-width: 1980px) {
  .s_thumbnails > div > div > div > div > div > div > span {
    font-size: 20px;
    line-height: 30px;
  }
}
.s_thumbnails > div > div > div > div > div > div > p {
  position: relative;
  padding: 10px 0 5px 0;
  font-family: "Gotham Book";
  font-size: 14px;
  color: #ececea;
}
@media (min-width: 1980px) {
  .s_thumbnails > div > div > div > div > div > div > p {
    font-size: 18px;
  }
}
.s_thumbnails > div > div > div > div > img {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  margin: 10px 10px 0 0;
  width: 32px;
}

/** @format */
.s_estimation_widget {
  position: relative;
  height: 100%;
  padding: 5%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.s_estimation_widget > div:first-child {
  position: relative;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.s_estimation_widget > div:first-child > h2 {
  position: relative;
  font-size: 35px;
  white-space: nowrap;
  font-family: "Optimal";
  color: #325a66;
  text-transform: uppercase;
}
.s_estimation_widget > div:first-child p {
  position: relative;
  font-size: 12px;
  font-family: "Gotham Book";
  color: #325a66;
}
.s_estimation_widget > div:first-child > div {
  position: relative;
  padding-top: 1.25em;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.s_estimation_widget > div:first-child > div > input {
  position: relative;
  width: 23rem;
  height: 48px;
  padding: 10px 10px 10px 40px;
  border: 1px solid #eee;
}
.s_estimation_widget > div:first-child > div > input::placeholder {
  opacity: 0.5;
}
.s_estimation_widget > div:first-child > div > img {
  position: absolute;
  width: 20px;
  left: 10px;
  height: 48px;
  z-index: 10;
}
.s_estimation_widget > div:last-child {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0 0 0 5%;
}
.s_estimation_widget > div:last-child > div:first-child {
  position: relative;
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.s_estimation_widget > div:last-child > div:first-child > p {
  font-size: 10px;
  font-family: "Gotham Bold";
  font-weight: bold;
  position: relative;
  color: #ececea;
}
.s_estimation_widget > div:last-child > div:first-child > p > b {
  color: #325a66;
}
.s_estimation_widget > div:last-child > div:first-child > img {
  width: 2rem;
  margin: 0 0 0 5px;
}
.s_estimation_widget > div:last-child > div:last-child {
  padding: 5px 0 0 0;
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
}
.s_estimation_widget > div:last-child > div:last-child > span {
  background-color: #325a66;
  border-radius: 5px;
  padding: 0.5rem;
  margin-left: 5px;
}
.s_estimation_widget > div:last-child > div:last-child > span:first-child {
  margin: 0;
}

#budget {
  margin-top: 10px;
}

h4.mobile {
  font-family: "Gotham Bold";
  position: relative;
  margin: 0 10px !important;
  color: #325a66;
  font-size: 14px;
}

.mobile {
  display: none;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .mobile {
    display: flex !important;
  }
}
@media (min-width: 201px) and (max-width: 1149px) {
  .mobile span {
    width: 46px !important;
    height: 46px;
    padding: 10px;
    margin-right: 10px;
  }
  .mobile span:last-child {
    margin-right: 0;
  }
  .mobile span:hover {
    background-color: transparent !important;
  }
}
@media (min-width: 201px) and (max-width: 1149px) {
  .mobile span img {
    width: 46px;
  }
}

.s_filter {
  position: relative;
  z-index: 10;
  background: #325a66;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 40px;
  margin: 40px 0 0 0;
  width: 100%;
  transition: all 0.5s;
}
.s_filter .close_filter {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 56px;
  cursor: pointer;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_filter {
    width: 100%;
    overflow: scroll;
    position: fixed;
    top: 100%;
    display: flex;
    padding-top: 40px;
    height: 100%;
    background: #c9c1b6;
    flex-direction: column;
    justify-content: flex-start;
  }
}
.s_filter > div {
  position: relative;
  width: 19%;
  height: 100%;
  padding: 40px 0 40px 0;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_filter > div {
    width: 100%;
    padding: 0 0 20px 0;
    margin-top: 0;
    height: auto;
  }
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_filter > div span {
    display: none;
  }
}
.s_filter > div > div {
  opacity: 0;
  display: none;
  position: absolute;
  margin-top: 20px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: calc(100% + 150px);
  transition: all 0.5s;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_filter > div > div {
    position: relative;
    opacity: 1;
    display: block;
    background-color: transparent;
    padding: 0;
    width: 100%;
    box-shadow: none;
    margin-top: 10px;
  }
}
.s_filter > div > div .result > b {
  width: 47%;
  font-size: 8px;
  line-height: 10px;
  padding: 10px;
  text-transform: uppercase;
  background: #325a66;
  color: white;
  border-radius: 5px;
  margin-bottom: 1%;
  justify-content: space-between;
  align-items: center;
}
.s_filter > div > div .result > b:last-child {
  margin-right: 0;
}
.s_filter > div > div .result > b > img {
  width: 16px;
  height: 16px;
  z-index: 5;
  cursor: pointer;
}
.s_filter > div > div h4 {
  position: relative;
  color: #325a66;
  font-family: "Optimal Bold";
  font-size: 18px;
  white-space: nowrap;
}
.s_filter > div > div > div {
  position: relative;
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_filter > div > div > div {
    margin: 10px 0 0 0;
    align-items: center;
    justify-content: flex-start;
  }
}
.s_filter > div > div > div.pieces b, .s_filter > div > div > div.ascenceur b {
  position: relative;
  display: flex;
  width: 33%;
  justify-content: center;
  align-items: center;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_filter > div > div > div.pieces b, .s_filter > div > div > div.ascenceur b {
    width: 13%;
  }
  .s_filter > div > div > div.pieces b:first-child, .s_filter > div > div > div.ascenceur b:first-child {
    width: 20%;
  }
}
.s_filter > div > div > div.pieces b input:checked, .s_filter > div > div > div.ascenceur b input:checked {
  z-index: 0;
}
.s_filter > div > div > div.pieces input, .s_filter > div > div > div.ascenceur input {
  width: 62px !important;
  height: 32px !important;
  cursor: pointer;
}
.s_filter > div > div > div.pieces p, .s_filter > div > div > div.ascenceur p {
  position: absolute;
}
.s_filter > div > div > div > img {
  position: absolute;
  z-index: 10;
  left: 10px;
  top: 10px;
}
.s_filter > div > div > div > div {
  position: relative;
  width: 47%;
  margin-right: 10px;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_filter > div > div > div > div {
    width: 49.5%;
    margin-right: 3px;
  }
}
.s_filter > div > div > div > div:last-child {
  margin-right: 0;
}
.s_filter > div > div > div > div > p {
  font-family: "Gotham Book";
  position: relative;
  margin: 5px 0;
  font-size: 12px;
  text-transform: uppercase;
}
.s_filter > div > div > div input[type=text],
.s_filter > div > div > div input[type=number] {
  position: relative;
  text-decoration: none;
  border: 1px solid #325a66;
  border-radius: 0;
  appearance: none;
  width: 100%;
  padding: 10px 10px 10px 40px;
  transition: all 0.5s;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_filter > div > div > div input[type=text],
.s_filter > div > div > div input[type=number] {
    background-color: transparent;
  }
}
.s_filter > div > div > div input[type=text]::placeholder,
.s_filter > div > div > div input[type=number]::placeholder {
  font-family: "Gotham Book";
  color: #325a66;
  font-size: 12px;
  opacity: 0.7;
}
.s_filter > div > div > div input[type=number] {
  padding: 10px !important;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_filter > div > div > div input[type=number] {
    background-color: transparent;
  }
}
.s_filter > div > div > div input[type=number]::before {
  position: absolute;
  content: "";
  right: 20px;
  width: 16px;
  height: 16px;
  background-color: red;
}
.s_filter > div > div > div > b {
  display: flex;
  width: 50%;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.s_filter > div > div > div > b input,
.s_filter > div > div > div > b p {
  margin-right: 10px;
}
.s_filter > div > div > div > b input[type=checkbox] {
  position: relative;
  cursor: pointer;
  z-index: 3;
  text-decoration: none;
  border: 1px solid #325a66;
  border-radius: 0;
  appearance: none;
  width: 12px;
  height: 12px;
  transition: all 0.5s;
}
.s_filter > div > div > div > b input[type=checkbox]:checked {
  background-color: #325a66;
  border: 1px solid #325a66;
}
.s_filter > div > div > div > b p {
  font-family: "Gotham Book";
  font-size: 12px;
  color: #325a66;
}

.left-back {
  background: linear-gradient(90deg, #325a66 -5.97%, rgba(50, 90, 102, 0.5) 65%, rgba(217, 217, 217, 0) 100%);
}

.right-back {
  background: linear-gradient(270deg, #325a66 0%, rgba(50, 90, 102, 0.5) 65%, rgba(217, 217, 217, 0) 100%);
}

.s_control_swiper_appartement {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.moveTeamBtn {
  position: absolute;
  height: 50%;
  width: 100%;
  border-radius: 0;
  font-size: 12px;
}
.moveTeamBtn:nth-child(2n+2) {
  bottom: 0;
}
.moveTeamBtn img {
  height: 15px;
}

.teamRow {
  position: relative;
  padding: 0;
}

/** @format */
tbody tr:nth-child(even) {
  background-color: #c9c0b652;
}
tbody tr:nth-child(odd) {
  background-color: white;
}

.hight {
  text-transform: uppercase;
}

.pieces {
  position: relative;
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  width: 100% !important;
  gap: 40px;
}
.pieces .piece {
  position: relative;
  flex-direction: column !important;
  width: 300px !important;
  height: 200px;
  border-radius: 10px;
  padding: 40px 20px;
  background-color: #c9c1b6;
}
.pieces .piece .type {
  font-size: 21px;
  text-transform: uppercase;
}
.pieces .piece .label {
  font-size: 12px;
}

.s_main_section_panel {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 10;
}

.s_top_header {
  position: relative;
  left: 0;
  height: 50px;
  display: flex;
  z-index: 10;
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  padding: 0 5%;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;
}
.s_top_header > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  flex-direction: row;
  height: 50%;
}
.s_top_header > div > h3 {
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  color: #0a0f25;
  text-transform: uppercase;
}
.s_top_header > div > h3 > b {
  color: #325a66;
}
.s_top_header > div > h4 {
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0;
}
.s_top_header > div > span {
  position: relative;
  font-size: 10px;
  text-transform: uppercase;
  color: #959cb6;
}

.s_cache_panel {
  position: fixed;
  z-index: 10;
  display: flex;
  backdrop-filter: blur(5px);
  width: 100%;
  padding: 20% 15% 10% 15%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.s_cache_panel h4 {
  font-size: 18px;
  color: #0a0f25;
  text-transform: uppercase;
  text-align: center;
}
.s_cache_panel svg {
  width: 100px;
  height: auto;
}
.s_cache_panel svg path {
  fill: #325a66;
}

.s_appartement_panel {
  position: relative;
  flex-direction: row !important;
  overflow: hidden;
  padding: 1.25em;
}
.s_appartement_panel > div {
  position: relative;
  width: 33.33%;
  height: 100%;
  padding: 0 5px;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
}
.s_appartement_panel > div > b {
  font-family: "Gotham Book";
  font-size: 12px;
  font-weight: normal;
  margin: auto 0;
  padding-top: 20px;
  line-height: 15px;
  height: auto;
}
.s_appartement_panel > div > form {
  margin-top: auto;
}
.s_appartement_panel > div > div {
  width: 100%;
  display: flex;
  overflow: hidden;
  height: 100%;
  background-color: whitesmoke;
  border-radius: 0.357rem;
  border: 1px solid #eee;
  flex-direction: column;
  justify-content: space-between;
}
.s_appartement_panel > div > div > div:last-child {
  height: 30%;
}
.s_appartement_panel > div > div > div:last-child > p {
  font-size: 10px;
  text-transform: uppercase;
  position: relative;
  height: 100%;
  color: #325a66;
  display: flex;
  justify-content: center;
  align-items: center;
}
.s_appartement_panel > div > div > div:last-child > p > a {
  color: #325a66;
  text-align: center;
  padding: 10px;
  font-weight: bold;
}
.s_appartement_panel > div > div > div:first-child {
  width: 100%;
  height: 70%;
  overflow: hidden;
}
.s_appartement_panel > div > div > div:first-child > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.s_appartement_panel > div > span {
  white-space: nowrap;
  border-radius: 0.357rem;
  width: 40px;
  height: auto;
  margin-right: 1.25em;
  position: relative;
  padding: 10px;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  font-weight: 600;
  color: #325a66;
  background: #ececea;
}
.s_appartement_panel > div > p {
  font-family: "Gotham Bold";
  margin: 10px 0;
  position: relative;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
}
.s_appartement_panel > div > h6 {
  font-size: 1.25em;
  color: #325a66;
}

.s_board_screen {
  height: 100vh;
  z-index: 10;
}

.s_bord_separator {
  position: relative;
  display: block;
  width: 1px;
  height: 4px;
  border-radius: 2px;
  background: #e7e8ef;
  margin: 0 10px;
  height: 50%;
}

.s_color_board {
  background: #f2f3f8;
  z-index: 10;
}

.s_board {
  position: relative;
  padding: 5%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 10;
}
@media screen and (min-width: 200px) and (max-width: 1155px) {
  .s_board {
    padding: 150px 5% 10% 5%;
  }
}

.s_column_widget {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
  justify-content: space-between;
  z-index: 10;
}

.s_card {
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 20px;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(34, 41, 47, 0.125);
  border-radius: 0.428rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  justify-content: space-between;
  z-index: 10;
}
.s_card > h4,
.s_card > div > h4 {
  position: relative;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #0a0f25;
}
.s_card > h4 {
  text-decoration: none;
  color: #0a0f25;
  transition: all 1s;
}
.s_card > h4::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 1px;
  background-color: #325a66;
  bottom: 0;
  left: 0;
}
.s_card > div > h4 > a {
  text-decoration: none;
  color: #0a0f25;
  transition: all 1s;
}
.s_card > h4,
.s_card > div > h4 > a:hover {
  color: #325a66;
}

.s_card_flex {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  justify-content: flex-start;
  flex-direction: column;
  align-content: center;
}

.s_card > div:not(.s_notification) {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.s_card > div > a {
  border-radius: 0.358rem;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px;
  background: #325a66;
  text-align: center;
  color: whitesmoke;
  font-size: 12px;
  transition: all 1s;
  cursor: pointer;
}

#s_dowload,
.s_return_invoice {
  border-radius: 0.358rem;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px;
  background: #325a66;
  text-align: center;
  color: whitesmoke;
  font-size: 12px;
  transition: all 1s;
  cursor: pointer;
}

.s_card > div > a:hover,
#s_dowload:hover,
.s_return_invoice:hover {
  background-color: #0a0f25;
  color: whitesmoke;
}

.s_card_link {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.s_card_link > div:first-child {
  width: 30%;
  position: relative;
  justify-content: center;
  align-items: center;
}
.s_card_link > div:first-child > svg {
  height: 50px;
  width: 50px;
}
.s_card_link > div:first-child > svg g [fill] {
  fill: #325a66;
}
.s_card_link > div:last-child {
  width: 70%;
  position: relative;
  justify-content: center;
}

.s_card > p {
  position: relative;
  margin: 0;
  font-size: 12px;
  height: 100%;
  display: flex;
}

.s_maj_profil > div:first-child {
  position: relative;
  width: 100%;
  height: 40%;
  background-color: #ececea;
  border-radius: 0.428rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.s_maj_profil > div:first-child > svg,
.s_maj_profil > div:first-child > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.s_maj_profil > div:last-child {
  position: relative;
  width: 100%;
  height: 60%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.s_maj_profil > div:last-child > div:first-child {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
}
.s_maj_profil > div:last-child > div:last-child {
  margin-top: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
}
.s_maj_profil > div:last-child > div:last-child > div {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
}
.s_maj_profil > div:last-child > div:last-child > div > div:first-child {
  white-space: nowrap;
  border-radius: 0.357rem;
  margin-right: 20px;
  position: relative;
  padding: 7px;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  font-weight: 600;
  color: #325a66;
  background: #ececea;
}
.s_maj_profil > div:last-child > div:last-child > div > div:last-child {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.s_maj_profil > div:last-child > div:last-child > div > div:last-child > h6 {
  margin: 0;
  font-weight: bold;
  font-size: 12px;
  color: #325a66;
}
.s_maj_profil > div:last-child > div:last-child > div > div:last-child > h6 > a {
  text-decoration: none;
  color: #0a0f25;
  font-size: 12px;
  transition: 1s all;
}
.s_maj_profil > div:last-child > div:last-child > div > div:last-child > h6 > a:hover {
  color: #325a66;
}
.s_maj_profil > div:last-child > div:last-child > div > div:last-child > small {
  margin: 0;
  font-weight: normal;
  font-size: 8px;
  text-transform: uppercase;
  color: #325a66;
}
.s_maj_profil > div:last-child > div:first-child > div:first-child {
  text-align: center;
  border-right: 1px solid #d8d6de;
  width: 30%;
  display: flex;
  padding-right: 1.3rem;
  margin-right: 1.3rem;
  flex-direction: column;
  justify-content: space-between;
}
.s_maj_profil > div:last-child > div:first-child > div:first-child > h6 {
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}
.s_maj_profil > div:last-child > div:first-child > div:first-child > h3 {
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}
.s_maj_profil > div:last-child > div:first-child > div:last-child {
  text-align: left;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.s_maj_profil > div:last-child > div:first-child > div:last-child > h6 {
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}
.s_maj_profil > div:last-child > div:first-child > div:last-child > h3 {
  margin: 0;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 12px;
  opacity: 0.4;
}

/* REMISE DISPLAY */
.s_small_widget .s_row {
  flex-direction: row;
}

.s_stats_maj_profil {
  position: relative;
  display: inline-block;
  width: 90%;
  height: 100%;
  border-radius: 100%;
  margin-bottom: 18px;
  text-align: center;
  background: whitesmoke;
}

.s_percent {
  display: inline-block;
  font-size: 12px;
  line-height: 100%;
  z-index: 2;
  font-weight: 700;
  color: #666;
}

.s_card_flex > p {
  margin: 0;
  color: #0a0f25;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: normal;
}
.s_card_flex > p > i {
  font-size: 14px;
  margin-right: 10px;
  color: #325a66;
}
.s_card_flex > p > b {
  color: #325a66;
}

.s_card > div > p {
  position: relative;
  margin: 0;
  height: auto;
  font-size: 12px;
}
.s_card > .s_date {
  font-size: 10px;
  /* opacity: .7; */
  color: #999;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.s_small_widget {
  width: 30%;
  height: 220px;
  margin-right: 1%;
}

.s_semi_small_widget {
  width: 25%;
  height: 220px;
  margin-right: 1%;
}

.s_semi_medium_widget {
  width: 40%;
  height: 220px;
  margin-right: 1%;
}

.s_medium_widget {
  width: 50%;
  height: 220px;
  margin-right: 1%;
}

.s_medium_55_widget {
  width: 55%;
  height: 220px;
  margin-right: 1%;
}

.s_3_small_widget {
  width: 33%;
  height: 220px;
  margin-right: 1%;
}

.s_large_widget {
  width: 70%;
  height: 220px;
  margin-right: 1%;
}

.s_very_large_widget {
  width: 60%;
  height: 400px;
  margin-right: 1%;
}

.s_widget_no_margin {
  margin: 0 !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.s_widget_no_padding {
  padding: 0;
}

.s_auto {
  height: auto;
}

.s_300px {
  height: 300px;
}

.s_400px {
  height: 370px;
}

.s_600px {
  height: 600px;
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 5px;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.s_600px::-webkit-scrollbar {
  width: 3px;
}
.s_600px::-webkit-scrollbar-track {
  background: transparent;
}
.s_600px::-webkit-scrollbar-thumb {
  background: #325a66;
}
.s_600px::-webkit-scrollbar-thumb:hover {
  background: #325a66;
}

.s_full {
  width: 100% !important;
}

form .s_column {
  flex-direction: column !important;
  align-items: flex-start !important;
}

/* quilljs */
.ql-toolbar.ql-snow {
  border-radius: 5px;
  background: whitesmoke;
  width: 100%;
  border: #eee solid 0.2px !important;
  padding: 15px 20px 15px 20px !important;
  font-family: "Gotham Book" !important;
}
.ql-toolbar.ql-snow button:hover {
  color: #325a66 !important;
}

.ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #325a66 !important;
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #325a66 !important;
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #325a66;
}

.ql-toolbar.ql-snow .ql-picker-label {
  display: flex;
  align-items: center;
}

.ql-editor {
  height: 600px !important;
}

.ql-container.ql-snow {
  border-radius: 5px;
  margin: 10px 0;
  background: whitesmoke;
  width: 100%;
  border: #eee solid 0.2px !important;
  padding: 15px 20px 15px 20px !important;
}

.s_card > form > b > div > p {
  margin: 0;
  font-size: 11px;
  font-weight: normal;
  text-transform: uppercase;
}

.s_board > .s_column_widget > .s_large_widget > .s_card_stats {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.s_board > .s_column_widget > .s_large_widget > .s_card_stats > div {
  display: flex;
  width: 50%;
  justify-content: space-around;
  position: relative;
  flex-direction: row;
  align-items: center;
  align-content: center;
}
.s_board > .s_column_widget > .s_large_widget > .s_card_stats > div > div {
  width: 50%;
  height: auto;
  padding: 5px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.s_avatar,
.s_devis,
.s_euros {
  background: #ececea;
}

.s_avatar svg,
.s_devis svg,
.s_euros svg {
  color: #325a66;
}

.s_content_card {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}
.s_content_card > span {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  position: relative;
}
.s_content_card > p {
  position: relative;
  margin: 0;
  font-size: 12px;
}

.s_icon svg {
  width: 20px;
  height: 20px;
}

.s_chart {
  width: 100%;
  justify-content: space-around;
}

.s_circular-chart {
  display: block;
  margin: 0 auto;
  max-width: 80%;
  max-height: 80%;
}

.s_circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.s_circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.s_actus {
  position: relative;
  height: 100%;
  width: 100%;
}
.s_actus > div {
  display: flex;
  width: 100%;
  height: 33.3%;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  padding: 5px 0;
}
.s_actus > div > div {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
}
.s_actus > div > div > b {
  flex-direction: column;
  line-height: 15px;
}
.s_actus > div > div > b > p {
  margin: 0;
  font-size: 12px;
}
.s_actus > div > div > b > span {
  margin: 0;
  font-size: 10px;
  color: #999;
  font-weight: normal;
  text-transform: capitalize;
}
.s_actus > div > span {
  font-size: 10px;
  /* opacity: .7; */
  color: #999;
  letter-spacing: 2px;
  margin-bottom: 10px;
}
.s_actus > div > div > .s_icon {
  white-space: nowrap;
  background: rgba(40, 199, 111, 0.12) !important;
  margin-right: 20px;
  border-radius: 0.357rem;
  position: relative;
  cursor: pointer;
  color: #fff;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 1rem;
  width: 32px;
  height: 32px;
  text-align: center;
  vertical-align: middle;
  font-weight: 600;
}
.s_actus > div > div > .s_icon > svg {
  color: #28c76f;
}

@-webkit-keyframes show_dropdown {
  from {
    top: 60px;
  }
  to {
    top: 36px;
  }
}
.s_circular-chart.s_color .s_circle {
  stroke: #325a66;
}

.s_percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}

.s_revendeur_button:hover > ul {
  display: flex;
  /* animation: show_dropdown; */
  animation-duration: 0.2s;
}
.s_revendeur_button ul {
  display: none;
  width: 100%;
  position: absolute;
  z-index: 10;
  padding: 10px 20px;
  /* margin: 40px 0; */
  top: 36px;
  font-size: 1rem;
  transition: all 1s;
  color: #6e6b7b;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(34, 41, 47, 0.05);
  border-radius: 0.357rem;
  flex-direction: column;
}
.s_revendeur_button ul li {
  font-size: 10px;
  margin-bottom: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid whitesmoke;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #6e6b7b;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
}
.s_revendeur_button ul li a {
  display: block;
  width: 100%;
  padding: 0.65rem 1.28rem;
  clear: both;
  text-decoration: none;
  color: #0a0f25;
  opacity: 0.7;
  font-weight: bold;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  transition: all 1s;
}
.s_revendeur_button ul li a:hover {
  color: #325a66;
  opacity: 1;
}

.s_profil div:last-child {
  background: none;
}

.table {
  border-radius: 0.357rem;
  width: 100%;
  display: table;
  color: #6e6b7b;
  border-color: #ebe9f1;
  vertical-align: middle;
  caption-side: bottom;
  border-collapse: collapse;
  text-indent: initial;
  border-spacing: 2px;
  border-bottom-right-radius: 0.357rem;
  border-bottom-left-radius: 0.357rem;
  margin-bottom: 0;
}
.table > thead {
  vertical-align: bottom;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-bottom: 1px solid whitesmoke;
}

table th {
  background-color: white;
  vertical-align: top;
  text-align: left;
  border-right: 1px solid whitesmoke;
  text-transform: uppercase;
  font-size: 10px;
  width: auto;
  letter-spacing: 0.5px;
  padding: 5px 10px;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px transparent;
}

.table > tbody {
  vertical-align: inherit;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
.table > tbody .image {
  width: 200px;
  height: 100px;
  object-fit: cover;
}

td {
  padding: 20px 10px;
  text-align: left;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px transparent;
}
td > span {
  font-size: 12px;
  text-transform: uppercase;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
td > span i {
  font-size: 16px;
}

.s_color {
  color: #325a66;
  font-weight: bold;
}

.s_color_link {
  color: #7367f0;
}

.s_display_none {
  display: none;
}

.s_color_red {
  color: #ac1414;
}

.s_board .s_column_widget .s_card .s_head_invoice {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #eee;
}

.s_head_invoice div:first-child {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.s_head_invoice div:last-child {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.s_head_invoice div:last-child > h4 {
  margin: 0;
  text-transform: uppercase;
  font-size: 14px;
  position: relative;
}
.s_head_invoice div:last-child > span {
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
}
.s_head_invoice div:first-child > img {
  width: 64px;
  height: auto;
  position: relative;
}

.s_table_invoice {
  width: 100%;
  height: auto;
  padding: 20px 0;
}
.s_table_invoice tbody h3 {
  margin: 0;
  display: flex;
  flex-direction: column;
  font-size: 8px;
  font-weight: bold;
  color: #325a66;
  text-transform: uppercase;
}
.s_table_invoice tbody h3 span {
  font-size: 12px;
  font-weight: normal;
  color: #0a0f25;
}

.s_content .s_board .s_total_invoice {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.s_table_invoice th,
.s_table_invoice td {
  text-align: left;
}

.s_invoice_control {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  height: auto;
  justify-content: space-between;
}

.s_card form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  background: white;
}
.s_card form h2 {
  text-align: left;
  font-size: 18px;
  text-transform: uppercase;
  color: #325a66;
  font-weight: bold;
  margin: 20px 0;
}
.s_card form > div {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.s_card > form > div {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.s_card form > b > div,
.s_card > form > b > div {
  width: 49%;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote {
  font-weight: normal !important;
}

.s_card form > b,
.s_card > form > b {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.s_card form > div > a,
.s_card > form > div > a {
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 1px;
  padding-bottom: 5px;
  color: #0a0f25;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.s_card form > div > a > i,
.s_card > form > button > i {
  margin-right: 10px;
}

form > div > a:hover,
.s_card > form > div > a:hover {
  color: #325a66;
}

form > label,
form > b > div > label {
  float: left;
  position: relative;
  width: 100%;
  text-align: left;
  font-weight: 500;
}

.s_card > form > b > div > label {
  float: left;
  position: relative;
  width: 100%;
  text-align: left;
  font-weight: 500;
}

form > label > i,
form > b > div > label > i {
  padding-right: 12px;
  font-size: 16px;
  color: #325a66;
  position: absolute;
  font-weight: bold;
  top: 14px;
  left: 20px;
  text-align: left;
}

.s_card > form > b > div > label > i {
  padding-right: 12px;
  font-size: 16px;
  color: #325a66;
  position: absolute;
  font-weight: bold;
  top: 14px;
  left: 20px;
  text-align: left;
}

form > p,
.s_card > form > p {
  position: relative;
  margin: 5px 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

.s_card form textarea {
  height: 150px;
  float: left;
  border-radius: 5px;
  background: whitesmoke;
  width: 100%;
  border: #eee solid 0.2px;
  padding: 15px 20px 15px 55px;
  color: white;
  font-size: 12px;
  margin-bottom: 10px;
  color: #0a0f25;
}
.s_card form > input,
.s_card form > b > div > input {
  float: left;
  border-radius: 5px;
  background: whitesmoke;
  width: 100%;
  border: #eee solid 0.2px;
  padding: 15px 20px 15px 55px;
  color: white;
  font-size: 12px;
  margin-bottom: 10px;
  color: #0a0f25;
}

.s_card > form > b > div > input {
  float: left;
  border-radius: 5px;
  background: whitesmoke;
  width: 100%;
  border: #eee solid 0.2px;
  padding: 15px 20px 15px 55px;
  color: white;
  font-size: 12px;
  margin-bottom: 10px;
  color: #0a0f25;
}

.s_card form > input,
.s_card form > b > div > input {
  float: left;
  border-radius: 5px;
  background: whitesmoke;
  width: 100%;
  border: #eee solid 0.2px;
  padding: 15px 20px 15px 55px;
  color: white;
  font-size: 12px;
  margin-bottom: 10px;
  color: #0a0f25;
}

.s_card > form > b > div > select,
.s_card input {
  float: left;
  border-radius: 5px;
  background: whitesmoke;
  width: 100%;
  border: #eee solid 0.2px;
  padding: 15px 20px 15px 55px;
  color: white;
  font-size: 12px;
  margin-bottom: 10px;
  color: #0a0f25;
}

.s_card form select {
  float: left;
  border-radius: 5px;
  background: whitesmoke;
  width: 100%;
  border: #eee solid 0.2px;
  padding: 15px 20px 15px 55px;
  color: white;
  font-size: 12px;
  margin-bottom: 10px;
  color: #0a0f25;
}
.s_card form > b > div > input {
  text-transform: none;
}

.s_card > form > b > div > select {
  text-transform: none;
}

.s_card form select {
  text-transform: none;
}
.s_card form > input[type=text]:focus {
  color: #0a0f25;
  text-transform: none;
}
.s_card form > b > div > input[type=text] {
  color: #0a0f25;
  text-transform: none;
}
.s_card form > b > div > input[type=text]:focus {
  color: #0a0f25;
  text-transform: none;
}

/* MEDIA SCREEN TABLETTE */
@media screen and (min-width: 800px) and (max-width: 1200px) {
  .s_card_stats {
    flex-direction: column;
  }

  .s_board > .s_column_widget > .s_large_widget > .s_card_stats > div,
.s_medium_widget,
.s_3_small_widget {
    width: 100%;
  }

  .s_board > .s_column_widget > .s_large_widget > .s_card_stats > div > div {
    justify-content: flex-start;
  }
}
/* MEDIA SCREEN MOBILE */
@media screen and (min-width: 100px) and (max-width: 845px) {
  .s_top_header {
    top: 70px;
  }
  .s_top_header > div > h3 {
    display: none;
  }

  .s_appartement_panel {
    flex-direction: column !important;
  }

  .s_board {
    padding: 30% 5% 10% 5%;
  }

  .s_card_stats,
.s_column_widget,
.s_column_row {
    flex-direction: column !important;
  }

  .s_large_widget,
.s_small_widget,
.s_medium_widget,
.s_semi_medium_widget,
.s_3_small_widget {
    margin-bottom: 1%;
  }

  .table,
.s_history_simulateur {
    margin-top: 1%;
  }

  .s_column_widget {
    margin: 0 0 1% 0;
  }

  .s_board_screen,
.s_300px {
    height: auto;
  }

  .s_appartement_panel > div > div > div:last-child > p {
    height: 40px !important;
  }

  .s_appartement_panel > div {
    padding: 20px 0 !important;
  }

  .s_appartement_panel > div,
.s_simulateur,
.s_history_simulateur,
.s_board > .s_column_widget > .s_large_widget > .s_card_stats > div,
.s_3_small_widget,
.s_semi_medium_widget,
.s_small_widget,
.s_large_widget,
.s_medium_widget {
    width: 100% !important;
  }

  .s_board > .s_column_widget > .s_large_widget > .s_card_stats > div > div {
    justify-content: flex-start;
  }

  .s_simulateur > div > img {
    width: 35%;
    height: 150px;
    padding: 0 10px 0 0;
  }

  .s_simulateur_form p {
    display: flex;
    flex-direction: column-reverse;
    white-space: nowrap;
    overflow: hidden;
  }

  .s_revendeur_button ul {
    position: relative;
    top: 0;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0;
    clear: both;
    color: whitesmoke;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
  }
  .s_revendeur_button ul li {
    flex-direction: row;
    border: none;
    margin-bottom: 0;
    color: white;
  }
  .s_revendeur_button ul li a {
    color: whitesmoke;
    opacity: 0.7;
    font-size: 12px;
    padding: 7px 15px;
  }
}
.s_verification_adresse {
  position: fixed;
  width: 100%;
  display: none;
  padding: 10%;
  height: 100%;
  background: whitesmoke;
  z-index: 100;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.s_verification_adresse img {
  width: 64px;
  height: auto;
  margin-bottom: 20px;
}
.s_verification_adresse p {
  font-size: 16px;
  font-family: "Gotham Book";
  line-height: 30px;
  letter-spacing: 1px;
  color: #0a0f25;
}
.s_verification_adresse b {
  font-size: 16px;
  font-family: "Gotham Bold";
  line-height: 30px;
  letter-spacing: 1px;
  color: #325a66;
}
.s_verification_adresse > i {
  font-size: 30px;
  width: 100%;
  display: flex;
  margin-bottom: 70px;
  position: relative;
  justify-content: flex-start;
  cursor: pointer;
  transition: 1s all;
}
.s_verification_adresse > i:hover {
  color: #325a66;
}
.s_verification_adresse > div {
  font-size: 20px;
  display: flex;
  text-transform: uppercase;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}
.s_verification_adresse > div i {
  margin-right: 10px;
  color: #b70d0d;
  font-weight: bold;
}
.s_verification_adresse > ul {
  padding: 20px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.s_verification_adresse > ul > li {
  margin-bottom: 10px;
  margin-right: 10px;
  display: flex;
  border-radius: 5px;
  height: 50px;
  background-color: #0a0f25;
  text-align: center;
  align-items: left;
  justify-content: center;
}
.s_verification_adresse > ul > li > a {
  position: relative;
  padding: 10px;
  color: whitesmoke;
  cursor: pointer;
  transition: all 1s;
}

.s_bandeau_child {
  position: relative;
  width: 100%;
  height: 200px;
}
.s_bandeau_child img {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.s_bandeau_child h1 {
  width: 100%;
  height: 100%;
  font-size: 26px;
  letter-spacing: 5px;
  display: flex;
  position: absolute;
  z-index: 10;
  color: white;
  align-items: center;
  justify-content: center;
}
.s_bandeau_child h1:before {
  position: absolute;
  content: "";
  bottom: 70px;
  width: 50px;
  height: 1px;
  background: white;
}

.s_step_7 a {
  background: #C9C1B6;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-weight: 100;
  font-size: 12px;
  width: 10%;
  text-align: center;
  position: relative;
  left: 0;
  border: none;
  font-family: Montserrat;
  cursor: pointer;
}

.s_bottom {
  bottom: 10% !important;
}

.s_step_sell {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.s_step_sell > div > div > div {
  position: relative;
  width: 100%;
  z-index: 10;
  overflow: hidden;
  margin: 60px 10px 0 0;
  padding: 20px;
  height: 400px;
  border-radius: 5px;
  background: white;
  border: 1px #eee solid;
}
.s_step_sell h3 {
  color: #0a0f25;
  position: relative;
  font-size: 18px;
  line-height: 40px;
  opacity: 1;
  font-family: Montserrat;
  padding-bottom: 40px;
}
.s_step_sell h3::before {
  position: absolute;
  content: "";
  bottom: 20px;
  background: #C9C1B6;
  left: 0;
  width: 20%;
  height: 2px;
}
.s_step_sell span {
  color: #0a0f25;
  font-size: 12px;
  opacity: 0.7;
  font-family: Montserrat;
}
.s_step_sell i {
  font-size: 20px;
  padding-bottom: 20px;
}
.s_step_sell b {
  position: absolute;
  display: flex;
  left: 0;
  font-size: 60px;
  padding: 0 20px;
  width: 100%;
  background: white;
  bottom: 0%;
  color: #C9C1B6;
  opacity: 1;
  font-family: Montserrat;
  justify-content: space-between;
  align-items: flex-end;
}

.s_expert {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 0 10% 0 5%;
}
.s_expert > div:first-child {
  position: relative;
  width: 15%;
  height: auto;
  overflow: hidden;
}
.s_expert > div:first-child img {
  position: relative;
  border-radius: 50%;
  width: 60%;
  height: auto;
}
.s_expert > div:last-child {
  position: relative;
  width: 90%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
}
.s_expert > div:last-child span {
  font-family: "Gotham Bold";
  font-size: 16px;
  line-height: 22.5px;
  color: #0a0f25;
}
.s_expert > div:last-child p {
  font-family: "Gotham Book";
  font-size: 13px;
  line-height: 22.5px;
  color: #989cad;
}

.s_select_option > div {
  position: relative;
  width: 100%;
  height: auto;
  padding: 40px 10% 40px 5%;
  justify-content: space-between;
}
.s_select_option > div > div {
  margin: 20px 0;
}

.s_button_widget {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-around;
}

.s_step_1 > div {
  position: relative;
  padding: 20px;
  cursor: pointer;
  width: 40%;
  height: 40%;
  display: flex;
  border-radius: 20px;
  background-color: white;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid #eee;
}
.s_step_1 > div > div:first-child {
  position: relative;
  display: flex;
  justify-content: center;
  height: 70%;
}
.s_step_1 > div > div:last-child {
  position: relative;
  display: flex;
  justify-content: center;
  height: 30%;
}
.s_step_1 > div > div > img {
  width: 60%;
}

.s_select_option > a {
  position: absolute;
  bottom: 5%;
  font-size: 16px;
  right: 5%;
  background: white;
  color: black;
  border-radius: 5px;
}

.s_expert div {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.s_expert div button {
  border: none;
  display: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 10px;
  letter-spacing: 1px;
  transition: 1s all;
  float: right;
  color: white;
  background-color: #0a0f25;
  cursor: pointer;
  position: relative;
}

.s_select_option button:hover {
  background-color: #C9C1B6;
  color: white;
}
.s_select_option > div p {
  font-family: "Gotham Bold";
  font-size: 13px;
  padding: 20px 0;
  line-height: 22.5px;
}

.s_steps_expert {
  position: relative;
  width: 100%;
  background-color: white;
  height: 100px;
  padding: 0 10% 0 5%;
  margin-bottom: 3%;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.s_steps {
  position: relative;
  height: 5px;
}

.current {
  background-color: #325a66;
  transition: all 1s ease-in;
}
.current span {
  position: absolute;
  right: -25px;
  bottom: -60px;
}
.current:before {
  position: absolute;
  content: "";
  width: 2px;
  height: 35px;
  right: 0;
  bottom: -30px;
  background-color: #325a66;
}

.s_line {
  width: 100%;
  display: flex;
  height: 5px;
  background: rgba(48, 51, 57, 0.15);
  position: relative;
  flex-direction: row;
}

.s_step_6_number {
  display: none;
  flex-direction: column;
}

.s_step_4 > span {
  margin-top: 20px;
}

.s_step_2 .maison, .s_step_2 .appartement {
  display: none;
}

.appartement, .maison {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.appartement > div, .maison > div {
  width: 49%;
}

.appartement div, .maison div {
  margin: 10px 0;
}

.s_step_9 > div > div {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  background: white;
  padding: 10px;
  align-items: center;
  border-radius: 5px;
  margin: 10px 0;
}

.s_box_number {
  width: 100% !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.s_select_option .s_step_white {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  background: white;
  margin: 20px 0 0 0;
}
.s_select_option .s_step_white input[type=checkbox] {
  position: relative;
  height: 36px;
  width: 36px;
  padding: 0 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: 1px solid #ececea !important;
  border-radius: 10px !important;
  transition: 1s all;
}
.s_select_option .s_step_white input[type=checkbox]:checked {
  position: relative;
  background-color: #325a66;
}
.s_select_option .s_step_white input[type=checkbox]:checked::before {
  content: url("../../assets/svg/valide.svg");
  position: absolute;
  width: 18px;
  height: 18px;
}
.s_select_option .s_step_white span {
  font-family: "Gotham Book" !important;
  color: #989cad !important;
  opacity: 1 !important;
}
.s_select_option .s_step_white > div:not(:last-child) {
  width: 49%;
}
.s_select_option .s_step_white > div:last-child {
  width: 100%;
}

.s_step_9 > div > div input {
  background: whitesmoke;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.s_select_option .s_box_number {
  display: none;
}

.s_step_6 > div:last-child span, .s_box_number span {
  margin-bottom: 20px;
}

.s_step_2, .s_step_3, .s_step_4, .s_step_5, .s_step_6, .s_step_7, .s_step_8, .s_step_9, .s_step_10, .s_step_11, .s_step_12 {
  display: none;
  flex-direction: column;
}

.s_select_option {
  padding: 0 20px;
}
.s_select_option input, .s_select_option select {
  border: 1px solid #ececea !important;
  box-sizing: border-box;
  border-radius: 15px !important;
  width: 90%;
  background-color: transparent !important;
  padding: 15px;
}
.s_select_option .s_comfirme {
  display: flex !important;
}
.s_select_option form h4 {
  display: flex;
  align-items: center;
  font-family: "Gotham Book";
  font-size: 13px;
  padding: 20px 0;
  line-height: 22.5px;
}
.s_select_option form h4 p {
  color: #325a66;
  padding: 0 !important;
  margin-left: 5px;
  cursor: pointer;
  text-decoration: underline;
}
.s_select_option form img {
  top: 40% !important;
}
.s_select_option form input {
  margin: 15px 0;
  width: 100% !important;
}
.s_select_option form > div {
  display: flex;
}
.s_select_option input {
  height: 42px;
}
.s_select_option select {
  height: 52px;
}
.s_select_option span {
  font-family: "Gotham Bold";
  font-size: 14px;
  line-height: 22.5px;
  margin: 10px 0;
  opacity: 0.6;
  color: #0a0f25;
}

.s_step_1 {
  align-items: center;
  display: none;
  flex-direction: row;
  justify-content: space-around;
}

.s_bloc_expert {
  background-color: white;
  width: 100%;
  z-index: 10;
  padding: 0 5% 5% 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.s_widget {
  position: relative;
  display: none !important;
  background-color: white;
  border: 1px solid #eee;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.s_widget > div:first-child {
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  padding-bottom: 40px;
}
.s_widget > div:first-child h4 {
  position: relative;
  font-family: "Gotham Bold";
  font-weight: bold;
  font-size: 16px;
}
.s_widget > div:first-child h4:before {
  position: absolute;
  content: "";
  background-color: #C9C1B6;
  bottom: -10px;
  left: 0;
  width: 30%;
  height: 1px;
}
.s_widget > div:last-child {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: nowrap;
}
.s_widget > div:last-child > div {
  padding-bottom: 10px;
}

.history_adresse {
  padding-bottom: 10px;
}

.s_widget > div:last-child > div span, .history_adresse span {
  font-size: 14px;
  font-family: "Gotham Bold";
  font-weight: bold;
  color: #C9C1B6;
}

.s_widget > div:last-child > div p, .history_adresse p {
  position: relative;
  font-size: 12px;
  font-family: "Gotham Bold";
  font-weight: bold;
}

.s_estimation {
  position: relative;
  background-color: white;
  width: 100%;
  height: 100%;
}

.estimation-button {
  margin: 0;
  padding: 0 10% 0 5%;
  flex-direction: row;
}

footer {
  position: relative;
  height: auto;
  width: 100%;
  padding: 5% 0;
  display: flex;
  z-index: 5;
  background: #ececea;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
footer > ul {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  padding: 0 10% 5% 10%;
  justify-content: space-between;
}
footer > ul > li {
  position: relative;
  padding: 0 10px;
  text-transform: uppercase;
  font-family: "Optimal";
  font-size: 14px;
  color: #325a66;
  cursor: pointer;
}
footer > img {
  position: relative;
  width: 64px;
  margin-bottom: 5%;
}
footer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
footer > div > p {
  position: relative;
  font-family: "Gotham Book";
  font-size: 10px;
  line-height: 15px;
  text-transform: uppercase;
  color: #325a66;
  margin-bottom: 10px;
}
footer > div > p a {
  color: #325a66;
}
footer > div > p strong {
  font-family: "Gotham Bold";
}

/** @format */
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(2);
  }
}
.slider-previsu {
  position: relative;
  max-width: 80%;
  height: 60vh;
  margin: 50px auto 0 auto;
}
.slider-previsu .banner {
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 10%;
  padding: 20px;
  opacity: 0.9;
  background-color: #325a66;
  font-family: "Gotham Bold";
  font-weight: bold;
  color: #ffffff;
}
.slider-previsu .s_main_section {
  max-height: 100%;
}
.slider-previsu .s_main_section .wallpaper-slider {
  position: absolute;
  height: 100%;
  width: 100%;
}
.slider-previsu .s_main_section .wallpaper-slider .image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all 1s ease-in-out;
}
.slider-previsu .s_main_section .wallpaper-slider .image.disable {
  opacity: 0;
}
.slider-previsu .s_main_section .wallpaper-slider .image.active {
  opacity: 1;
  animation: zoom 5s linear;
}
.slider-previsu .hover {
  max-width: 40%;
  position: absolute;
  z-index: 999;
}

.image_form {
  width: 100%;
  padding: 20px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(34, 41, 47, 0.125);
  border-radius: 5px;
  display: none;
}

.accueil_panel.s_board {
  width: 90% !important;
  margin: auto;
}
.accueil_panel.s_button {
  position: absolute !important;
  bottom: 0 !important;
  width: 100% !important;
}
.accueil_panel.s_content {
  padding-bottom: 50px;
}

.title {
  width: 80%;
  padding: 10px;
  margin: 30px auto 0 auto;
  background-color: #325a66;
  font-family: "Gotham Bold";
  font-weight: bold;
  color: #ffffff;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.images-container {
  position: relative;
  max-width: 80%;
  height: 40vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
.images-container .image-arrow {
  width: 100%;
  height: 30px;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
}
.images-container .image-arrow a {
  width: 30px;
  height: 30px;
  color: #ffffff;
}
.images-container .image-arrow .s_button {
  width: 50%;
  border-radius: 0;
}
.images-container .container {
  position: relative;
  max-width: 190px;
  min-width: 190px;
  height: 190px;
  border-radius: 15px;
  margin: 10px !important;
}
.images-container .container .image {
  width: 100%;
  height: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  object-fit: cover;
}
.images-container .container .logo {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  color: #325a66;
  background-color: transparent;
  border: none;
  margin: 0;
}

/** @format */
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  background-color: #FFF;
  font-family: "Gotham Book";
}

#wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

#main {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 1;
}

strong {
  font-family: "Gotham Bold";
}

/* NOTIFICATION */
.s_notif_acheter {
  opacity: 0;
  position: fixed;
  bottom: 20px;
  left: 100px;
  height: 150px;
  width: 250px;
  background: white;
  z-index: 100;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  overflow: hidden;
  transition: all 0.5s;
}
@media (min-width: 201px) and (max-width: 1149px) {
  .s_notif_acheter {
    left: 20px;
    height: 150px;
    width: 250px;
    z-index: 5;
  }
}
.s_notif_acheter > div:first-child {
  position: relative;
  padding: 0 10px;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;
  background-color: #ececea;
}
.s_notif_acheter > div:first-child > p {
  position: relative;
  font-size: 10px;
  color: #325a66;
  text-transform: uppercase;
}
.s_notif_acheter > div:first-child > img {
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.s_notif_acheter > div:last-child {
  position: relative;
  padding: 10px;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.s_notif_acheter > div:last-child > p {
  font-size: 14px;
  opacity: 0.7;
}

.s_notification {
  display: block;
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  top: 10%;
  right: 0;
  z-index: 40;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.s_notification i {
  margin-right: 15px;
  font-weight: bold;
}
.s_notification .error_message {
  padding: 10px;
  font-size: 12px;
  padding: 20px 10px;
  text-transform: uppercase;
  display: flex;
  background: #bd3630;
  color: whitesmoke;
  align-items: center;
  justify-content: center;
}
.s_notification .sucess_message {
  padding: 10px;
  font-size: 12px;
  padding: 20px 10px;
  text-transform: uppercase;
  display: flex;
  background: #4caf4f;
  color: whitesmoke;
  align-items: center;
  justify-content: center;
}

/* NOTIFICATION */
/* CONNEXION */
.s_connexion {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

#connexion_board {
  width: 100%;
}

.s_left {
  width: 50%;
  height: 100%;
  padding: 10%;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.s_left h1 {
  font-size: 26px;
  padding-bottom: 20px;
  width: 100%;
  text-align: left;
  font-family: "Gotham Bold";
  letter-spacing: 8px;
}

.s_right {
  width: 50%;
  height: 100%;
  position: relative;
  right: 0;
  top: 0;
  background-image: url("../../assets/background/buy.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

/* CONNEXION */
/* LOAD */
.s_load {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #325a66;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s_load > div {
  position: relative;
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s_load > div > .s_cache_logo {
  position: absolute;
  background-color: #325a66;
  width: 100%;
  height: 100%;
}

/* LOAD */
/* COUNTER API SEARCH */
.hdpi.pac-logo:after,
.pac-logo:after,
.mapboxgl-ctrl-attrib-inner,
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

/* COUNTER API SEARCH */
/* OVERLAY */
.s_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0a0f25;
  z-index: 7;
}

.bk_bleu_overlay {
  background-color: #325a66;
}

.b_rad_10 {
  border-radius: 10px;
}

.opa_x_6 {
  opacity: 0.6;
}

.opa_x_5,
.opa_x_4 {
  opacity: 0.4;
}

.opa_x_3 {
  opacity: 0.3;
}

.opa_x_2 {
  opacity: 0.2;
}

/* OVERLAY */
/* AXEPTIO */
.htKxuG svg path {
  fill: #325a66 !important;
}
.htKxuG svg path:last-child {
  fill: white !important;
}

.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1 .inpjsL.inpjsL.inpjsL.inpjsL.inpjsL.inpjsL {
  color: #325a66 !important;
}

.EeFHd {
  display: none !important;
}

#axeptio_main_button {
  right: 1% !important;
}

/* AXEPTIO */
/* RECHERCHE */
.s_nodal_content {
  display: none;
  position: absolute !important;
  background: white;
  top: 100%;
  margin-top: 10px;
  z-index: 100;
  padding: 0 !important;
  width: 100% !important;
  border-radius: 5px;
  flex-direction: column !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.s_nodal_content > div:first-child {
  background-color: #f5f7f7;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  position: relative;
  padding: 5px 10px;
}
.s_nodal_content > div:first-child > img {
  width: 26px;
  height: 26px;
  cursor: pointer;
}
.s_nodal_content > div:first-child > div {
  display: flex;
  align-items: center;
  align-content: center;
}
.s_nodal_content > div:first-child > div > img {
  width: 23px;
  height: 23px;
  padding: 5px;
}
.s_nodal_content > div:first-child > div p {
  position: relative;
  font-family: "Gotham Book";
  text-transform: uppercase;
  font-size: 10px;
  top: 1px;
  padding: 0 5px;
}
.s_nodal_content > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.s_nodal_content > div:nth-child(2) > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  padding: 10px 0;
  width: 100%;
  transition: 0.5s all;
  cursor: pointer;
}
.s_nodal_content > div:nth-child(2) > div:not(:last-child) {
  border-bottom: 1px solid whitesmoke;
}
.s_nodal_content > div:nth-child(2) > div:hover {
  background-color: whitesmoke;
}
.s_nodal_content > div:nth-child(2) > div > div:first-child {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s_nodal_content > div:nth-child(2) > div > div:first-child > svg {
  width: 26px;
  height: 26px;
}
.s_nodal_content > div:nth-child(2) > div > div:last-child {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.s_nodal_content > div:nth-child(2) > div > div:last-child > h4 {
  position: relative;
  font-size: 14px;
  font-family: "Gotham Book";
}
.s_nodal_content > div:nth-child(2) > div > div:last-child > p {
  position: relative;
  font-size: 12px;
  font-family: "Gotham Book";
  opacity: 0.6;
}

/* SWIPER */
.swiper-button-next,
.swiper-button-prev {
  top: 90%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #0a0f25;
}

.swiper-pagination-bullet-active {
  background-color: #0a0f25 !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 23px !important;
}

body .swiper {
  overflow: visible;
}

/* SWIPER */
/* FORM */
form {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
}
form select {
  float: left;
  border-radius: 5px;
  background: #ececea;
  width: 100%;
  border: #eee solid 0.2px;
  padding: 15px 20px 15px 55px;
  font-size: 12px;
  margin-bottom: 10px;
  color: #0a0f25;
}
form select::placeholder {
  font-size: 12px;
  opacity: 0.4;
}
form input {
  float: left;
  border-radius: 5px;
  background: #ececea;
  width: 100%;
  border: #eee solid 0.2px;
  padding: 15px 20px 15px 55px !important;
  font-size: 12px;
  margin-bottom: 10px;
  color: #0a0f25;
}
form input::placeholder {
  font-size: 12px;
  opacity: 0.4;
}
form textarea {
  float: left;
  border-radius: 5px;
  background: #ececea;
  width: 100%;
  border: #eee solid 0.2px;
  padding: 15px 20px 15px 20px;
  font-size: 12px;
  margin-bottom: 10px;
  color: #0a0f25;
}
form textarea::placeholder {
  font-size: 12px;
  opacity: 0.4;
}
form > p {
  font-family: "Gotham Book";
  position: relative;
  margin: 5px 0 !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
}
form > b {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-direction: row;
  position: relative;
}
form > b > div {
  position: relative;
  width: 49%;
}
form > b > div > p {
  font-family: "Gotham Book";
  position: relative;
  margin: 5px 0 !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
}
form > b > div > img {
  padding-right: 12px;
  font-size: 16px;
  color: #d17e4b;
  position: absolute;
  width: 26px;
  font-weight: 700;
  top: 50%;
  left: 20px;
  text-align: left;
}

#closeButton {
  position: fixed;
  right: 5%;
  top: 5%;
  width: 80px;
  height: auto;
  cursor: pointer;
}

.bk_sable {
  background-color: #c9c1b6;
}

.bk_white {
  background-color: white;
}

.bk_bleu {
  background-color: #325a66;
  color: #ececea;
}

.bk_white_client {
  background-color: #ececea;
}

.bk_sable {
  background-color: #c9c1b6;
}

.cl_bleu_prusse {
  color: #325a66;
}

.cl_white {
  color: white;
}

.bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

.text_none {
  text-transform: none !important;
}

.m_left_0 {
  margin-left: 0 !important;
}

.m_auto {
  margin: auto !important;
}

.m_b_0 {
  margin-bottom: 0 !important;
}

.m_right_5 {
  margin-right: 5%;
}

.m_top_10 {
  margin-top: 10px;
}

.m_top_20 {
  margin-top: 20px;
}

.m_5_0 {
  margin: 5% 0;
}

.m_1_0 {
  margin: 1% 0;
}

.m_20_center {
  margin: 20px auto 0 auto;
}

.l_10 {
  left: 10%;
}

.l_0 {
  left: 0 !important;
}

.p_0_5 {
  padding: 0 5%;
}

.p_5_5_2_5 {
  padding: 5% 5% 2% 5%;
}

.p_0_10 {
  padding: 0 10%;
}

.p_20_40 {
  padding: 20px 40px !important;
}

.p_15 {
  padding: 15px;
}

.p_15_40 {
  padding: 15px 40px;
}

.p_10_20 {
  padding: 10px 20px;
}

.p_a20_40 {
  padding: 20px 60px 20px 20px;
}

.p_a15_40 {
  padding: 15px 40px 15px 15px;
}

.p_a15_l40 {
  padding: 15px 15px 15px 40px;
}

.p_0 {
  padding: 0 !important;
}

.p_t_0 {
  padding-top: 0 !important;
}

.p_0_20 {
  padding: 0 20px;
}

.z_6 {
  z-index: 6 !important;
}

.bd_l_0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bd_r_none {
  border-right: none !important;
}

.bd_r_0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bd_1_prusse {
  border: 1px solid #325a66;
}

.bd_1_white {
  border: 1px solid white;
}

.bd_r_10 {
  border-radius: 10px;
}

.fs_16 {
  font-size: 16px;
}

.fs_12 {
  font-size: 12px;
}

.fs_10 {
  font-size: 10px;
}

.fs_08 {
  font-size: 8px;
}

body section .t_none {
  text-transform: none !important;
}
body section .w_64px {
  width: 10% !important;
  display: flex;
  height: 100%;
  margin: 0;
  align-items: center;
}
body section .w_64px:hover {
  background-color: transparent !important;
}
body section .w_0 {
  width: 0 !important;
}
body section .w_20 {
  width: 20% !important;
}
body section .w_25 {
  width: 25% !important;
}
body section .w_30 {
  width: 30% !important;
}
body section .w_50 {
  width: 50% !important;
}
body section .w_60 {
  width: 60%;
}
body section .w_70 {
  width: 70% !important;
}
body section .w_100 {
  width: 100% !important;
}
body section .w_80 {
  width: 80%;
}
body section .h_auto {
  height: auto !important;
}
body section .h_30vh {
  height: 30vh;
}
body section .h_100 {
  height: 100%;
}
body section .h_70vh {
  height: 70vh;
}
body section .d_flex {
  display: flex;
  align-items: center;
}
body section .aj_start {
  align-items: flex-start;
  justify-content: flex-start;
}
body section .f_end {
  align-items: flex-end;
}
body section .f_start {
  align-items: flex-start;
}
body section .f_center {
  align-items: center !important;
}
body section .f_column_reverse {
  flex-direction: column-reverse;
}
body section .f_reverse {
  flex-direction: row-reverse;
}
body section .t_align_center {
  text-align: center;
}
body section .t_align_right {
  text-align: right;
}
body section .index_10 {
  z-index: 10;
}
body section .s_overflow_h {
  overflow: hidden !important;
}
body .j_center {
  justify-content: center !important;
}
body .j_start {
  justify-content: flex-start !important;
}
body .top_60 {
  top: 60% !important;
}
body .top_100 {
  top: 100% !important;
}
body .j_between {
  justify-content: space-between !important;
}

.s_modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: #000000e3;
  width: 100%;
  height: 100%;
  padding: 0 5%;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.s_modal .zoomSlide {
  overflow: hidden;
}
.s_modal .swiper-button-prev {
  padding-right: 80px;
}
.s_modal .swiper-button-next {
  padding-left: 80px;
}
.s_modal .swiper-button-prev,
.s_modal .swiper-button-next {
  color: white;
  width: 100%;
  top: 95%;
  display: flex;
  z-index: 100;
  position: fixed;
  justify-content: center;
  align-items: center;
}
.s_modal > img {
  position: fixed;
  right: 20px;
  top: 20px;
  width: 54px;
  cursor: pointer;
}
.s_modal > div img {
  display: block;
  width: 100%;
  height: 80vh;
  object-fit: contain;
  padding: 20px 5%;
}
.s_modal .j_end {
  justify-content: flex-end !important;
}

.mobile_desktop {
  display: block !important;
}

.big-image {
  width: 100% !important;
  height: 100% !important;
}