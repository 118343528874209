/** @format */

@use "variables";

tbody {
  tr {
    &:nth-child(even) {
      background-color: #c9c0b652;
    }
    &:nth-child(odd) {
      background-color: white;
    }
  }
}

.hight {
  text-transform: uppercase;
}

.pieces {
  position: relative;
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  width: 100% !important;
  gap: 40px;
  .piece {
    position: relative;
    flex-direction: column !important;
    width: 300px !important;
    height: 200px;
    border-radius: 10px;
    padding: 40px 20px;
    background-color: variables.$S_SABLE;
    .type {
      font-size: 21px;
      text-transform: uppercase;
    }
    .label {
      font-size: 12px;
    }
  }
}

.s_main_section_panel {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 10;
}

.s_top_header {
  position: relative;
  left: 0;
  height: 50px;
  display: flex;
  z-index: 10;
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  padding: 0 5%;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    flex-direction: row;
    height: 50%;

    > {
      h3 {
        margin: 0;
        font-weight: normal;
        font-size: 12px;
        color: variables.$S_MAIN_COLOR_BLACK;
        text-transform: uppercase;

        > b {
          color: variables.$S_BLEU_PRUSSE;
        }
      }

      h4 {
        position: relative;
        font-size: 14px;
        text-transform: uppercase;
        margin: 0;
      }

      span {
        position: relative;
        font-size: 10px;
        text-transform: uppercase;
        color: #959cb6;
      }
    }
  }
}

.s_cache_panel {
  position: fixed;
  z-index: 10;
  display: flex;
  backdrop-filter: blur(5px);
  width: 100%;
  padding: 20% 15% 10% 15%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    font-size: 18px;
    color: variables.$S_MAIN_COLOR_BLACK;
    text-transform: uppercase;
    text-align: center;
  }

  svg {
    width: 100px;
    height: auto;

    path {
      fill: variables.$S_BLEU_PRUSSE;
    }
  }
}

.s_appartement_panel {
  position: relative;
  flex-direction: row !important;
  overflow: hidden;
  padding: 1.25em;

  > div {
    position: relative;
    width: 33.33%;
    height: 100%;
    padding: 0 5px;
    margin: 0 5px;
    display: flex;
    flex-direction: column;

    > {
      b {
        font-family: "Gotham Book";
        font-size: 12px;
        font-weight: normal;
        margin: auto 0;
        padding-top: 20px;
        line-height: 15px;
        height: auto;
      }

      form {
        margin-top: auto;
      }
      div {
        width: 100%;
        display: flex;
        overflow: hidden;
        height: 100%;
        background-color: whitesmoke;
        border-radius: 0.357rem;
        border: 1px solid #eee;
        flex-direction: column;
        justify-content: space-between;

        > div {
          &:last-child {
            > p {
              font-size: 10px;
              text-transform: uppercase;
              position: relative;
              height: 100%;
              color: variables.$S_BLEU_PRUSSE;
              display: flex;
              justify-content: center;
              align-items: center;

              > a {
                color: variables.$S_BLEU_PRUSSE;
                text-align: center;
                padding: 10px;
                font-weight: bold;
              }
            }

            height: 30%;
          }

          &:first-child {
            width: 100%;
            height: 70%;
            overflow: hidden;

            > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: 50% 50%;
            }
          }
        }
      }

      span {
        white-space: nowrap;
        border-radius: 0.357rem;
        width: 40px;
        height: auto;
        margin-right: 1.25em;
        position: relative;
        padding: 10px;
        cursor: pointer;
        display: inline-flex;
        font-size: 1rem;
        text-align: center;
        vertical-align: middle;
        font-weight: 600;
        color: variables.$S_BLEU_PRUSSE;
        background: variables.$S_WHITE_COLOR_CLIENT;
      }

      p {
        font-family: "Gotham Bold";
        margin: 10px 0;
        position: relative;
        font-weight: bold;
        font-size: 10px;
        text-transform: uppercase;
      }
    }

    > h6 {
      font-size: 1.25em;
      color: variables.$S_BLEU_PRUSSE;
    }
  }
}

.s_board_screen {
  height: 100vh;
  z-index: 10;
}

.s_bord_separator {
  position: relative;
  display: block;
  width: 1px;
  height: 4px;
  border-radius: 2px;
  background: #e7e8ef;
  margin: 0 10px;
  height: 50%;
}

.s_color_board {
  background: #f2f3f8;
  z-index: 10;
}

.s_board {
  position: relative;
  padding: 5%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 10;
  @media screen and (min-width: 200px) and (max-width: 1155px) {
    padding: 150px 5% 10% 5%;
  }
}

.s_column_widget {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
  justify-content: space-between;
  z-index: 10;
}

.s_card {
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 20px;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(34, 41, 47, 0.125);
  border-radius: 0.428rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  justify-content: space-between;
  z-index: 10;

  > {
    h4,
    div > h4 {
      position: relative;
      margin: 0;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 10px;
      text-transform: uppercase;
      color: variables.$S_MAIN_COLOR_BLACK;
    }

    h4 {
      &::before {
        content: "";
        position: absolute;
        width: 50px;
        height: 1px;
        background-color: variables.$S_BLEU_PRUSSE;
        bottom: 0;
        left: 0;
      }

      text-decoration: none;
      color: variables.$S_MAIN_COLOR_BLACK;
      transition: all 1s;
    }

    div > h4 > a {
      text-decoration: none;
      color: variables.$S_MAIN_COLOR_BLACK;
      transition: all 1s;
    }

    h4,
    div > h4 > a:hover {
      color: variables.$S_BLEU_PRUSSE;
    }
  }
}

.s_card_flex {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  justify-content: flex-start;
  flex-direction: column;
  align-content: center;
}

.s_card > div {
  &:not(.s_notification) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  > a {
    border-radius: 0.358rem;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px;
    background: variables.$S_BLEU_PRUSSE;
    text-align: center;
    color: whitesmoke;
    font-size: 12px;
    transition: all 1s;
    cursor: pointer;
  }
}

#s_dowload,
.s_return_invoice {
  border-radius: 0.358rem;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px;
  background: variables.$S_BLEU_PRUSSE;
  text-align: center;
  color: whitesmoke;
  font-size: 12px;
  transition: all 1s;
  cursor: pointer;
}

.s_card > div > a:hover,
#s_dowload:hover,
.s_return_invoice:hover {
  background-color: variables.$S_MAIN_COLOR_BLACK;
  color: whitesmoke;
}

.s_card_link {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    &:first-child {
      width: 30%;
      position: relative;
      justify-content: center;
      align-items: center;

      > svg {
        height: 50px;
        width: 50px;

        g [fill] {
          fill: variables.$S_BLEU_PRUSSE;
        }
      }
    }

    &:last-child {
      width: 70%;
      position: relative;
      justify-content: center;
    }
  }
}

.s_card > p {
  position: relative;
  margin: 0;
  font-size: 12px;
  height: 100%;
  display: flex;
}

.s_maj_profil > div {
  &:first-child {
    position: relative;
    width: 100%;
    height: 40%;
    background-color: variables.$S_WHITE_COLOR_CLIENT;
    border-radius: 0.428rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    > {
      svg,
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &:last-child {
    position: relative;
    width: 100%;
    height: 60%;
    padding: 20px;
    display: flex;
    flex-direction: column;

    > div {
      &:first-child {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
      }

      &:last-child {
        margin-top: 30px;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        align-items: flex-start;
        justify-content: flex-start;

        > div {
          width: 100%;
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          align-content: center;

          > div {
            &:first-child {
              white-space: nowrap;
              border-radius: 0.357rem;
              margin-right: 20px;
              position: relative;
              padding: 7px;
              cursor: pointer;
              display: inline-flex;
              font-size: 1rem;
              text-align: center;
              vertical-align: middle;
              font-weight: 600;
              color: variables.$S_BLEU_PRUSSE;
              background: variables.$S_WHITE_COLOR_CLIENT;
            }

            &:last-child {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              > {
                h6 {
                  margin: 0;
                  font-weight: bold;
                  font-size: 12px;
                  color: variables.$S_BLEU_PRUSSE;

                  > a {
                    text-decoration: none;
                    color: variables.$S_MAIN_COLOR_BLACK;
                    font-size: 12px;
                    transition: 1s all;

                    &:hover {
                      color: variables.$S_BLEU_PRUSSE;
                    }
                  }
                }

                small {
                  margin: 0;
                  font-weight: normal;
                  font-size: 8px;
                  text-transform: uppercase;
                  color: variables.$S_BLEU_PRUSSE;
                }
              }
            }
          }
        }
      }

      &:first-child > div {
        &:first-child {
          text-align: center;
          border-right: 1px solid #d8d6de;
          width: 30%;
          display: flex;
          padding-right: 1.3rem;
          margin-right: 1.3rem;
          flex-direction: column;
          justify-content: space-between;

          > {
            h6 {
              margin: 0;
              text-transform: uppercase;
              font-weight: bold;
              font-size: 12px;
            }

            h3 {
              margin: 0;
              text-transform: uppercase;
              font-weight: bold;
              font-size: 14px;
            }
          }
        }

        &:last-child {
          text-align: left;
          width: 70%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          > {
            h6 {
              margin: 0;
              text-transform: uppercase;
              font-weight: bold;
              font-size: 12px;
            }

            h3 {
              margin: 0;
              text-transform: uppercase;
              font-weight: normal;
              font-size: 12px;
              opacity: 0.4;
            }
          }
        }
      }
    }
  }
}

/* REMISE DISPLAY */

.s_small_widget .s_row {
  flex-direction: row;
}

.s_stats_maj_profil {
  position: relative;
  display: inline-block;
  width: 90%;
  height: 100%;
  border-radius: 100%;
  margin-bottom: 18px;
  text-align: center;
  background: whitesmoke;
}

.s_percent {
  display: inline-block;
  font-size: 12px;
  line-height: 100%;
  z-index: 2;
  font-weight: 700;
  color: #666;
}

.s_card_flex > p {
  > {
    i {
      font-size: 14px;
      margin-right: 10px;
      color: variables.$S_BLEU_PRUSSE;
    }

    b {
      color: variables.$S_BLEU_PRUSSE;
    }
  }

  margin: 0;
  color: variables.$S_MAIN_COLOR_BLACK;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: normal;
}

.s_card > {
  div > p {
    position: relative;
    margin: 0;
    height: auto;
    font-size: 12px;
  }

  .s_date {
    font-size: 10px;

    /* opacity: .7; */
    color: #999;
    letter-spacing: 2px;
    margin-bottom: 10px;
  }
}

.s_small_widget {
  width: 30%;
  height: 220px;
  margin-right: 1%;
}

.s_semi_small_widget {
  width: 25%;
  height: 220px;
  margin-right: 1%;
}

.s_semi_medium_widget {
  width: 40%;
  height: 220px;
  margin-right: 1%;
}

.s_medium_widget {
  width: 50%;
  height: 220px;
  margin-right: 1%;
}

.s_medium_55_widget {
  width: 55%;
  height: 220px;
  margin-right: 1%;
}

.s_3_small_widget {
  width: 33%;
  height: 220px;
  margin-right: 1%;
}

.s_large_widget {
  width: 70%;
  height: 220px;
  margin-right: 1%;
}

.s_very_large_widget {
  width: 60%;
  height: 400px;
  margin-right: 1%;
}

.s_widget_no_margin {
  margin: 0 !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.s_widget_no_padding {
  padding: 0;
}

.s_auto {
  height: auto;
}

.s_300px {
  height: 300px;
}

.s_400px {
  height: 370px;
}

.s_600px {
  height: 600px;
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 5px;
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #325a66;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #325a66;
  }
}

.s_full {
  width: 100% !important;
}

form .s_column {
  flex-direction: column !important;
  align-items: flex-start !important;
}

/* quilljs */

.ql-toolbar.ql-snow {
  border-radius: 5px;
  background: whitesmoke;
  width: 100%;
  border: #eee solid 0.2px !important;
  padding: 15px 20px 15px 20px !important;
  font-family: "Gotham Book" !important;

  button:hover {
    color: variables.$S_BLEU_PRUSSE !important;
  }
}

.ql-snow {
  .ql-toolbar button:hover,
  &.ql-toolbar button:focus,
  .ql-toolbar button:focus,
  &.ql-toolbar button.ql-active,
  .ql-toolbar button.ql-active,
  &.ql-toolbar .ql-picker-label:hover,
  .ql-toolbar .ql-picker-label:hover,
  &.ql-toolbar .ql-picker-label.ql-active,
  .ql-toolbar .ql-picker-label.ql-active,
  &.ql-toolbar .ql-picker-item:hover,
  .ql-toolbar .ql-picker-item:hover,
  &.ql-toolbar .ql-picker-item.ql-selected,
  .ql-toolbar .ql-picker-item.ql-selected {
    color: variables.$S_BLEU_PRUSSE !important;
  }

  &.ql-toolbar button:hover .ql-stroke,
  .ql-toolbar button:hover .ql-stroke,
  &.ql-toolbar button:focus .ql-stroke,
  .ql-toolbar button:focus .ql-stroke,
  &.ql-toolbar button.ql-active .ql-stroke,
  .ql-toolbar button.ql-active .ql-stroke,
  &.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-toolbar .ql-picker-label:hover .ql-stroke,
  &.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  &.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-toolbar .ql-picker-item:hover .ql-stroke,
  &.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  &.ql-toolbar button:hover .ql-stroke-miter,
  .ql-toolbar button:hover .ql-stroke-miter,
  &.ql-toolbar button:focus .ql-stroke-miter,
  .ql-toolbar button:focus .ql-stroke-miter,
  &.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-toolbar button.ql-active .ql-stroke-miter,
  &.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  &.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  &.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  &.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: variables.$S_BLEU_PRUSSE !important;
  }

  &.ql-toolbar button:hover .ql-fill,
  .ql-toolbar button:hover .ql-fill,
  &.ql-toolbar button:focus .ql-fill,
  .ql-toolbar button:focus .ql-fill,
  &.ql-toolbar button.ql-active .ql-fill,
  .ql-toolbar button.ql-active .ql-fill,
  &.ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-toolbar .ql-picker-label:hover .ql-fill,
  &.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  &.ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-toolbar .ql-picker-item:hover .ql-fill,
  &.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  &.ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-toolbar button:hover .ql-stroke.ql-fill,
  &.ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-toolbar button:focus .ql-stroke.ql-fill,
  &.ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-toolbar button.ql-active .ql-stroke.ql-fill,
  &.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  &.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  &.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  &.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: variables.$S_BLEU_PRUSSE;
  }
}

.ql-toolbar.ql-snow .ql-picker-label {
  display: flex;
  align-items: center;
}

.ql-editor {
  height: 600px !important;
}

.ql-container.ql-snow {
  border-radius: 5px;
  margin: 10px 0;
  background: whitesmoke;
  width: 100%;
  border: #eee solid 0.2px !important;
  padding: 15px 20px 15px 20px !important;
}

.s_card > form > b > div > p {
  margin: 0;
  font-size: 11px;
  font-weight: normal;
  text-transform: uppercase;
}

.s_board > .s_column_widget > .s_large_widget > .s_card_stats {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;

  > div {
    display: flex;
    width: 50%;
    justify-content: space-around;
    position: relative;
    flex-direction: row;
    align-items: center;
    align-content: center;

    > div {
      width: 50%;
      height: auto;
      padding: 5px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
    }
  }
}

.s_avatar,
.s_devis,
.s_euros {
  background: variables.$S_WHITE_COLOR_CLIENT;
}

.s_avatar svg,
.s_devis svg,
.s_euros svg {
  color: variables.$S_BLEU_PRUSSE;
}

.s_content_card {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;

  > {
    span {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
      position: relative;
    }

    p {
      position: relative;
      margin: 0;
      font-size: 12px;
    }
  }
}

.s_icon svg {
  width: 20px;
  height: 20px;
}

.s_chart {
  width: 100%;
  justify-content: space-around;
}

.s_circular-chart {
  display: block;
  margin: 0 auto;
  max-width: 80%;
  max-height: 80%;
}

.s_circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.s_circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.s_actus {
  position: relative;
  height: 100%;
  width: 100%;

  > div {
    display: flex;
    width: 100%;
    height: 33.3%;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    padding: 5px 0;

    > {
      div {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-content: center;

        > b {
          flex-direction: column;
          line-height: 15px;

          > {
            p {
              margin: 0;
              font-size: 12px;
            }

            span {
              margin: 0;
              font-size: 10px;
              color: #999;
              font-weight: normal;
              text-transform: capitalize;
            }
          }
        }
      }

      span {
        font-size: 10px;

        /* opacity: .7; */
        color: #999;
        letter-spacing: 2px;
        margin-bottom: 10px;
      }

      div > .s_icon {
        white-space: nowrap;
        background: rgba(40, 199, 111, 0.12) !important;
        margin-right: 20px;
        border-radius: 0.357rem;
        position: relative;
        cursor: pointer;
        color: #fff;
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        font-size: 1rem;
        width: 32px;
        height: 32px;
        text-align: center;
        vertical-align: middle;
        font-weight: 600;

        > svg {
          color: #28c76f;
        }
      }
    }
  }
}

@-webkit-keyframes show_dropdown {
  from {
    top: 60px;
  }

  to {
    top: 36px;
  }
}

.s_circular-chart.s_color .s_circle {
  stroke: variables.$S_BLEU_PRUSSE;
}

.s_percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}

.s_revendeur_button {
  &:hover > ul {
    display: flex;

    /* animation: show_dropdown; */
    animation-duration: 0.2s;
  }

  ul {
    display: none;
    width: 100%;
    position: absolute;
    z-index: 10;
    padding: 10px 20px;

    /* margin: 40px 0; */
    top: 36px;
    font-size: 1rem;
    transition: all 1s;
    color: #6e6b7b;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(34, 41, 47, 0.05);
    border-radius: 0.357rem;
    flex-direction: column;

    li {
      font-size: 10px;
      margin-bottom: 5px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      border-bottom: 1px solid whitesmoke;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      clear: both;
      font-weight: 400;
      color: #6e6b7b;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;

      a {
        display: block;
        width: 100%;
        padding: 0.65rem 1.28rem;
        clear: both;
        text-decoration: none;
        color: variables.$S_MAIN_COLOR_BLACK;
        opacity: 0.7;
        font-weight: bold;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        transition: all 1s;

        &:hover {
          color: variables.$S_BLEU_PRUSSE;
          opacity: 1;
        }
      }
    }
  }
}

.s_profil div:last-child {
  background: none;
}

.table {
  border-radius: 0.357rem;
  width: 100%;
  display: table;
  color: #6e6b7b;
  border-color: #ebe9f1;
  vertical-align: middle;
  caption-side: bottom;
  border-collapse: collapse;
  text-indent: initial;
  border-spacing: 2px;
  border-bottom-right-radius: 0.357rem;
  border-bottom-left-radius: 0.357rem;
  margin-bottom: 0;

  > thead {
    vertical-align: bottom;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
}

tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-bottom: 1px solid whitesmoke;
}

table th {
  background-color: white;
  vertical-align: top;
  text-align: left;
  border-right: 1px solid whitesmoke;
  text-transform: uppercase;
  font-size: 10px;
  width: auto;
  letter-spacing: 0.5px;
  padding: 5px 10px;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px transparent;
}

.table > tbody {
  vertical-align: inherit;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  .image {
    width: 200px;
    height: 100px;
    object-fit: cover;
  }
}

td {
  padding: 20px 10px;
  text-align: left;
  // background-color: white;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px transparent;

  > span {
    font-size: 12px;
    text-transform: uppercase;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    i {
      font-size: 16px;
    }
  }
}

.s_color {
  color: variables.$S_BLEU_PRUSSE;
  font-weight: bold;
}

.s_color_link {
  color: #7367f0;
}

.s_display_none {
  display: none;
}

.s_color_red {
  color: rgb(172, 20, 20);
}

.s_board .s_column_widget .s_card .s_head_invoice {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #eee;
}

.s_head_invoice div {
  &:first-child {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &:last-child {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    > {
      h4 {
        margin: 0;
        text-transform: uppercase;
        font-size: 14px;
        position: relative;
      }

      span {
        position: relative;
        text-transform: uppercase;
        font-size: 12px;
      }
    }
  }

  &:first-child > img {
    width: 64px;
    height: auto;
    position: relative;
  }
}

.s_table_invoice {
  width: 100%;
  height: auto;
  padding: 20px 0;

  tbody h3 {
    margin: 0;
    display: flex;
    flex-direction: column;
    font-size: 8px;
    font-weight: bold;
    color: variables.$S_BLEU_PRUSSE;
    text-transform: uppercase;

    span {
      font-size: 12px;
      font-weight: normal;
      color: variables.$S_MAIN_COLOR_BLACK;
    }
  }
}

.s_content .s_board .s_total_invoice {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.s_table_invoice {
  th,
  td {
    text-align: left;
  }
}

.s_invoice_control {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  height: auto;
  justify-content: space-between;
}

.s_card {
  form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    background: white;
    h2 {
      text-align: left;
      font-size: 18px;
      text-transform: uppercase;
      color: variables.$S_BLEU_PRUSSE;
      font-weight: bold;
      margin: 20px 0;
    }

    > div {
      position: relative;
      width: 100%;
      height: auto;
      margin-top: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }
}

.s_card > form > div {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.s_card form > b > div,
.s_card > form > b > div {
  width: 49%;
}

.ql-editor {
  p,
  ol,
  ul,
  pre,
  blockquote {
    font-weight: normal !important;
  }
}

.s_card form > b,
.s_card > form > b {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.s_card form > div > a,
.s_card > form > div > a {
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 1px;
  padding-bottom: 5px;
  color: variables.$S_MAIN_COLOR_BLACK;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.s_card form > div > a > i,
.s_card > form > button > i {
  margin-right: 10px;
}

form > div > a:hover,
.s_card > form > div > a:hover {
  color: variables.$S_BLEU_PRUSSE;
}

form > {
  label,
  b > div > label {
    float: left;
    position: relative;
    width: 100%;
    text-align: left;
    font-weight: 500;
  }
}

.s_card > form > b > div > label {
  float: left;
  position: relative;
  width: 100%;
  text-align: left;
  font-weight: 500;
}

form > {
  label > i,
  b > div > label > i {
    padding-right: 12px;
    font-size: 16px;
    color: variables.$S_BLEU_PRUSSE;
    position: absolute;
    font-weight: bold;
    top: 14px;
    left: 20px;
    text-align: left;
  }
}

.s_card > form > b > div > label > i {
  padding-right: 12px;
  font-size: 16px;
  color: variables.$S_BLEU_PRUSSE;
  position: absolute;
  font-weight: bold;
  top: 14px;
  left: 20px;
  text-align: left;
}

form > p,
.s_card > form > p {
  position: relative;
  margin: 5px 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}
.s_card {
  form {
    textarea {
      height: 150px;
      float: left;
      border-radius: 5px;
      background: whitesmoke;
      width: 100%;
      border: #eee solid 0.2px;
      padding: 15px 20px 15px 55px;
      color: white;
      font-size: 12px;
      margin-bottom: 10px;
      color: variables.$S_MAIN_COLOR_BLACK;
    }

    > {
      input,
      b > div > input {
        float: left;
        border-radius: 5px;
        background: whitesmoke;
        width: 100%;
        border: #eee solid 0.2px;
        padding: 15px 20px 15px 55px;
        color: white;
        font-size: 12px;
        margin-bottom: 10px;
        color: variables.$S_MAIN_COLOR_BLACK;
      }
    }
  }
}

.s_card > form > b > div > input {
  float: left;
  border-radius: 5px;
  background: whitesmoke;
  width: 100%;
  border: #eee solid 0.2px;
  padding: 15px 20px 15px 55px;
  color: white;
  font-size: 12px;
  margin-bottom: 10px;
  color: variables.$S_MAIN_COLOR_BLACK;
}

.s_card {
  form > {
    input,
    b > div > input {
      float: left;
      border-radius: 5px;
      background: whitesmoke;
      width: 100%;
      border: #eee solid 0.2px;
      padding: 15px 20px 15px 55px;
      color: white;
      font-size: 12px;
      margin-bottom: 10px;
      color: variables.$S_MAIN_COLOR_BLACK;
    }
  }
}

.s_card > form > b > div > select,
.s_card input {
  float: left;
  border-radius: 5px;
  background: whitesmoke;
  width: 100%;
  border: #eee solid 0.2px;
  padding: 15px 20px 15px 55px;
  color: white;
  font-size: 12px;
  margin-bottom: 10px;
  color: variables.$S_MAIN_COLOR_BLACK;
}
.s_card {
  form {
    select {
      float: left;
      border-radius: 5px;
      background: whitesmoke;
      width: 100%;
      border: #eee solid 0.2px;
      padding: 15px 20px 15px 55px;
      color: white;
      font-size: 12px;
      margin-bottom: 10px;
      color: variables.$S_MAIN_COLOR_BLACK;
    }

    > b > div > input {
      text-transform: none;
    }
  }
}

.s_card > form > b > div > select {
  text-transform: none;
}

.s_card {
  form {
    select {
      text-transform: none;
    }

    > {
      input[type="text"]:focus {
        color: variables.$S_MAIN_COLOR_BLACK;
        text-transform: none;
      }

      b > div > input[type="text"] {
        &:focus {
          color: variables.$S_MAIN_COLOR_BLACK;
          text-transform: none;
        }

        color: variables.$S_MAIN_COLOR_BLACK;
        text-transform: none;
      }
    }
  }
}

/* MEDIA SCREEN TABLETTE */
@media screen and (min-width: 800px) and (max-width: 1200px) {
  .s_card_stats {
    flex-direction: column;
  }

  .s_board > .s_column_widget > .s_large_widget > .s_card_stats > div,
  .s_medium_widget,
  .s_3_small_widget {
    width: 100%;
  }

  .s_board > .s_column_widget > .s_large_widget > .s_card_stats > div > div {
    justify-content: flex-start;
  }
}

/* MEDIA SCREEN MOBILE */
@media screen and (min-width: 100px) and (max-width: 845px) {
  .s_top_header {
    > div > h3 {
      display: none;
    }

    top: 70px;
  }

  .s_appartement_panel {
    flex-direction: column !important;
  }

  .s_board {
    padding: 30% 5% 10% 5%;
  }

  .s_card_stats,
  .s_column_widget,
  .s_column_row {
    flex-direction: column !important;
  }

  .s_large_widget,
  .s_small_widget,
  .s_medium_widget,
  .s_semi_medium_widget,
  .s_3_small_widget {
    margin-bottom: 1%;
  }

  .table,
  .s_history_simulateur {
    margin-top: 1%;
  }

  .s_column_widget {
    margin: 0 0 1% 0;
  }

  .s_board_screen,
  .s_300px {
    height: auto;
  }

  .s_appartement_panel > div > div > div:last-child > p {
    height: 40px !important;
  }

  .s_appartement_panel > div {
    padding: 20px 0 !important;
  }

  .s_appartement_panel > div,
  .s_simulateur,
  .s_history_simulateur,
  .s_board > .s_column_widget > .s_large_widget > .s_card_stats > div,
  .s_3_small_widget,
  .s_semi_medium_widget,
  .s_small_widget,
  .s_large_widget,
  .s_medium_widget {
    width: 100% !important;
  }

  .s_board > .s_column_widget > .s_large_widget > .s_card_stats > div > div {
    justify-content: flex-start;
  }

  .s_simulateur > div > img {
    width: 35%;
    height: 150px;
    padding: 0 10px 0 0;
  }

  .s_simulateur_form p {
    display: flex;
    flex-direction: column-reverse;
    white-space: nowrap;
    overflow: hidden;
  }

  .s_revendeur_button ul {
    position: relative;
    top: 0;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0;
    clear: both;
    color: whitesmoke;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;

    li {
      flex-direction: row;
      border: none;
      margin-bottom: 0;
      color: white;

      a {
        color: whitesmoke;
        opacity: 0.7;
        font-size: 12px;
        padding: 7px 15px;
      }
    }
  }
}
