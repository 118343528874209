@use "variables";
// #return {
//   display: none;
// }
.s_verification_adresse {
    position: fixed;
    width: 100%;
    display: none;
    padding: 10%;
    height: 100%;
    background: whitesmoke;
    z-index: 100;
    align-items: flex-start;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    img {
      width: 64px;
      height: auto;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      font-family: "Gotham Book";
      line-height: 30px;
      letter-spacing: 1px;
      color: variables.$S_MAIN_COLOR_BLACK;
    }

    b {
      font-size: 16px;
      font-family: "Gotham Bold";
      line-height: 30px;
      letter-spacing: 1px;
      color: variables.$S_BLEU_PRUSSE;
    }
  
    > {
      i {
        font-size: 30px;
        width: 100%;
        display: flex;
        margin-bottom: 70px;
        position: relative;
        justify-content: flex-start;
        cursor: pointer;
        transition: 1s all;
  
        &:hover {
          color: variables.$S_BLEU_PRUSSE;
        }
      }
  
      div {
        font-size: 20px;
        display: flex;
        text-transform: uppercase;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
  
        i {
          margin-right: 10px;
          color: #b70d0d;
          font-weight: bold;
        }
  
      }
  
      ul {
        padding: 20px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
  
        > li {
          margin-bottom: 10px;
          margin-right: 10px;
          display: flex;
          border-radius: 5px;
          height: 50px;
          background-color: variables.$S_MAIN_COLOR_BLACK;
          text-align: center;
          align-items: left;
          justify-content: center;
  
          > a {
            position: relative;
            padding: 10px;
            color: whitesmoke;
            cursor: pointer;
            transition: all 1s;
          }
        }
      }
    }
  }


.s_bandeau_child {
  position: relative;
  width: 100%;
  height: 200px;

  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h1 {
    width: 100%;
    height: 100%;
    font-size: 26px;
    letter-spacing: 5px;
    display: flex;
    position: absolute;
    z-index: 10;
    color: white;
    align-items: center;
    justify-content: center;

    &:before {
      position: absolute;
      content: "";
      bottom: 70px;
      width: 50px;
      height: 1px;
      background: white;
    }
  }
}

.s_step_7 a {
  background: variables.$S_MAIN_COLOR_CLIENT;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-weight: 100;
  font-size: 12px;
  width: 10%;
  text-align: center;
  position: relative;
  left: 0;
  border: none;
  font-family: Montserrat;
  cursor: pointer;
}

.s_bottom {
  bottom: 10% !important;
}

.s_step_sell {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  > div > div > div {
    position: relative;
    width: 100%;
    z-index: 10;
    overflow: hidden;
    margin: 60px 10px 0 0;
    padding: 20px;
    height: 400px;
    border-radius: 5px;
    background: white;
    border: 1px #eee solid;
  }

  h3 {
    color: variables.$S_MAIN_COLOR_BLACK;
    position: relative;
    font-size: 18px;
    line-height: 40px;
    opacity: 1;
    font-family: Montserrat;
    padding-bottom: 40px;

    &::before {
      position: absolute;
      content: "";
      bottom: 20px;
      background: variables.$S_MAIN_COLOR_CLIENT;
      left: 0;
      width: 20%;
      height: 2px;
    }
  }

  span {
    color: variables.$S_MAIN_COLOR_BLACK;
    font-size: 12px;
    opacity: 0.7;
    font-family: Montserrat;
  }

  i {
    font-size: 20px;
    padding-bottom: 20px;
  }

  b {
    position: absolute;
    display: flex;
    left: 0;
    font-size: 60px;
    padding: 0 20px;
    width: 100%;
    background: white;
    bottom: 0%;
    color: variables.$S_MAIN_COLOR_CLIENT;
    opacity: 1;
    font-family: Montserrat;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.s_expert {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 0 10% 0 5%;

  > div {
    &:first-child {
      position: relative;
      width: 15%;
      height: auto;
      overflow: hidden;

      img {
        position: relative;
        border-radius: 50%;
        width: 60%;
        height: auto;
      }
    }

    &:last-child {
      position: relative;
      width: 90%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: flex-start;

      span {
        font-family: "Gotham Bold";
        font-size: 16px;
        line-height: 22.5px;
        color: #0a0f25;
      }

      p {
        font-family: "Gotham Book";
        font-size: 13px;
        line-height: 22.5px;
        color: #989cad;
      }
    }
  }
}

.s_select_option > div {
  position: relative;
  width: 100%;
  height: auto;
  padding: 40px 10% 40px 5%;
  justify-content: space-between;

  > div {
    margin: 20px 0;
  }
}

.s_button_widget {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-around;
}

.s_step_1 > div {
  position: relative;
  padding: 20px;
  cursor: pointer;
  width: 40%;
  height: 40%;
  display: flex;
  border-radius: 20px;
  background-color: white;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid #eee;

  > div {
    &:first-child {
      position: relative;
      display: flex;
      justify-content: center;
      height: 70%;
    }

    &:last-child {
      position: relative;
      display: flex;
      justify-content: center;
      height: 30%;
    }

    > img {
      width: 60%;
    }
  }
}

.s_select_option > a {
  position: absolute;
  bottom: 5%;
  font-size: 16px;
  right: 5%;
  background: white;
  color: black;
  border-radius: 5px;
}

.s_expert div {
  button {
    border: none;
    display: none;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    margin-right: 10px;
    letter-spacing: 1px;
    transition: 1s all;
    float: right;
    color: white;
    background-color: variables.$S_MAIN_COLOR_BLACK;
    cursor: pointer;
    position: relative;
  }

  position: relative;
  display: flex;
  justify-content: flex-end;
}

.s_select_option {
  button:hover {
    background-color: variables.$S_MAIN_COLOR_CLIENT;
    color: white;
  }

  > div p {
    font-family: "Gotham Bold";
    font-size: 13px;
    padding: 20px 0;
    line-height: 22.5px;
  }
}

.s_steps_expert {
  position: relative;
  width: 100%;
  background-color: white;
  height: 100px;
  padding: 0 10% 0 5%;
  margin-bottom: 3%;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.s_steps {
  position: relative;
  height: 5px;
}

.current {
  background-color: variables.$S_BLEU_PRUSSE;
  transition: all 1s ease-in;

  span {
    position: absolute;
    right: -25px;
    bottom: -60px;
  }

  &:before {
    position: absolute;
    content: "";
    width: 2px;
    height: 35px;
    right: 0;
    bottom: -30px;
    background-color: variables.$S_BLEU_PRUSSE;
  }
}

.s_line {
  width: 100%;
  display: flex;
  height: 5px;
  background: rgba(48, 51, 57, 0.15);
  position: relative;
  flex-direction: row;
}

.s_step_6_number {
  display: none;
  flex-direction: column;
}

.s_step_4 > span {
  margin-top: 20px;
}
.s_step_2 {
  .maison, .appartement {
    display: none;
  }
}

.appartement, .maison {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  > div {
    width: 49%;
  }
}

.appartement div, .maison div {
  margin: 10px 0;
}

.s_step_9 > div > div {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  background: white;
  padding: 10px;
  align-items: center;
  border-radius: 5px;
  margin: 10px 0;
}

.s_box_number {
  width: 100% !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.s_select_option .s_step_white {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  background: white;
  margin: 20px 0 0 0;
  input[type='checkbox'] {
    position: relative;
    height: 36px;
    width: 36px;
    padding: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    appearance: none;
    border: 1px solid #ececea !important;
    border-radius: 10px !important;
    transition: 1s all;
    &:checked {
      position: relative;
      background-color: variables.$S_BLEU_PRUSSE;
      &::before {
        content: url("../../assets/svg/valide.svg");
        position: absolute;
        width: 18px;
        height: 18px;
      }
    }
  }
  span {
    font-family: "Gotham Book" !important;
    color: #989cad !important;
    opacity: 1 !important;
  }
  > div:not(:last-child) {
    width: 49%;
  }
  > div:last-child {
    width: 100%;
  }
}

.s_step_9 > div > div input {
  background: whitesmoke;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.s_select_option .s_box_number {
  display: none;
}

.s_step_6 > div:last-child span, .s_box_number span {
  margin-bottom: 20px;
}

.s_step_2, .s_step_3, .s_step_4, .s_step_5, .s_step_6, .s_step_7, .s_step_8, .s_step_9, .s_step_10, .s_step_11, .s_step_12 {
  display: none;
  flex-direction: column;
}

.s_select_option {
  padding: 0 20px;
  input, select {
    border: 1px solid variables.$S_WHITE_COLOR_CLIENT !important;
    box-sizing: border-box;
    border-radius: 15px !important;
    width: 90%;
    background-color: transparent !important;
    padding: 15px;
  }
  .s_comfirme {
    display: flex !important;
  }
  form h4 {
    display: flex;
    align-items: center;
    font-family: "Gotham Book";
    font-size: 13px;
    padding: 20px 0;
    line-height: 22.5px;
    p {
      color: variables.$S_BLEU_PRUSSE;
      padding: 0 !important;
      margin-left: 5px;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  form img {
    top: 40% !important;
  }

  form input {
    margin: 15px 0;
    width: 100% !important;
  }

  form > div {
    display: flex;
  }


  input {
    height: 42px;
  }
  select {
    height: 52px;
  }
  span {
    font-family: "Gotham Bold";
    font-size: 14px;
    line-height: 22.5px;
    margin: 10px 0;
    opacity: .6;
    color: variables.$S_MAIN_COLOR_BLACK;
  }
}

.s_step_1 {
  align-items: center;
  display: none;
  flex-direction: row;
  justify-content: space-around;
}

.s_bloc_expert {
  background-color: white;
  width: 100%;
  z-index: 10;
  padding: 0 5% 5% 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.s_widget_estimation {
  // width: 30%;
  // padding: 0 20px 20px 20px;
}

.s_widget {
  position: relative;
  display: none !important;
  background-color: white;
  border: 1px solid #eee;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  > div {
    &:first-child {
      height: 10%;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      padding-bottom: 40px;

      h4 {
        position: relative;
        font-family: "Gotham Bold";
        font-weight: bold;
        font-size: 16px;

        &:before {
          position: absolute;
          content: "";
          background-color: variables.$S_MAIN_COLOR_CLIENT;
          bottom: -10px;
          left: 0;
          width: 30%;
          height: 1px;
        }
      }
    }

    &:last-child {
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      flex-wrap: nowrap;

      > div {
        padding-bottom: 10px;
      }
    }
  }
}

.history_adresse {
  padding-bottom: 10px;
}

.s_widget > div:last-child > div span, .history_adresse span {
  font-size: 14px;
  font-family: "Gotham Bold";
  font-weight: bold;
  color: variables.$S_MAIN_COLOR_CLIENT;
}

.s_widget > div:last-child > div p, .history_adresse p {
  position: relative;
  font-size: 12px;
  font-family: "Gotham Bold";
  font-weight: bold;
}

.s_estimation {
  position: relative;
  background-color: white;
  width: 100%;
  height: 100%;
}

.estimation-button {
  margin: 0;
  padding: 0 10% 0 5%;
  flex-direction: row;
}