/** @format */

@use "variables";
@import "../../node_modules/include-media/dist/include-media";
$breakpoints: (
  small-phone: 200px,
  phone: 450px,
  tablet: 768px,
  big-tablet: 1000px,
  transition: 1150px,
  laptop: 1440px,
  desktop: 1980px,
);
//MAIN

.s_main_section {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 9;

  .wallpaper-slider {
    position: absolute;
    height: 100%;
    overflow: hidden;
    width: 100%;
    .image {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: all 3s ease-in-out;
      transform: scale(2);

      &.disable {
        opacity: 0;
      }

      &.active {
        opacity: 1;
        animation: zoom 10s linear;
      }
    }
  }
}
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(2);
  }
}
.s_main_content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  z-index: 10;
  > img {
    width: 30vh;
    z-index: 10;
  }
}

//STANDARD
.s_section {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 5;
  padding-left: 80px;
  > p {
    position: relative;
    font-family: "Gotham Book";
    font-size: 16px;
    letter-spacing: 0.5px;
    text-align: justify;
    line-height: 22px;
    padding-bottom: 20px;
    padding: 20px 5%;
  }
}

.s_fix {
  position: relative;
  width: 100%;
  height: 100vh;
}

.s_top_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5% 0 0 0;
  background-color: white;
}

.s_large_box {
  width: 100%;
  height: auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 5%;
  background-color: white;
  ul {
    list-style-type: disc;
    padding-left: 20px;
    margin: 10px 0;
    li {
      font-family: "Gotham Book";
      font-weight: normal;
      font-size: 14px;
      text-align: justify;
      margin-bottom: 5px;
    }
  }
  > div {
    padding: 20px 40px;
    border-radius: 10px;
    > h4 {
      font-family: "Gotham Bold";
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: justify;
      color: variables.$S_BLEU_PRUSSE;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
    > p {
      font-family: "Gotham Book";
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: justify;
    }
  }
}

// SECTION ACTUALITES
.s_actualites {
  position: relative;
  display: flex;
  background-color: white;
  flex-direction: column;
  padding: 5%;
  width: 100%;
  height: 100%;
  > span {
    position: relative;
    font-family: "Optimal Bold";
    font-size: 14px;
    color: variables.$S_SABLE;
    text-transform: uppercase;
    padding-bottom: 20px;
  }
  > h2 {
    position: relative;
    font-family: "Optimal";
    font-size: 35px;
    color: variables.$S_BLEU_PRUSSE;
    padding-bottom: 20px;
  }
  > .s_card_actualite {
    position: relative;
    left: -3%;
    margin: 5% 0;
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: row;
    > div {
      position: relative;
      border-radius: 10px;
      > div {
        border-radius: 10px;
      }
      > img {
        position: relative;
        object-fit: cover;
        height: 100%;
        border-radius: 10px;
        width: 100%;
      }
      &.s_card_image {
        left: 10%;
        z-index: 10;
        margin: 2% 0;
        width: 25%;
      }
      &.s_card_sable {
        position: relative;
        background-color: variables.$S_SABLE;
        padding: 20px 15% 20px 20px;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        > h4 {
          position: relative;
          font-family: "Optima";
          font-size: 20px;
          color: variables.$S_BLEU_PRUSSE;
        }
        > span {
          position: relative;
          font-family: "Gotham Book";
          font-size: 10px;
          color: white;
        }
        > p {
          position: relative;
          font-family: "Gotham Book";
          font-size: 12px;
          color: variables.$S_MAIN_COLOR_BLACK;
          line-height: 20px;
          margin: 20px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
      &.s_card_prusse {
        position: relative;
        background-color: variables.$S_BLEU_PRUSSE;
        padding: 20px 20px 20px 15%;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        > h4 {
          position: relative;
          font-family: "Optima";
          font-size: 20px;
          color: white;
        }
        > span {
          position: relative;
          font-family: "Gotham Book";
          font-size: 10px;
          color: variables.$S_SABLE;
        }
        > p {
          position: relative;
          font-family: "Gotham Book";
          font-size: 12px;
          color: white;
          line-height: 20px;
          margin: 20px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
      &.s_card_white {
        position: relative;
        background-color: variables.$S_WHITE_COLOR_CLIENT;
        padding: 20px 20px 20px 15%;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        > h4 {
          position: relative;
          font-family: "Optima";
          font-size: 20px;
          color: variables.$S_BLEU_PRUSSE;
        }
        > span {
          position: relative;
          font-family: "Gotham Book";
          font-size: 10px;
          color: variables.$S_SABLE;
        }
        > p {
          position: relative;
          font-family: "Gotham Book";
          font-size: 12px;
          color: variables.$S_MAIN_COLOR_BLACK;
          line-height: 20px;
          margin: 20px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
// SECTION BUY SELL RENTAL
.s_model_content {
  position: relative;
  display: flex;
  background-color: white;
  padding: 5%;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  > div {
    position: relative;
    width: 100%;
    height: 100%;
    > span {
      position: relative;
      font-family: "Optimal Bold";
      font-size: 15px;
      color: variables.$S_SABLE;
      text-transform: uppercase;
      padding-bottom: 20px;
      letter-spacing: 2px;
    }
    > p {
      position: relative;
      font-family: "Gotham Book";
      font-size: 16px;
      letter-spacing: 0.5px;
      text-align: justify;
      line-height: 22px;
      padding-bottom: 20px;
      > a {
        position: relative;
        font-size: 12px;
        font-family: "Gotham Bold";
        text-transform: uppercase;
        color: variables.$S_BLEU_PRUSSE;
        transition: 1s all;
        &:hover {
          color: variables.$S_BLEU_PETROLE;
        }
      }
    }
    > h2 {
      position: relative;
      font-family: "Optimal";
      font-size: 36px;
      color: variables.$S_BLEU_PRUSSE;
      padding-bottom: 20px;
    }
    .s_information {
      justify-content: flex-start;
      display: flex;
      margin-bottom: 20px;
      > div {
        &:first-child {
          margin-right: 20px;
          img {
            width: 20px;
            height: auto;
          }
        }
        &:last-child {
          > h4 {
            font-family: "Gotham Bold";
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 5px;
            color: variables.$S_BLEU_PRUSSE;
          }
          > p,
          a {
            font-family: "Gotham Book";
            font-size: 12px;
            line-height: 20px;
            color: variables.$S_MAIN_COLOR_BLACK;
          }
        }
      }
    }
    .s_information_contact {
      height: auto;
      flex-direction: column;
    }
    > div {
      position: relative;
      display: flex;
      flex-direction: row;
      padding-top: 20px;
      width: 100%;
      height: 80px;
      justify-content: space-between;
    }
    > img {
      position: relative;
      width: auto;
      height: 100%;
    }
  }
  > div:first-child {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  > div:last-child {
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .f_end {
    align-items: flex-end;
  }
}

// SECTION APPARTMENT
.s_description_detail {
  width: 100%;
  margin: 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .mapboxgl-canvas-container {
    position: relative;
    height: 100%;
    .mapboxgl-canvas {
      height: 400px;
      position: relative;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    }
  }
  > div {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:first-child {
      padding-right: 5%;
      > div {
        margin-bottom: 20px;
        width: 100%;
        b {
          font-family: "Gotham Book";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          margin-right: 10px;
          text-transform: uppercase;
          color: variables.$S_BLEU_PRUSSE;
        }
        p,
        div p {
          display: flex;
          width: 100%;
          justify-content: flex-start;
          font-family: "Gotham Bold";
          font-style: normal;
          font-weight: 400;
          text-transform: uppercase;
          font-size: 14px;
          color: variables.$S_MAIN_COLOR_BLACK;
          margin: 10px 0;
          align-items: center;
          > img {
            width: 35%;
          }
        }
        > span {
          font-family: "Optimal";
          font-style: normal;
          margin-bottom: 10px;
          font-weight: 700;
          font-size: 20px;
          line-height: 29px;
          color: variables.$S_BLEU_PRUSSE;
        }
      }
    }
  }
}
.s_description {
  width: 100%;
  margin: 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:first-child {
      padding-right: 5%;
      > div {
        position: relative;
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        > div {
          width: 100%;
          display: flex;
          flex-direction: row;
          > div {
            &:first-child {
              width: 60px;
              height: 42px;
              border-radius: 10px;
              background: variables.$S_BLEU_PRUSSE;
              margin-right: 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              > img {
                width: 16px;
                height: 16px;
              }
            }
            &:last-child {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: flex-start;
              position: relative;
              > p {
                font-family: "Gotham Book";
                font-size: 12px;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                color: variables.$S_MAIN_COLOR_BLACK;
              }
              > span {
                font-family: "Gotham Bold";
                font-style: normal;
                font-size: 14px;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-feature-settings: "cpsp" on;
                color: variables.$S_BLEU_PRUSSE;
              }
            }
          }
        }
      }
    }
    &:last-child {
      h4 {
        font-family: "Optimal";
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 42px;
        color: variables.$S_BLEU_PRUSSE;
        margin-bottom: 20px;
      }
      p {
        font-family: "Gotham Book";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: justify;
        color: variables.$S_MAIN_COLOR_BLACK;
      }
    }
  }
}
.s_general_box {
  background-color: white;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 5%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .s-table {
    border-radius: 10px;
    border: 1px solid #f3f3f9;
    color: variables.$S_BLEU_PRUSSE;
    width: 100%;
    padding-right: 0 !important;
    .s-table-row {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      border-bottom: 1px solid #f3f3f9;
      margin-bottom: 0;
      &:nth-child(even) {
        background-color: #c9c0b652;
      }
      .s-table-cell {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 15px;
        align-items: center;
        align-content: center;
        &:first-child {
          border-right: 1px solid #f3f3f9;
        }
        span {
          font-family: "Optimal";
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }

  .s_step {
    display: flex;
    align-items: center;
    margin: 10px 0;
    justify-content: flex-start;
    span {
      font-family: "Optimal";
      background: #c9c1b6;
      padding-top: 3px;
      height: 30px;
      margin: 0 20px 0 0;
      border: 1px solid #eee;
      color: white;
      display: flex;
      width: 30px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
    }
    h4 {
      font-family: "Optimal";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 42px;
      color: variables.$S_BLEU_PRUSSE;
    }
  }
  .s_seo_model {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    z-index: 5;
    overflow: hidden;
    margin: 20px 0 20px 0;
    > div {
      width: 100%;
    }
    h1 {
      font-family: "Optimal";
      font-style: normal;
      font-weight: 400;
      text-align: center;
      font-size: 20px;
      text-transform: uppercase;
      line-height: 42px;
      color: variables.$S_BLEU_PRUSSE;
    }
  }
  .s_model_head {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 5;
    overflow: hidden;
    .s_img_model {
      position: relative;
      height: 70vh;
      width: 80%;
      box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
      margin-left: auto;
      margin-right: auto;
      img {
        border-radius: 10px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
      }
    }
    > div {
      &:first-child {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}
.s_slide_appartement {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 40px;
  overflow: hidden;
  .s_card_appartement {
    .s_card_fix {
      height: 100% !important;
    }
    > div {
      &:last-child {
        width: 50%;
      }
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        width: 50%;
        margin-right: 5%;
        height: 80%;
        img {
          &.s_main_picture {
            object-fit: cover;
            height: 100%;
            width: 100%;
            border-radius: 10px;
          }
          &.s_filigrame {
            position: absolute;
            width: 145px;
            opacity: 0.5;
            z-index: 10;
          }
        }
      }
      &:last-child {
        height: 100%;
        display: flex;
        margin-right: 20px;
        flex-direction: column;
        align-items: flex-start;
        background-color: variables.$S_SABLE;
        justify-content: center;
        @include media(">small-phone", "<transition") {
          margin-right: 0;
        }
        > span {
          position: relative;
          font-family: "Optimal";
          font-size: 16px;
          color: variables.$S_WHITE_COLOR_CLIENT;
          text-transform: uppercase;
          background-color: variables.$S_SABLE;
          margin-bottom: 10px;
          width: 100%;
        }
        > b {
          font-family: "Gotham Book";
          font-size: 20px;
          background: variables.$S_SABLE;
          width: 100%;
          color: variables.$S_BLEU_PRUSSE;
          letter-spacing: 5px;
          margin: 10px 0;
        }
        > h2 {
          position: relative;
          font-family: "Optimal";
          font-size: 35px;
          color: variables.$S_BLEU_PRUSSE;
          width: 100%;
          background-color: variables.$S_SABLE;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        > p {
          position: relative;
          font-size: 14px;
          line-height: 20px;
          width: 100%;
          text-align: justify;
          overflow: hidden;
          margin-bottom: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          background-color: variables.$S_SABLE;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        > div {
          position: relative;
          width: 100%;
          display: flex;
          background-color: variables.$S_SABLE;
          flex-wrap: wrap;
          &.show {
            @include media(">small-phone", "<transition") {
              display: none;
            }
          }
          div {
            width: 50%;
            padding: 10px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            align-content: center;
            > div {
              &:first-child {
                width: 3rem;
                background: variables.$S_BLEU_PRUSSE;
                border-radius: 10px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              &:last-child {
                width: 80%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-around;
                > p {
                  text-transform: uppercase;
                  font-family: "Gotham Book";
                  font-size: 12px;
                  background-color: variables.$S_SABLE;
                }
                > span {
                  font-family: "Gotham Bold";
                  font-size: 12px;
                  color: variables.$S_BLEU_PRUSSE;
                  text-transform: uppercase;
                  background-color: variables.$S_SABLE;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

// SECTION ACTUS
.s_actus {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  > div {
    &:first-child {
      width: 40%;
      display: flex;
      height: 35rem;
      padding: 5% 5% 0 5%;
      align-items: flex-end;
      > div {
        position: relative;
        padding: 20px;
        display: flex;
        top: 0%;
        z-index: 20;
        height: auto;
        padding: 40% 5%;
        border-radius: 10px;
        background-color: variables.$S_WHITE_COLOR_CLIENT;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        > span {
          position: relative;
          font-size: 16px;
          font-family: "Optimal Bold";
          color: variables.$S_SABLE;
          margin-bottom: 30px;
        }
        > p {
          position: relative;
          font-family: "Optimal";
          font-size: 12px;
          line-height: 15px;
          margin-bottom: 10px;
        }
        > h2 {
          position: relative;
          color: variables.$S_BLEU_PRUSSE;
          font-size: 30px;
          font-family: "Optimal";
          margin-bottom: 10px;
        }
      }
    }
    &:last-child {
      position: relative;
      padding: 5% 5% 0 0;
      width: 60%;
      height: 35rem;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      > div {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        &:first-child {
          padding-right: 5%;
        }
        &:last-child {
          padding-left: 5%;
        }
        > h2 {
          position: relative;
          font-size: 23px;
          color: variables.$S_BLEU_PRUSSE;
          font-family: "Optimal";
          width: 100%;
          text-align: left;
          margin-bottom: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        > p {
          position: relative;
          font-size: 12px;
          font-family: "Gotham Book";
          width: 100%;
          line-height: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          text-align: left;
          margin-bottom: 20px;
          &::-webkit-scrollbar {
            width: 2px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            border-radius: 5px;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: variables.$S_BLEU_PRUSSE;
          }
        }
        > div {
          position: relative;
          width: 100%;
          height: auto;
          margin-bottom: 20px;
          > img {
            border-radius: 10px;
            height: 250px;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

//SECTION CONTENT BLOG
.content_blog {
  position: relative;
  width: 100%;
  height: auto;
  padding: 5%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h1 {
    font-family: "Optimal";
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 42px;
    margin-bottom: 20px;
    color: variables.$S_BLEU_PRUSSE;
  }
  > p {
    font-family: "Gotham Book";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 40px 0 20px 0;
    color: variables.$S_MAIN_COLOR_BLACK;
  }

  .s_blog_bloc {
    position: relative;
    width: 100%;
    height: 100%;
    background: white;
    margin-bottom: 20px;
    .strong {
      font-family: "Gotham Bold";
    }
    > img {
      float: left;
      width: 50%;
      height: 50vh;
      object-fit: cover;
      border-radius: 10px;
      margin: 20px 20px 20px 0;
    }

    > {
      ul,
      div > ul {
        padding: 20px 0 20px 40px;
        width: 100%;
        height: auto;
      }

      ul > li {
        list-style-type: disc;

        font-size: 14px;
        line-height: 25px;
      }

      div {
        > ul > li {
          list-style-type: disc;
          font-size: 14px;
          line-height: 25px;
        }

        a {
          color: variables.$S_BLEU_PRUSSE;
          padding-bottom: 5px;
          border-bottom: 1px solid variables.$S_MAIN_COLOR_BLACK;
          transition: all 1s;

          &:hover {
            color: variables.$S_MAIN_COLOR_BLACK;
          }
        }

        table {
          position: relative;
          width: 100%;
          height: auto;
          background: whitesmoke;
          margin: 20px 0;
        }

        tr {
          position: relative;
          border-bottom: 1px solid #eee;
        }

        .color {
          background: variables.$S_BLEU_PRUSSE !important;
        }

        td {
          position: relative;
          text-align: center;
          font-size: 14px;
          padding: 10px;
          border-right: 2px solid #eee;
          color: variables.$S_MAIN_COLOR_BLACK;
        }

        th {
          color: whitesmoke;
          text-align: center;
          text-transform: uppercase;

          padding: 10px;
          font-size: 12px;
          border-right: 2px solid #eee;
        }

        tbody {
        }
      }

      span {
        position: relative;
        width: 100%;
        height: 2px;
        margin-bottom: 40px;
        margin-top: 20px;
        background-color: #eee;
      }

      h1 {
        position: relative;
        margin-bottom: 20px;
        line-height: 40px;
        font-size: 26px;
        color: variables.$S_MAIN_COLOR_BLACK;
        text-transform: uppercase;
      }

      h2::before {
        position: absolute;
        content: "";
        width: 20%;
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: variables.$S_MAIN_COLOR_BLACK;
      }

      h1::before {
        background-color: variables.$S_BLEU_PRUSSE;
      }

      div {
        position: relative;
        width: 100%;
        height: auto;
        padding-bottom: 20px;
        margin: 0;

        > h2 {
          margin-bottom: 20px;
          font-size: 20px;
          color: variables.$S_BLEU_PRUSSE;
          text-transform: uppercase;
          position: relative;
        }
      }

      h1 > b {
        color: variables.$S_BLEU_PRUSSE;
      }

      p {
        position: relative;
        width: 100%;
        height: auto;
        text-align: justify;
        padding: 10px 0;
        line-height: 20px;
        font-size: 14px;
      }

      div > {
        p {
          position: relative;
          width: 100%;
          height: auto;
          text-align: justify;
          padding: 10px 0;
          line-height: 20px;
          font-size: 14px;

          > b {
            color: variables.$S_BLEU_PRUSSE;
          }
        }

        div {
          display: flex;
          flex-direction: row;
          height: auto;
          align-items: flex-start;

          > {
            p {
              margin-bottom: 5px;
              font-size: 10px;
              text-transform: uppercase;
            }

            span {
              margin-right: 20px;
              margin: 10px 0;
              font-size: 12px;
              font-weight: bold;
              text-transform: uppercase;
            }

            ul {
              padding: 10px;
              display: flex;
              flex-direction: row;
              font-family: "Gotham Book";
              justify-content: flex-end;
              align-items: flex-start;

              > li > a {
                color: variables.$S_MAIN_COLOR_BLACK;
                text-transform: uppercase;
                font-size: 12px;
                border: none;
                letter-spacing: 2px;
                font-family: "Gotham Book";
                transition: all 1s;

                > img {
                  width: 46px;
                  height: 46px;
                  padding: 0 10px;
                }

                > i {
                  font-size: 20px;
                  border: 1px solid variables.$S_MAIN_COLOR_BLACK;
                  color: variables.$S_MAIN_COLOR_BLACK;
                  border-radius: 3px;
                  margin: 0 10px 0 0;

                  padding: 5px;
                  transition: all 1s;

                  &:hover {
                    border-radius: 50%;
                    color: variables.$S_BLEU_PRUSSE;
                    border: 1px solid variables.$S_BLEU_PRUSSE;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .s_footer_blog {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    > div:first-child {
      flex-direction: column;
      > b {
        display: flex;
        font-weight: normal;
        > p {
          color: variables.$S_BLEU_PRUSSE;
          margin: 0 5px;
        }
      }
    }
  }
}

//SECTION REVIEWS
.s_reviews_control_fix {
  position: relative;
  right: 0;
  width: 47.5%;
  margin-left: auto;
}
.s_review {
  width: 100%;
  height: auto;
  padding: 5% 0;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  > div {
    padding: 5%;
    width: 50%;
    &:first-child {
      position: relative;
      height: auto;
      overflow: hidden !important;
      margin-left: 5%;
      padding: 5% 0 5% 0;
      .s_card_review {
        position: relative;
        background-color: variables.$S_MAIN_COLOR_CLIENT;
        border-radius: 10px;
        padding: 5% 10%;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        > {
          h2 {
            position: relative;
            color: variables.$S_BLEU_PRUSSE;
            font-size: 35px;
            margin-bottom: 5px;
            text-transform: capitalize;
            font-family: "Optimal";
          }
          p {
            position: relative;
            color: variables.$S_MAIN_COLOR_BLACK;
            font-family: "Gotham Book";
            font-size: 14px;
            line-height: 20px;
            overflow-x: hidden;
            overflow-y: scroll;
            height: 80px;
            margin-bottom: 10px;
            &::-webkit-scrollbar {
              width: 5px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: transparent;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: variables.$S_BLEU_PRUSSE;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: variables.$S_BLEU_PRUSSE;
            }
          }
          div {
            > span {
              background-color: variables.$S_BLEU_PRUSSE;
              border-radius: 5px;
              padding: 0.5rem;
              margin-left: 5px;
              > img {
                width: 15px;
              }
              &:first-child {
                margin: 0;
              }
            }
          }
        }
      }
    }
    &:last-child {
      position: relative;
      height: auto;
      > span {
        position: relative;
        font-size: 18px;
        font-family: "Optimal";
        color: variables.$S_SABLE;
      }
      > h2 {
        position: relative;
        font-family: "Optimal";
        font-size: 35px;
        color: variables.$S_BLEU_PRUSSE;
        margin: 20px 0;
      }
      > p {
        position: relative;
        font-size: 14px;
        font-family: "Gotham Book";
        line-height: 20px;
        text-align: justify;
        margin-bottom: 10px;
      }
      > div {
        width: 200px;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 40px 0 0 5%;
        > div:first-child {
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: row-reverse;
          align-items: flex-end;
          justify-content: space-between;
          > p {
            font-size: 10px;
            font-family: "Gotham Bold";
            font-weight: bold;
            position: relative;
            color: variables.$S_BLEU_PRUSSE;
            > b {
              color: variables.$S_BLEU_PRUSSE;
            }
          }
          > img {
            width: 2rem;
          }
        }
        > div:last-child {
          padding: 5px 0 0 0;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-around;
          > span {
            background-color: variables.$S_BLEU_PRUSSE;
            border-radius: 5px;
            padding: 0.5rem;
            margin-left: 5px;
            &:first-child {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

//SLIDE AGENT
.s_active_agent {
  animation: agentMove 1s forwards;
}

.s_unactive_agent {
  animation: agentUnmove 1s forwards;
}
.w_100 {
  .s_slide_agent {
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 20px;
    margin-top: 15%;
    background-color: variables.$S_MAIN_COLOR_CLIENT;
    .s_card_agent {
      position: relative;
      transform: translateY(-150px);
      height: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      > h2 {
        padding: 10px 0 0 0;
        font-size: 28px;
        color: variables.$S_BLEU_PRUSSE;
        font-family: "Optimal";
      }
      > p {
        font-size: 12px;
        font-family: "Gotham Book";
        color: black;
        line-height: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > div {
        &:first-child {
          position: relative;
          > div {
            position: absolute;
            top: 0;
            border-radius: 10px;
            opacity: 0;
            height: 100%;
            width: 100%;
            display: flex;
            padding: 20px;
            z-index: 10;
            background: variables.$S_BLEU_PRUSSE_9;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            > h4 {
              font-family: "Gotham Book";
              font-weight: normal;
              font-size: 26px;
              line-height: 20px;
              text-transform: uppercase;
              color: #ffffff;
              margin-bottom: 10%;
            }
            > p {
              font-family: "Gotham Book";
              font-weight: normal;
              font-size: 12px;
              line-height: 20px;
              color: #ffffff;
            }
          }
        }
        &:last-child {
          position: relative;
          width: 40%;
          padding: 20px 0;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          align-content: center;
          height: auto;
        }
      }
    }
  }
}

// AUTRE
.s_noresult {
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  h4 {
    font-size: 20px;
    font-family: "Gotham Bold";
    color: variables.$S_BLEU_PRUSSE;
  }
}
//SECTION PARTNER
.s_partner {
  width: 100%;
  height: 200px;
  background: #325a66;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  align-content: center;
  > div {
    height: 100%;
    img {
      width: 150px;
    }
  }
  .s_card_partner {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    position: relative;
    filter: brightness(0) invert(1);
    opacity: 0.7;
    transition: all 1s;
    &:hover {
      opacity: 1;
    }
  }
}

//SECTION VENDRE
.s_content_sell {
  position: relative;
  width: 100%;
  height: auto;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  > h2 {
    position: relative;
    font-size: 28px;
    font-family: "Optimal";
    color: variables.$S_BLEU_PRUSSE;
    line-height: 42px;
  }

  > div {
    position: relative;
    width: 100%;
    padding-top: 5%;
    display: flex;
    height: 350px;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    > div {
      position: relative;
      width: 25%;
      margin-right: 5%;
      padding: 20px;
      height: 100%;
      background: #fff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      align-content: center;
      overflow: hidden;
      transition: all 1s;
      &:hover {
        > {
          img,
          h4,
          p {
            opacity: 0;
          }
          div {
            opacity: 1;
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
      > img {
        width: 64px;
        margin-bottom: 10px;
      }
      > h4 {
        position: relative;
        color: variables.$S_BLEU_PRUSSE;
        font-size: 20px;
        text-align: center;
        font-family: "Optimal";
      }
      > p {
        font-size: 12px;
        margin: 10px 0;
        text-align: justify;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }
      > div {
        opacity: 0;
        position: absolute;
        top: 0;
        padding: 20px;
        overflow: auto;
        z-index: 100;
        background-color: variables.$S_BLEU_PRUSSE;
        height: 100%;
        transition: all 1s;
        > p {
          font-size: 15px;
          text-align: center;
          line-height: 18px;
          letter-spacing: 0.5px;
          overflow: auto;
          color: white;
        }
      }
    }
  }
}

.s_control_filter {
  width: 100%;
  position: relative;
  display: flex;
  flex: row;
  align-items: center;
  justify-content: space-between;
  padding: 50px 5% 40px 5%;
  background-color: white;

  @include media(">small-phone", "<transition") {
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0 10px 40px 10px;
  }
}
.s_order_by {
  position: relative;
  padding: 20px 60px;
  border: variables.$S_BLEU_PRUSSE 1px solid;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s;
  @include media(">small-phone", "<transition") {
    display: block;
    border: none;
    position: absolute;
    right: 0;
    width: 100%;
  }
  &:hover {
    background-color: variables.$S_BLEU_PRUSSE;
    @include media(">small-phone", "<transition") {
      background-color: transparent;
    }
    > p {
      color: white;
    }
  }
  > p {
    font-family: "Gotham Bold";
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: variables.$S_BLEU_PRUSSE;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    @include media(">small-phone", "<transition") {
      display: none;
    }
  }
  > div {
    display: none;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    position: absolute;
    width: 100%;
    right: 0;
    bottom: -148px;
    z-index: 100;
    overflow: hidden;
    transition: all 0.5s;
    @include media(">small-phone", "<transition") {
      display: block;
      right: -100%;
      width: 200px;
      bottom: -150px;
    }
    > p {
      position: relative;
      width: 100%;
      padding: 10px 20px;
      font-family: "Gotham Bold";
      font-size: 13px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      text-transform: uppercase;
      border-bottom: 1px solid #ececea;
      white-space: nowrap;

      color: variables.$S_BLEU_PRUSSE;
      transition: all 0.5s;
      @include media(">small-phone", "<transition") {
        font-size: 11px;
      }
      &:hover {
        background-color: #ececea;
      }
      &:last-child {
        border: none;
      }
    }
  }
}

.s_text_filter {
  position: relative;
  display: flex;
  background-color: white;
  flex-direction: column;
  width: 50%;
  height: auto;
  justify-content: space-between;
  align-items: flex-start;

  @include media("<phone") {
    padding: 0 !important;
    width: 70%;
  }
  > {
    span {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 50%;
      height: auto;
      &:first-child {
        font-family: "Gotham Bold";
        font-size: 18px;
        line-height: 26px;
        text-transform: uppercase;
        color: variables.$S_BLEU_PRUSSE;
        @include media("<phone") {
          font-size: 14px;
        }
      }
      &:last-child {
        font-family: "Gotham Book";
        font-size: 18px;
        line-height: 26px;
        text-transform: uppercase;

        color: variables.$S_BLEU_PRUSSE;
        @include media("<phone") {
          font-size: 12px;
        }
      }

      > b {
        margin-right: 10px;
      }
    }

    div {
      display: flex;
      cursor: pointer;
      width: auto;
      height: 50px;
      border: 1px solid #eee;
      border-radius: 0.3rem;
      background: white;
      padding: 0 10px;
      align-items: center;
      justify-content: center;

      > {
        i {
          margin-right: 20px;
        }

        span {
          margin-right: 20px;
          font-size: 10px;
        }
      }
    }
  }
}

#ModelMap {
  width: 100%;
  height: 50vh;
  border-radius: 10px;
}

.s_appartement {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0 5% 5% 5%;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.s_thumbnails {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 5%;
  display: flex;
  background-color: white;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  transition: all 1s;

  > div {
    position: relative;
    padding-top: 100px;
    width: 100%;
    > h2 {
      font-family: "Gotham Book";
      font-size: 24px;
      text-transform: uppercase;
      color: #2a2a2a;
    }
    > div {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      background-color: white;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 40px 0;
      align-items: center;
      justify-content: space-between;
      transition: all 1s;
      > div {
        position: relative;
        width: 32.5%;
        height: 500px;
        overflow: hidden;
        border-radius: 5px;
        margin-top: 5px;
        transition: all 1s;
        @include media(">=desktop") {
          height: 600px;
        }
        @include media(">=big-tablet", "<laptop") {
          width: 49.5%;
          height: 600px;
        }
        @include media(">=tablet", "<big-tablet") {
          width: 49.5%;
        }
        @include media(">phone", "<tablet") {
          width: 100%;
        }
        @include media("<phone") {
          width: 100%;
        }
        &:hover {
          > div {
            height: 100%;
            > div {
              padding: 40px;
            }
            > div > div:first-child p:nth-child(3) {
              opacity: 1;
            }
          }
        }
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
        > div {
          position: absolute;
          display: flex;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 20%;
          z-index: 3;
          justify-content: space-between;
          align-items: flex-end;
          flex-direction: column;
          transition: all 1s;
          > div {
            width: 100%;
            z-index: 5;
            padding: 20px;
            display: flex;
            height: 100%;
            background: linear-gradient(
              0deg,
              black 0%,
              rgba(0, 0, 0, 0.7) 70%,
              rgba(0, 0, 0, 0) 100%
            );
            flex-direction: column;
            justify-content: space-between;
            transition: all 1s;
            > div {
              position: relative;
              width: 100%;
              height: auto;
              &:first-child {
                p {
                  &:nth-child(4) {
                    opacity: 0;
                    transition: all 1s;
                  }
                  &:nth-child(3) {
                    margin-bottom: 30px;
                    opacity: 0;
                    transition: all 1s;
                  }
                }
              }
              &:last-child {
                p {
                  opacity: 0.7;
                  font-style: italic;
                  font-size: 10px;
                  text-transform: uppercase;
                  margin-bottom: 20px;
                  @include media(">=desktop") {
                    font-size: 16px;
                  }
                }
                > b {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                }
              }
              .fs_08 {
                @include media(">=desktop") {
                  font-size: 14px;
                }
              }
              > h4 {
                position: relative;
                color: variables.$S_WHITE_COLOR_CLIENT;
                font-size: 20px;
                font-family: "Optimal Bold";
                @include media(">=desktop") {
                  font-size: 26px;
                }
              }
              > span {
                position: relative;
                margin-bottom: 10px;
                font-family: "Gotham Book";
                font-size: 12.9px;
                line-height: 20px;
                text-align: justify;
                color: variables.$S_WHITE_COLOR_CLIENT;
                margin-top: 30px;
                @include media(">=desktop") {
                  font-size: 20px;
                  line-height: 30px;
                }
              }
              > p {
                position: relative;
                padding: 10px 0 5px 0;
                font-family: "Gotham Book";
                font-size: 14px;
                color: variables.$S_WHITE_COLOR_CLIENT;
                @include media(">=desktop") {
                  font-size: 18px;
                }
              }
            }
          }
          > img {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 10;
            margin: 10px 10px 0 0;
            width: 32px;
          }
        }
      }
    }
  }
}
