/** @format */

@use "variables";

@keyframes zoom {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(2);
	}
}

.slider-previsu {
	position: relative;
	max-width: 80%;
	height: 60vh;
	margin: 50px auto 0 auto;

	.banner {
		position: absolute;
		z-index: 20;

		width: 100%;
		height: 10%;
		padding: 20px;

		opacity: 0.9;
		background-color: #325a66;

		font-family: "Gotham Bold";
		font-weight: bold;
		color: #ffffff;
	}

	.s_main_section {
		max-height: 100%;
		.wallpaper-slider {
			position: absolute;
			height: 100%;
			width: 100%;
			.image {
				position: absolute;
				height: 100%;
				width: 100%;
				object-fit: cover;
				transition: all 1s ease-in-out;

				&.disable {
					opacity: 0;
				}

				&.active {
					opacity: 1;
					animation: zoom 5s linear;
				}
			}
		}
	}

	.hover {
		max-width: 40%;
		position: absolute;
		z-index: 999;
	}
}

.image_form {
	width: 100%;
	padding: 20px;

	box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
	background-color: #fff;
	background-clip: border-box;
	border: 0 solid rgba(34, 41, 47, 0.125);

	border-radius: 5px;
	display: none;
}

.accueil_panel {
	&.s_board {
		width: 90% !important;
		margin: auto;
	}

	&.s_button {
		position: absolute !important;
		bottom: 0 !important;
		width: 100% !important;
	}

	&.s_content {
		padding-bottom: 50px;
	}
}

.title {
	width: 80%;
	padding: 10px;
	margin: 30px auto 0 auto;

	background-color: #325a66;
	font-family: "Gotham Bold";
	font-weight: bold;
	color: #ffffff;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
}

.images-container {
	position: relative;
	max-width: 80%;
	height: 40vh;
	margin-left: auto;
	margin-right: auto;

	display: flex;
	flex-wrap: wrap;
	overflow: auto;
	box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);

	.image-arrow {
		width: 100%;
		height: 30px;
		background-color: #ffffff;

		position: absolute;
		bottom: 0;

		display: flex;
		justify-content: space-between;

		a {
			width: 30px;
			height: 30px;
			color: #ffffff;
		}

		.s_button {
			width: 50%;
			border-radius: 0;
		}
	}

	.container {
		position: relative;
		max-width: 190px;
		min-width: 190px;
		height: 190px;

		border-radius: 15px;
		margin: 10px !important;
		.image {
			width: 100%;
			height: 100%;

			border-top-right-radius: 15px;
			border-top-left-radius: 15px;
			object-fit: cover;
		}
		.logo {
			position: absolute;
			top: 0;
			right: 0;
			padding: 15px;
			color: #325a66;
			background-color: transparent;
			border: none;
			margin: 0;
		}
	}
}
